import { m } from "framer-motion"
import NextLink from "next/link"
import React, { useState } from "react"

// @mui
import { styled } from "@mui/material/styles"
import { Button, Box, Link, Container, Typography, Stack, Grid } from "@mui/material"
// routes

// components
import Image from "next/image"
import Iconify from "../../components/Iconify"

import { MotionContainer, varFade } from "../../components/animate"

import { openPopupWidget, InlineWidget } from "react-calendly"
import styledComponent from "styled-components"
import { gtag } from "../../utils/analytics"
import { useRouter } from "next/router"
import useResponsive from "src/hooks/useResponsive"
import profilePic from "../../../public/images/gpt-taaft.png"

// ----------------------------------------------------------------------

const TextContainer = styledComponent.div`
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 16px;
  text-align:center;
  
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  // background-color: rgb(12,12,13, 0.8);

  `
const RootStyle = styled(m.div)(({ theme, isFeatures }) => ({
    position: "relative",
    //backgroundColor: "#140648", //theme.palette.grey[400],

    backgroundImage: "linear-gradient(180deg, rgb(0 0 0) 15%, #004d4c 42%, #74A99B 66%, rgba(255,255,255,1) 100%)",

    [theme.breakpoints.up("xs")]: {
        width: "100%",
        // height: isFeatures ? "1100px" : "1000px",
        // height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}))

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
    zIndex: 10,
    maxWidth: 1120,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    display: "flex",

    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "50px",

    [theme.breakpoints.up("md")]: {
        margin: "unset",
        textAlign: "left",
        paddingTop: "50px",
        paddingBottom: theme.spacing(15),
    },
    [theme.breakpoints.up("xs")]: {
        margin: "unset",
        textAlign: "left",
        paddingTop: "0px",
    },
}))

const HeroImgStyle = styled(m.div)(({ theme }) => ({
    zIndex: 8,
    height: "auto",
    width: "90%",
    margin: "auto",

    [theme.breakpoints.up("xs")]: {
        marginBottom: 20,
        borderRadius: 8,
        width: "95%",
        // marginBottom: -80,
    },
    [theme.breakpoints.up("sm")]: {
        // marginBottom: -150,
        borderRadius: 8,
        width: "90%",
    },
    [theme.breakpoints.up("md")]: {
        // marginBottom: -350,
        marginTop: -150,
        borderRadius: 8,
        width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
        width: "80%",
        height: "auto",
        // marginBottom: -420,
        borderRadius: 12,
    },
    [theme.breakpoints.up("xl")]: {
        maxWidth: "1512px",
        minWidth: "auto",
        height: "auto",
        // marginBottom: -650,
        borderRadius: 12,
    },
}))

const StyledButton = styled(Button)`
    color: white;
    border: 1px solid white;
    font-size: 18px;
    :hover {
        background: white;
        color: #181a22;
    }
`

export const LogoSection = () => (
    <Box sx={{ flexGrow: 1, py: 4, textAlign: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ borderBottom: 1.2, flexGrow: 0.5, mr: 2, borderColor: "#fff" }} />
            <Typography variant='subtitle1' component='div' gutterBottom sx={{ color: "#fff", textTransform: "uppercase" }}>
                featured in
            </Typography>
            <Box sx={{ borderBottom: 1.2, flexGrow: 0.5, ml: 2, borderColor: "#fff" }} />
        </Box>

        <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{
                height: 1,
                mt: 2,
                display: "flex",

                borderRadius: "8px",
            }}
        >
            <Grid item xs={7} sm={6} md={3} sx={{ justifyContent: { xs: "center", md: "center", sm: "flex-end" }, display: "flex" }}>
                <Link
                    href={"https://theresanaiforthat.com/ai/flowpoint/?ref=featured&v=36463"}
                    target='_blank'
                    aria-label={"taaft"}
                    rel='noreferrer'
                    sx={{ display: "flex" }}
                >
                    <img
                        src='https://media.theresanaiforthat.com/featured4.png'
                        style={{ height: "50px", background: "white", borderRadius: "2.3px" }}
                    />
                </Link>
            </Grid>
            <Grid
                item
                xs={5}
                sm={6}
                md={3}
                sx={{
                    justifyContent: { xs: "center", md: "center", sm: "flex-start" },
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyItems: "center",
                }}
            >
                <Link
                    href='https://chat.openai.com/g/g-sNVZT6oAU-website-audit-ux-ui-content-by-flowpoint-ai'
                    target='_blank'
                    aria-label='GPTs'
                    rel='noreferrer'
                    sx={{
                        p: 1,
                        justifyContent: "center",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        border: "1.5px solid #6F7079",
                        width: { xs: "95%", sm: "70%", md: "95%" },
                        height: "50px",
                        borderRadius: "2px",
                        background: "white",
                    }}
                >
                    <img
                        src='/images/gpt.png'
                        style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                            backgroundPosition: "cover",
                            // border: "1px solid white",
                        }}
                    />
                    <Typography variant='caption' sx={{ color: "black", ml: 1 }}>
                        Featured in the GPT Store
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    </Box>
)
// ----------------------------------------------------------------------

export default function HomeHero({ title, subtitle, highlightedTitle }) {
    const [loading, setLoading] = useState(true)

    const { pathname } = useRouter()

    const isFeatures = pathname.startsWith("/features/")

    const handleLoad = () => {
        setLoading(false)
    }
    const onClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "TalkToSalesButton")
        }
        gtag_report_conversion()
    }

    function gtag_report_conversion() {
        gtag("event", "conversion", {
            send_to: "AW-11014059734/aCWdCKC1tJ0YENbt9IMp",
        })
        return false
    }
    const isDesktop = useResponsive("up", "sm")
    return (
        <MotionContainer>
            <RootStyle isFeatures={isFeatures}>
                <Container>
                    <ContentStyle>
                        <TextContainer>
                            <Box sx={{ mt: 17 }}>
                                {" "}
                                {/* <Logo hasTitle={true} isOffset={"false"} isOffsetHeader={"false"} titleColor={"white"} /> */}
                            </Box>
                            <m.div style={{ zIndex: 9, marginTop: "80px" }}>
                                <Typography
                                    component={isDesktop ? "h2" : "h2"}
                                    variant={isDesktop ? "h2" : "h2"}
                                    sx={{
                                        color: "#fff",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    {title}
                                    <Typography
                                        component={isDesktop ? "h2" : "h2"}
                                        variant={isDesktop ? "h2" : "h2"}
                                        sx={{
                                            fontFamily: "Poppins, sans-serif",
                                            WebkitBackgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                            // color: "#74A99B",
                                            backgroundImage:
                                                "linear-gradient(113.614deg, rgb(240, 249, 254) 5.27381%,rgb(116, 169, 155) 97.1786%)",
                                        }}
                                    >
                                        {highlightedTitle}
                                    </Typography>
                                </Typography>
                            </m.div>

                            <m.div style={{ zIndex: 9, maxWidth: "700px" }}>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        color: "white",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 500,
                                        lineHeight: "1.778rem",
                                        fontSize: { xs: "15px", md: "18px" },
                                        mt: 2.5,
                                    }}
                                    gutterBottom
                                >
                                    {subtitle}
                                </Typography>
                            </m.div>
                        </TextContainer>
                        <m.div
                            style={{
                                zIndex: 9,
                                display: "flex",
                                textAlign: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <NextLink
                                href='https://chat.openai.com/g/g-sNVZT6oAU-website-audit-ux-ui-content-by-flowpoint-ai'
                                target='_blank'
                                rel='noopener'
                            >
                                <StyledButton
                                    size='large'
                                    variant='contained'
                                    target='_blank'
                                    onClick={() => {
                                        if (typeof window !== "undefined" && window.fbq) {
                                            window.fbq("track", "GetStartedButton")
                                        }
                                    }}
                                    endIcon={
                                        <img
                                            src='/images/gpt.png'
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                                borderRadius: "50%",
                                                backgroundPosition: "cover",
                                                // border: "1px solid white",
                                            }}
                                        />
                                    }
                                    sx={{
                                        margin: "5px",
                                        background: "#74A99B",
                                        borderColor: "#74A99B",
                                        boxShadow: "none",
                                        color: "white",
                                        borderRadius: "40px",
                                        fontSize: "14px",
                                        "&:hover": {
                                            borderColor: "#74A99B",
                                            background: "white",
                                            color: "#74A99B",
                                        },
                                        // fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    Get Your FREE Audit Now
                                </StyledButton>
                            </NextLink>
                        </m.div>

                        <m.div
                            style={{
                                zIndex: 9,
                                display: "flex",
                                textAlign: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <LogoSection />
                        </m.div>
                    </ContentStyle>
                </Container>
                <HeroImgStyle>
                    <Image alt='Discover Flowpoint' src={profilePic} />
                </HeroImgStyle>
            </RootStyle>
        </MotionContainer>
    )
}
