import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import constants from "../../../utils/constants"
import NodeWrapper from "./NodeWrapper"

import Handle from "../Handle"
import IconPeople from "../../../assets/icon_people"

const customNodeStyles = {
    height: 124,
    width: 217,
    boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
    borderRadius: "16px",
    background: "#fff",
    borderColor: "#000",
    color: "#000",
    textAlign: "left"
}

const Container = styled.div`
    padding-top: 13px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
`
const Path = styled.span`
    font-size: 12px;
    line-height: 22px;
`

const Element = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    white-space: nowrap ;
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`

const Percentage = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    span {
        font-weight: bold;
        font-size: 14px;
    }
`
const Tag = styled.span`
    font-size: 12px;
    line-height: 22px;
`

const Sessions = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    span {
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
    }
`
const FlowNode = ({ data, id }) => {
    let color = constants.edgeColors.green
    if (data.hasOnlyErrors) {
        color = constants.edgeColors.red
    }

    return (
        <NodeWrapper style={customNodeStyles} isHighlighted={data.isHighlighted} id={id}>
            {(!data.start || data.hasLeadsFrom) && (
                <>
                    <Handle
                        type={constants.handleMethod.target}
                        position={constants.handlePosition.left}
                        top='50%'
                        id={constants.handleType.mid}
                        //  color={data.percentage === "100" ? constants.edgeColors.green : constants.edgeColors.blue}
                        color={constants.edgeColors.green}
                    />
                </>
            )}
            <Container>
                <Path>{data.path || "N/A"}</Path>
                <Element>{data.element || "N/A"}</Element>
                <Tag>
                    {data.tag} {data.type}
                </Tag>

                <Sessions>
                    <IconPeople />
                    <span>{data.entered}</span>
                    <Percentage>
                        <span>{data.percentage ? `${data.percentage}% ` : ""}</span>
                    </Percentage>
                </Sessions>
            </Container>

            {/* {!data.end && ( */}
            <Handle
                type={constants.handleMethod.source}
                position={constants.handlePosition.right}
                top='50%'
                id={constants.handleType.mid}
                //  color={data.directPercentage === "100" ? constants.edgeColors.green : constants.edgeColors.blue}
                color={color}
            />
            {/* )} */}
        </NodeWrapper>
    )
}

FlowNode.propTypes = {
    data: PropTypes.shape({
        start: PropTypes.bool,
        hasLeadsFrom: PropTypes.bool,
        isHighlighted: PropTypes.bool,
        path: PropTypes.string,
        element: PropTypes.string,
        tag: PropTypes.string,
        type: PropTypes.string,
        entered: PropTypes.number,
        end: PropTypes.bool,
    }),
}
FlowNode.defaultProps = {
    data: {
        start: false,
        isHighlighted: null,
        path: "/",
        element: "N/A",
        tag: "N/A",
        type: "N/A",
        entered: 0,
        percentage: 0,
        end: false,
    },
}

export default FlowNode
