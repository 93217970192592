import { capitalCase } from 'change-case';
import { m, motion, useViewportScroll, useTransform, useElementScroll } from 'framer-motion';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Stack,
  Radio,
  Tooltip,
  Container,
  Typography,
  RadioGroup,
  CardActionArea,
  FormControlLabel,
} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Image from '../../components/Image';
import { MotionViewport, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginTop: 50,

  [theme.breakpoints.up('md')]: {
    height: 600
  },
}));


// ----------------------------------------------------------------------

export default function HomeColorPresets() {
  const { themeColorPresets, onChangeColor, colorOption } = useSettings();





  return (
    <RootStyle >
      <Container component={MotionViewport} sx={{ position: 'relative', textAlign: 'center' }}>
        <m.div variants={varFade().inUp}>
          <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
            Record flows
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Set up your user flows
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light' ? 'text.secondary' : 'text.primary',
            }}
          >
            Record the most critical flows for your business
          </Typography>
        </m.div>



        <StyledBox sx={{}}>
          <Image
            disabledEffect
            alt="grid"
            src="/images/dashboard/grid.webp"
          />

          <Box sx={{ position: 'absolute', top: 0 }}>
            <m.div variants={varFade().inUp} style={{ borderRadius: "15px", padding: 10, boxShadow: "-40px 40px 80px 0 rgb(145 158 171 / 40%)" }}>
              <Image
                disabledEffect
                alt="screen"
                src={`/images/dashboard/dashboard_background.webp`}
              />
            </m.div>
          </Box>

          <Box sx={{ position: 'absolute', top: "15%", left: "20%", width: '75%' }} >

            <m.div variants={varFade().inDown}

              whileHover={{
                // scale: [1, 1.1, 1],
                //    y: [0, -10, 0],
                transition: { duration: 2 },
              }}

            >
              <m.div

                // style={{
                //   scaleY: scrollYProgress
                // }}
                animate={{ y: [0, -20, 0], x: [0, 0, 0] }} transition={{ duration: 5, repeat: Infinity }}

              >
                <Image

                  disabledEffect
                  alt="sidebar"
                  src={`/images/dashboard/flowsrecorded.webp`}
                />
              </m.div>
            </m.div>

          </Box>

          {/* <Box sx={{ position: 'absolute', top: 0 }}>
            <m.div variants={varFade().inDown}>
              <m.div animate={{ y: [-5, 10, -5] }} transition={{ duration: 8, repeat: Infinity }}>
                <Image
                  disabledEffect
                  alt="sidebar"
                // src={`https://minimal-assets-api.vercel.app/assets/images/home/theme-color/block2-${themeColorPresets}.png`}
                />
              </m.div>
            </m.div>
          </Box> */}

          {/* <Box sx={{ position: 'absolute', top: 0 }}>
            <m.div variants={varFade().inDown}>
              <m.div animate={{ y: [-25, 5, -25] }} transition={{ duration: 10, repeat: Infinity }}>
                <Image
                  disabledEffect
                  alt="sidebar"
                // src={`https://minimal-assets-api.vercel.app/assets/images/home/theme-color/sidebar-${themeColorPresets}.png`}
                />
              </m.div>
            </m.div>
          </Box> */}
        </StyledBox>
      </Container>
    </RootStyle >
  );
}
