import { m } from "framer-motion"
import NextLink from "next/link"
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles"
import { Box, Card, Container, Typography, Stepper, Step, StepLabel, Grid } from "@mui/material"
// components
// import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import Iconify from "../../components/Iconify"
import Image from "next/image"
// ----------------------------------------------------------------------
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 40,
        left: "20px", // Adjust the starting point of the line
        right: "20px", // Adjust the ending point of the line
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#fff",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#fff",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
        borderTopWidth: 1, // Adjust the width of the line as needed
        borderRadius: 1,
    },
}))

const CARDS = [
    {
        icon: "/images/data-safety/1.png",
        title: "Data encryption",
        description: "End to end encryption.",
        href: "/privacy/",
    },
    {
        icon: "/images/data-safety/2.png",
        title: "Data ownership",
        description: "You are the sole owner of data.",
        href: "/privacy/",
    },
    {
        icon: "/images/data-safety/3.png",
        title: "Data anonymisation",
        description: "No user identification unless requested",
        href: "/data-protection/",
    },
    // {
    //     icon: "/images/data-safety/4.png",
    //     title: "Data anonymisation",
    //     description: "No sensitive information is collected unless requested.",
    //     href: "/privacy/",
    // },
]

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`

const RootStyle = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(12),
    },
    [theme.breakpoints.up("xs")]: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(4),
    },
}))

const CardStyle = styled(Card)(({ theme }) => {
    const shadowCard = (opacity) =>
        theme.palette.mode === "light" ? alpha(theme.palette.grey[500], opacity) : alpha(theme.palette.common.black, opacity)

    return {
        border: 0,
        maxWidth: 380,

        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",

        marginBottom: theme.spacing(2), // Add margin to separate the cards vertically
        position: "relative", // Add relative positioning to the card

        [theme.breakpoints.up("xs")]: {
            justifyContent: "center",
            boxShadow: "none",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            display: "flex",
        },
    }
})

// ----------------------------------------------------------------------

export default function HomeMinimal() {
    const theme = useTheme()

    const isLight = theme.palette.mode === "light"

    return (
        <RootStyle>
            <Container component={MotionViewport}>
                {/* <Box
                    sx={{
                        textAlign: "center",
                        mb: { xs: 10, md: 10 },
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <m.div variants={varFade().inUp}>
                        <Typography component='div' variant='overline' sx={{ mb: 2, color: "#505668" }}>
                            DATA PROTECTION
                        </Typography>
                    </m.div>
                    <m.div variants={varFade().inDown}>
                        <Typography variant='h2' sx={{ fontFamily: "Poppins, sans-serif", maxWidth: "750px" }}>
                            We care about your data
                        </Typography>
                    </m.div>
                </Box> */}

                {/* <Stepper
                    activeStep={0}
                    alternativeLabel
                    connector={<QontoConnector />}
                    sx={{
                        display: "grid",
                        gap: { xs: 2, lg: 5 },
                        gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
                    }}
                >
                    {CARDS.map((card, index) => (
                        <Step key={`${card?.title}${index + 1}`}>
                            <m.div variants={varFade().inUp} key={card.title}>
                                <CardStyle>
                                    <img height='90px' width='90px' style={{ borderRadius: "12px" }} src={card.icon} alt={card.title} />
                                    <Typography variant='h5' sx={{ fontFamily: "Poppins, sans-serif", mt: 2, mb: 1 }}>
                                        {card?.title}
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            color: "text.secondary",

                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 500,
                                            lineHeight: "1.778rem",
                                            fontSize: { xs: "15px", md: "1rem" },
                                        }}
                                        gutterBottom
                                    >
                                        {card?.description}
                                    </Typography>
                                </CardStyle>
                            </m.div>
                        </Step>
                    ))}
                </Stepper> */}
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                        mt: 2,
                        alignItems: "center",
                        justifyItems: "center",
                        borderRadius: "12px",
                        background: "#00150D",
                    }}
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                        sx={{
                            height: "70px",
                            width: "70px",

                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",

                            alignItems: "center",
                            justifyItems: "center",
                        }}
                    >
                        <Image
                            height='60px'
                            width='60px'
                            style={{
                                borderRadius: "12px",
                                marginRight: "20px",
                                filter: "brightness(0) invert(1)",
                                color: "white",
                            }}
                            alt='GDPR flowpoint.ai'
                            src='/images/data-safety/GDPR-icon-dark.svg'
                        />
                        <Typography variant='subtitle1' sx={{ color: "white" }}>
                            GDPR compliance
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        sx={{
                            height: "70px",
                            width: "70px",

                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",

                            alignItems: "center",
                            justifyItems: "center",
                        }}
                    >
                        <Image
                            height='60px'
                            width='60px'
                            style={{
                                borderRadius: "12px",
                                marginLeft: "20px",
                                filter: "brightness(0) invert(1)",
                                color: "white",
                            }}
                            alt='CCPA flowpoint.ai'
                            src='/images/data-safety/CCPA-icon-dark.svg'
                        />
                        <Typography variant='subtitle1' sx={{ color: "white" }}>
                            CCPA compliance
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        sx={{
                            height: "70px",
                            width: "70px",

                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignContent: "center",

                            alignItems: "center",
                            justifyItems: "center",
                        }}
                    >
                        <Image
                            height='70px'
                            width='70px'
                            alt='ico flowpoint.ai'
                            style={{ borderRadius: "12px", marginLeft: "20px", color: "white" }}
                            src='/images/data-safety/ICO_Logo_WhiteOnBlue.webp'
                        />

                        <Typography variant='subtitle1' sx={{ color: "white" }}>
                            ICO compliance
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    )
}
