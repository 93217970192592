import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Popover, Badge } from "@mui/material"
import getNodesDetails from "../../../utils/flowHelpers/getNodesDetails"
import constants from "../../../utils/constants"
import { IconButtonAnimate } from "../../animate"
import Iconify from '../../Iconify';


import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  Collapse,
  TableCell as DefaultTableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton
} from '@mui/material';
// redux

// utils
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import {
  ProductMoreMenu,
  ProductListHead,
  ProductListToolbar,
} from '../../../sections/@dashboard/e-commerce/product-list';

const TABLE_HEAD = [
    { id: 'empty1' },
    { id: 'name', label: 'Insight', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'empty2' },
  ];

// const issues = [
//     {id:"s", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
//     {id:"s2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
//     {id:"s3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
//     {id:"s4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
// ]

const StyledTableContainer = styled(TableContainer)`
`

const TableCell = styled(DefaultTableCell)`
padding:5px;
`

const IssuePopoverContainer = styled.div`
    width: 550px;
    height: 400px;
    background: #ffffff;
    border: ${props => `1px solid ${props.color}`};
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
    padding: 29px 27px;
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }
    
`
const StyledTableRow = styled(TableRow)`
    border-radius:8px;
    background-color: white !important;
    height:50px;
    
`

const StyledTableRowTimestamp = styled(TableRow)`
    margin-top:10px;
    border-radius:8px;
    background-color: white !important;
    height:50px;
    justify-content:center;
    
`

const StyledPopover = styled(Popover)`

    .MuiPopover-paper {
        
        border-radius: 16px;
        margin-top: 10px;
        box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
        opacity:1 !important;
      
    }
    
   
`
const IssueName = styled.h1`
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    margin-right:5px;
    word-wrap: break-word;
    margin-bottom: 10px;
`

const IssueDetailsContainer = styled.div`
    overflow-y: scroll;
   
    margin-bottom: 10px;
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }
`
const IssueDetails = styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 10px;
    word-wrap: break-word;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const SimpleButton = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
`

const InsightsContainer = styled.div`
height:270px;
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}
`

const StyledBadge = styled(Badge)`
    .MuiBadge-overlapRectangular {
        background-color: ${(props) => props.customcolor} !important;
        right: -25px;
        bottom: 0px;
        top: auto;
    }
`

const TitleContainer = styled.div`
  display:flex;
  flex-direction:row;
`

const CloseButtonContainer = styled.div`
position:absolute;
top:10px;
right:10px;
`

const today = Date.now()

const day = 60 * 60 * 24 * 1000 * 7 

const insights = [{
    timestamp:  today,
    data:[
        {id:"s", name:"Remove external links", description:"Your product descriptions have too many external links, your users are encouraged to click on them and many don’t come back.", category:"UI/UX"},
        {id:"s2", name:"“Add to cart” button visibility", description:"Some of your users scroll in order to find the button.", category:"UI/UX"},
        {id:"s3", name:"Product details visibility", description:"Place product details together so users don't have to scroll to find them! Include all required purchasing details on your product page! Your users might be looking for additional details which don’t exist on your product page.", category:"UI/UX"},
        {id:"s4", name:"Smaller images", description:"Use smaller images or adapt the requested image sizes for slow internet. Your product images load too slow on bad internet.", category:"UI/UX"}
    ],
}, 
// {
//     timestamp:  new Date(today - day),
//     data:[
//         {id:"t", name:"fix bla bla bla 1", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"marketing"},
//         {id:"t2", name:"fix bla bla bla 2", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
//         {id:"t3", name:"fix bla bla bla 3", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"},
//         {id:"t4", name:"fix bla bla bla 4", description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis leo interdum lacus porta, tempor feugiat diam rutrum. Nulla facilisi. Morbi consectetur lectus eu nulla eleifend,", category:"technical"}
//     ],
// }
]


const InsightsPopover = ({  open, handleClose, click, clickedElementData: { data, id } }) => {

    const [isExpanded, setExpanded] = useState(false)
    const [selected, setSelected] = useState([]);
    const [isRowExpanded, setRowExpanded] = useState(null)

    const type = (data?.class && getNodesDetails[data?.class][data?.type]?.name) || data?.type


    const subtype =
        (data?.class && data?.subtype && getNodesDetails[data?.class][data?.type]?.subtype[data?.subtype]?.name) || data?.subtype

    const color = data?.isWarning ? constants.edgeColors.yellow : constants.edgeColors.red
    const emptyRows = 0;
    const isNotFound = false;


    const handleSelectAllClick = (checked) => {
        if (checked) {
          const selected = insights.map((n) => n.name);
          setSelected(selected);
          return;
        }
        setSelected([]);
      };
    const handleClick = (index) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
      };

    return (
        <StyledPopover
            key={"popoverInsightsNode"}
            open={open}
            anchorEl={click}
            onClose={(event) => {
                if (handleClose) {
                    handleClose(event)
                }
                setExpanded(false)
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <IssuePopoverContainer isExpanded={isExpanded} id={"popoverInsightsNode"} key={"popoverInsightsNode"}>
                <CloseButtonContainer>
                    <IconButtonAnimate onClick={handleClose}>
                            <Iconify icon={'eva:close-fill'} width={20} height={20} />
                        </IconButtonAnimate>
                  </CloseButtonContainer>
                  <TitleContainer>
                  <IssueName>
                        All insights
                    </IssueName>
                <StyledBadge badgeContent={data?.occurences} color='primary' customcolor={color} max={9999999}>
                 
                </StyledBadge>
                </TitleContainer>
                <IssueDetailsContainer>
                    <IssueDetails>{data?.message}</IssueDetails>
                    <IssueDetails>{data?.stack}</IssueDetails>
                    </IssueDetailsContainer>
                    <InsightsContainer>
                    <Scrollbar >
            <StyledTableContainer sx={{ maxWidth: 550, maxHeight:400 }}>
              <Table>
              <ProductListHead
                  order={'asc'}
                  orderBy={"name"}
                  headLabel={TABLE_HEAD}
                  rowCount={1}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  style={{paddingBottom:50}}
                />
              {insights.map((insightItem, index) => (
                  <>
               

                <TableBody key={`table${index + 1}`} style={{position:"relative"}}>
                    <StyledTableRowTimestamp
                            // hover
                            key={`rowid${insightItem.timestamp}`}
                            tabIndex={-1}
                        >
                            <TableCell width="20px" ></TableCell>
                            <TableCell padding="checkbox"> </TableCell>
                           <TableCell sx={{ display: 'flex', alignItems: 'center', width:'100%', height:50, position:'absolute', left:0, right:0 }}>
                         
                         <Typography variant="subtitle2" >
                         {fDate(insightItem.timestamp)}
                         </Typography>
                
                        
                          </TableCell>
                          <TableCell style={{ minWidth: 50 }}></TableCell>
                          <TableCell align="right"></TableCell>
                          </StyledTableRowTimestamp>
                  {insightItem?.data.map((row, index) => {
                    const { id: rowId, name,  category, description } = row;

                    const isItemSelected = selected.indexOf(index) !== -1;

                    return (
                        <>
                      <StyledTableRow
                        // hover
                        key={`row${id}${rowId}`}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                              <TableCell width="20px" >
                    <IconButton size="small" onClick={(row) => setRowExpanded(isRowExpanded === index ? null : index)}>
                        <Iconify icon={isRowExpanded === index ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
                    </IconButton>
                </TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={() => handleClick(index)} />
                        </TableCell>
                        <TableCell sx={{ display: 'flex', alignItems: 'center', width:220, height:50 }}>
                         
                          <Typography variant="subtitle2" >
                            {name}
                          </Typography>
                        </TableCell>
                        
                        <TableCell style={{ minWidth: 50 }}>
                          <Label
                            variant={"filled"}
                            color={
                              (category === 'technical' && 'error') ||
                              'success'
                            }
                          >
                            {category ? category : ''}
                          </Label>
                        </TableCell>
                  
                        <TableCell align="right">
                          <ProductMoreMenu productName={name} onDelete={() => handleDeleteProduct(id)} />
                        </TableCell>
                      </StyledTableRow>
                       <TableCell style={{ borderRadius:8, paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, background: '#F4F6F8' }} colSpan={5}>
                       <Collapse in={isRowExpanded!==null && isRowExpanded === index} timeout="auto" unmountOnExit>
                           <Box sx={{ padding: 1, maxWidth: 480 }}>
                            <Typography variant="subtitle2" >
                                {description}
                            </Typography>
                          </Box>
                       </Collapse>
                        </TableCell>
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody></>))}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Box sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </StyledTableContainer>
          </Scrollbar>
          </InsightsContainer>
               

                <ButtonContainer>
                
                  {/* <IconButtonAnimate onClick={() => console.log("sharing this")}>
                            <Iconify icon={'carbon:share'} width={20} height={20} />
                        </IconButtonAnimate> */}
                
                    {/* {trimmedStack?.length > STACK_THRESHOLD && (<SimpleButton onClick={() => setExpanded(!isExpanded)}>
                        {isExpanded ? "Less info" : "More info"}
                    </SimpleButton>)} */}
                </ButtonContainer>
            </IssuePopoverContainer>
        </StyledPopover>
    )
}
InsightsPopover.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    click: PropTypes.shape({}),
    data: PropTypes.shape({
        type: PropTypes.string,
        subtype: PropTypes.string,
    }),
    clickedElementData: PropTypes.shape({
        data: PropTypes.shape({
            stack: PropTypes.string,
            message: PropTypes.string,
            type: PropTypes.string,
            subtype: PropTypes.string,
            class: PropTypes.string,
            occurences: PropTypes.number,
        }),
    }),
}
export default InsightsPopover
