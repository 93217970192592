import PropTypes from "prop-types"
import { useRef, useState } from "react"
// @mui
import { styled } from "@mui/material/styles"
import { Stack, Input, Divider, IconButton, InputAdornment, Typography, Button, Card, Grid, Box } from "@mui/material"

// components
import Iconify from "src/components/Iconify"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    minHeight: 45,
    display: "flex",
    position: "relative",
    alignItems: "center",
    paddingLeft: theme.spacing(5),
    marginBottom: "5px",
    border: "1px solid #8e8ea0",
    borderRadius: "20px",
}))

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
    disabled: PropTypes.bool,
    conversationId: PropTypes.string,
    onSend: PropTypes.func,
    hideVariants: PropTypes.bool,
}

export default function ChatMessageInput({ disabled, conversationId, onSend, hideVariants }) {
    const fileInputRef = useRef(null)
    const [message, setMessage] = useState("")

    const handleAttach = () => {
        fileInputRef.current?.click()
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            handleSend()
        }
    }
    const handlePresetMessage = (presetMessage) => {
        setMessage(presetMessage)

        // Send the message immediately
        if (onSend) {
            onSend({
                conversationId,
                message: presetMessage,
                // contentType: "text",
                // attachments: [],
            })
        }
        // Clear the message input after sending
        setMessage("")
    }

    const handleSend = () => {
        if (!message) {
            return ""
        }
        if (onSend) {
            onSend({
                conversationId,
                message,
                // contentType: "text",
                // attachments: [],
            })
        }
        return setMessage("")
    }
    const textVariants = [
        {
            text: "What are the top features used by active users in the last 30 days?",
            title: "What are the top features",
            description: "used by active users in the last 30 days?",
        },
        {
            text: "What user actions correlate with high conversion rates?",
            title: "What user actions",
            description: "correlate with high conversion rates?",
        },
        {
            text: "What common traits do users who churn after one month share?",
            title: "What common traits",
            description: "do users who churn after one month share?",
        },
        {
            text: "How does page load time affect user retention and satisfaction?",
            title: "How does page load time affect",
            description: " user retention and satisfaction?",
        },
    ]

    return (
        <Box sx={{ width: { xl: "75%", lg: "85%", md: "90%", xs: "95%" } }}>
            {hideVariants && (
                <Grid container spacing={1} sx={{ mb: 2 }}>
                    {textVariants.slice(0, 4).map((text, index) => (
                        <Grid item xs={6} key={index}>
                            <Button
                                key={index}
                                variant='outlined'
                                disabled={disabled}
                                onClick={() => handlePresetMessage(text?.text)}
                                sx={{
                                    width: "100%",
                                    borderRadius: "12px",
                                    minHeight: "70px",
                                    borderColor: "lightgray",
                                    display: "flex",
                                    alignContent: "flex-start",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography variant='caption' sx={{ fontWeight: "500", fontSize: "14px", textTransform: "none" }}>
                                    {text?.title}
                                </Typography>
                                <Typography
                                    variant='caption'
                                    sx={{ fontWeight: "500", color: "grey", fontSize: "14px", textTransform: "none", textAlign: "left" }}
                                >
                                    {text?.description}
                                </Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            )}

            <RootStyle>
                <Input
                    disabled={disabled}
                    fullWidth
                    value={message}
                    disableUnderline
                    onKeyUp={handleKeyUp}
                    onChange={(event) => setMessage(event.target.value)}
                    placeholder={disabled ? "" : "Type a message"}
                />

                {/* <Divider orientation='vertical' flexItem /> */}

                <IconButton color='primary' disabled={!message} onClick={handleSend} sx={{ mx: 0.8 }}>
                    <Iconify icon='solar:square-arrow-up-bold' width={38} height={38} />
                </IconButton>

                <input type='file' ref={fileInputRef} style={{ display: "none" }} />
            </RootStyle>
        </Box>
    )
}
