import PropTypes from "prop-types"
import { paramCase } from "change-case"
// next
import NextLink from "next/link"
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Box, Card, Avatar, Typography, CardContent, Link, Stack } from "@mui/material"
// routes
import { PATH_DASHBOARD, PATH_POST } from "../../../routes/paths"
// hooks
import useResponsive from "../../../hooks/useResponsive"
// utils
import { fDate } from "../../../utils/formatTime"
import { fShortenNumber } from "../../../utils/formatNumber"
// components
import Image from "../../../components/Image"
import Iconify from "../../../components/Iconify"
import TextMaxLine from "../../../components/TextMaxLine"
import SvgIconStyle from "../../../components/SvgIconStyle"
import TextIconLabel from "../../../components/TextIconLabel"

// ----------------------------------------------------------------------

const OverlayStyle = styled("div")(({ theme }) => ({
    top: 0,
    zIndex: 1,
    width: "100%",
    height: "100%",
    position: "absolute",
    // backgroundColor: alpha(theme.palette.grey[900], 0.8),
    background: "#00140C",
    opacity: "0.8",
}))

// ----------------------------------------------------------------------

export default function BlogPostCard({
    cover,
    avatarUrl,
    title,
    view,
    comment,
    share,
    author,
    createdAt,
    index,
    authorName,
    linkTo,
    authorAvatarUrl,
}) {
    const isDesktop = useResponsive("up", "md")

    // const { cover, title, view, comment, share, author, createdAt } = post;

    const latestPost = index === 0 || index === 1 || index === 2

    if (isDesktop && latestPost) {
        return (
            <Card>
                <Avatar
                    alt='Vlad Niculescu'
                    src={avatarUrl}
                    sx={{
                        zIndex: 9,
                        top: 24,
                        left: 24,
                        width: 40,
                        height: 40,
                        position: "absolute",
                    }}
                />
                <PostContent title={title} index={index} linkTo={linkTo} />

                <a
                    href={linkTo}
                    passHref
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: isDesktop ? "white" : "black", textDecoration: "none" }}
                    aria-label='Read more about our blog'
                >
                    <OverlayStyle />
                </a>

                <Image alt='cover' src={cover} sx={{ height: 360 }} />
            </Card>
        )
    }

    return (
        <Card>
            <Box sx={{ position: "relative" }}>
                <SvgIconStyle
                    src='https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg'
                    sx={{
                        width: 80,
                        height: 36,
                        zIndex: 9,
                        bottom: -15,
                        position: "absolute",
                        color: "background.paper",
                    }}
                />
                <Avatar
                    alt={authorName}
                    src={avatarUrl}
                    sx={{
                        left: 24,
                        zIndex: 9,
                        width: 32,
                        height: 32,
                        bottom: -16,
                        position: "absolute",
                    }}
                />

                <a
                    href={linkTo}
                    passHref
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: isDesktop ? "white" : "black", textDecoration: "none" }}
                    aria-label='Read more about our blog'
                >
                    <Image alt='cover' src={cover} ratio='4/3' />
                </a>
            </Box>

            <PostContent linkTo={linkTo} title={title} view={view} comment={comment} share={share} />
        </Card>
    )
}

// ----------------------------------------------------------------------

export function PostContent({ title, view, comment, share, createdAt, index, linkTo }) {
    const isDesktop = useResponsive("up", "md")

    const latestPostLarge = index === 0
    const latestPostSmall = index === 1 || index === 2

    return (
        <CardContent
            sx={{
                pt: 4.5,
                width: 1,
                ...((latestPostLarge || latestPostSmall) && {
                    pt: 0,
                    zIndex: 9,
                    bottom: 0,
                    position: "absolute",
                    color: "common.white",
                }),
            }}
        >
            <Typography
                gutterBottom
                variant='caption'
                component='div'
                sx={{
                    color: isDesktop ? "text.disabled" : "grey",
                    ...((latestPostLarge || latestPostSmall) && {
                        opacity: 0.64,
                        color: isDesktop ? "white" : "black",
                    }),
                }}
            ></Typography>

            <a
                href={linkTo}
                passHref
                target='_blank'
                rel='noreferrer'
                style={{ color: isDesktop ? "white" : "#173363", textDecoration: "none" }}
            >
                <TextMaxLine
                    variant='body1'
                    line={2}
                    persistent
                    sx={{
                        fontWeight: "500",
                        lineHeight: "1.5714285714285714",
                        fontSize: isDesktop && latestPostLarge ? "1.2rem" : "0.875rem",
                        fontFamily: "Inter",
                    }}
                >
                    {title}
                </TextMaxLine>
            </a>

            <Stack
                flexWrap='wrap'
                direction='row'
                justifyContent='flex-end'
                sx={{
                    mt: 3,
                    color: "text.disabled",
                    ...((latestPostLarge || latestPostSmall) && {
                        opacity: 0.64,
                        color: "common.white",
                    }),
                }}
            ></Stack>
        </CardContent>
    )
}
