import { m, AnimateSharedLayout, useScroll } from "framer-motion";
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles";
import { useEffect, useState, useRef } from "react";
import { Box, Grid, Button, Container, Typography } from "@mui/material";
// components
import Image from "../../components/Image";
import { MotionViewport, varFade } from "../../components/animate";
import styledComponent from "styled-components";
import Iconify from "../../components/Iconify";
// ----------------------------------------------------------------------
const CardShadowTwo = styledComponent.div`
    border-radius: 10px;
    background: #212B36;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
    padding: 5px;
    color: white;
`;
const InsightsInProgressTwo = styledComponent.div`
    //box-shadow: 0px 0px 30px #dddddd82;
    border: 1px solid #DEDEDE;
    color: rgb(33, 43, 54);
    position: relative;
    border-radius: 14px;
    margin-top: 15px;
    align-content: center;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: 5px;
`;
const InsightText = styledComponent.p`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.57143;
    text-align: left;
`;
const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: "150px",
  paddingBottom: "50px",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    marginBottom: 0,
  },
}));

const COMMON = {
  scaleX: 0.86,
  skewY: 8,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  opacity: 0,
};

const variantScreenLeft = {
  initial: COMMON,
  animate: { ...COMMON, translateX: "-50%", translateY: 40, opacity: 1 },
};

const variantScreenCenter = {
  initial: COMMON,
  animate: { ...COMMON, opacity: 1 },
};

const variantScreenRight = {
  initial: COMMON,
  animate: { ...COMMON, translateX: "50%", translateY: -40, opacity: 1 },
};

// ----------------------------------------------------------------------

export default function HomeHugePackElements() {
  const theme = useTheme();

  const isLight = theme.palette.mode === "light";

  const isRTL = theme.direction === "rtl";

  const screenLeftAnimate = variantScreenLeft;

  const screenCenterAnimate = variantScreenCenter;

  const screenRightAnimate = variantScreenRight;

  return (
    <MotionViewport disableAnimatedMobile={false}>
      <RootStyle>
        <Container>
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6} dir="ltr">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Image
                  disabledEffect
                  src={"/images/test-gif.gif"}
                  style={{ borderRadius: "12px" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
              }}
            >
              <ContentStyle>
                {/* <m.div variants={varFade().inUp}>
                  <Typography
                    component="div"
                    variant="overline"
                    sx={{ mb: 2, color: "text.disabled" }}
                  >
                    Interface Starter Kit
                  </Typography>
                </m.div> */}

                <m.div variants={varFade().inUp}>
                  <Typography variant="h2" sx={{ mb: 3 }}>
                    Lorem Ipsum is simply dummy text
                  </Typography>
                </m.div>

                <m.div variants={varFade().inUp}>
                  <Typography
                    sx={{
                      mb: 5,
                      color: isLight ? "text.secondary" : "common.white",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </Typography>
                </m.div>

                <m.div variants={varFade().inUp}>
                  <InsightsInProgressTwo>
                    <CardShadowTwo>
                      <Iconify
                        icon="healthicons:i-schedule-school-date-time-outline"
                        sx={{ height: "30px", width: "30px" }}
                      />
                    </CardShadowTwo>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <InsightText
                        style={{
                          fontWeight: "400",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        Your first batch of insights will be processed soon.
                      </InsightText>
                    </div>
                  </InsightsInProgressTwo>
                  <InsightsInProgressTwo>
                    <CardShadowTwo>
                      <Iconify
                        icon="healthicons:i-schedule-school-date-time-outline"
                        sx={{ height: "30px", width: "30px" }}
                      />
                    </CardShadowTwo>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <InsightText
                        style={{
                          fontWeight: "400",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        Your first batch of insights will be processed soon.
                      </InsightText>
                    </div>
                  </InsightsInProgressTwo>
                  <InsightsInProgressTwo>
                    <CardShadowTwo>
                      <Iconify
                        icon="healthicons:i-schedule-school-date-time-outline"
                        sx={{ height: "30px", width: "30px" }}
                      />
                    </CardShadowTwo>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <InsightText
                        style={{
                          fontWeight: "400",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        Your first batch of insights will be processed soon.
                      </InsightText>
                    </div>
                  </InsightsInProgressTwo>
                </m.div>
              </ContentStyle>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </MotionViewport>
  );
}
