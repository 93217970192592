import { useState, useEffect, useRef } from "react"
import { AnimatePresence, m } from "framer-motion"
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Box, Divider, Container, Typography, useTheme, Grid, Card, Tooltip } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import styledComponent from "styled-components"
import { PageNotFoundIllustration } from "../../assets"
import Iconify from "../../components/Iconify"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// ----------------------------------------------------------------------

const IssueContainer = styledComponent.div`
  margin:auto;
   margin-top:10px;
  display: flex;
  height: 100%;
  margin:0px;
  margin-left:20px;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 35px;
    width: auto;
    height: auto;
  //  filter: grayscale(3) brightness(0.8);
   background-size: contain!important;
   background-repeat: no-repeat!important;
  }
  @media only screen and (max-width: 768px) {
  margin: 5px;
   
  }
`

const RootStyle = styled("div")(({ theme, paddingTop }) => ({
    // padding: theme.spacing(15, 0),
    paddingTop: theme.spacing(15),
    // paddingTop: "90px",
    [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
        paddingTop: paddingTop || theme.spacing(15),
        paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("xs")]: {
        paddingTop: theme.spacing(3),
        margin: "5px",
    },
    [theme.breakpoints.up("sm")]: {
        paddingTop: paddingTop || theme.spacing(15),
        paddingBottom: theme.spacing(2),
    },
}))

// ----------------------------------------------------------------------

export default function HomeCleanInterfaces({ paddingTopProp }) {
    const theme = useTheme()

    return (
        <RootStyle>
            <Container
                // component={MotionViewport}
                sx={{
                    display: "flex",
                    justifyContent: "center",

                    margin: "auto",
                    // overflow: "hidden",
                    padding: theme.spacing(2, 2),
                    borderRadius: "15px",

                    color: "#CCCCCC",

                    justifyItems: "center",
                    alignItems: "center",
                    alignContent: "center",
                }}
            >
                <Grid
                    spacing={2}
                    container
                    sx={{
                        justifyContent: "center",
                        justifyItems: "center",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Grid
                        container
                        sx={{
                            justifyContent: "center",
                            justifyItems: "center",
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                        }}
                    >
                        <Grid md={2} xs={12} item>
                            <IssueContainer>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: "black",
                                        fontWeight: "500",

                                        fontSize: "1rem",
                                    }}
                                >
                                    {" "}
                                    Backed by
                                </Typography>
                            </IssueContainer>
                        </Grid>
                        <Grid md={2} xs={3} item>
                            <IssueContainer>
                                <Image height={150} width={25} alt='customer' src='/images/customers/sfc-capital-logo-300x87.png' />
                            </IssueContainer>
                        </Grid>
                        <Grid md={3} xs={3} item>
                            <IssueContainer>
                                <Image height={51} width={160} alt='customer' src='/images/customers/Isometric-Ventures.png' />
                            </IssueContainer>
                        </Grid>
                        <Grid md={2} xs={3} item>
                            <Tooltip arrow title='Atomico Angel Program'>
                                <IssueContainer>
                                    <Image
                                        style={{ maxHeight: "28px" }}
                                        height={32}
                                        width={11}
                                        alt='customer'
                                        src='/images/customers/atomico.png'
                                    />
                                </IssueContainer>
                            </Tooltip>
                        </Grid>
                        <Grid md={2} xs={3} item>
                            <IssueContainer>
                                <Image
                                    height={30}
                                    width={164}
                                    alt='customer'
                                    src='/images/customers/Natwest_Secondary_Horizontal_RGB_NEG.png'
                                />
                            </IssueContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    )
}
