import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Popover, Badge } from "@mui/material"



const IssuePopoverContainer = styled.div`
    width: 362px;
    height: 250px;
    background: #ffffff;

    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
    padding: 29px 27px;
`
const StyledPopover = styled(Popover)`
    .MuiPopover-paper {
        border-radius: 16px;
        margin-top: 10px;
        box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
        opacity:0.8 !important;
        :hover{
            opacity:1 !important;
        }
    }
`
const IssueName = styled.h1`
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
    margin-bottom: 10px;
`

const IssueDetailsContainer = styled.div`
    overflow-y: scroll;
    height: 135px;
    margin-bottom: 10px;
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }
`
const IssueDetails = styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 10px;
    word-wrap: break-word;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const SimpleButton = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
`
const StyledBadge = styled(Badge)`
    .MuiBadge-overlapRectangular {
        right: -20px;
        bottom: 3px;
        top: auto;
    }
`

const FlowNodePopover = ({ open, handleClose, organization, click, clickedElementData: { data } }) => {
    const [isExpanded, setExpanded] = useState(false)
    let title = "Starting node"
    let details = "This is a starting node which shows the place a user came from before starting your flow"
    if (data?.parents?.length) {
        title = "Exit node"
        details = "This is an exit node which some of your users reached after leaving your predefined flow."
    }
    if (data?.dest === "Direct") {
        details = "Those users came directly to the page from an external source."
    }
    const baseUrl = organization?.metadata?.baseUrl || "flowpoint.ai"
    return (
        <StyledPopover
            open={open}
            anchorEl={click}
            onClose={(event) => {
                if (handleClose) {
                    handleClose(event)
                }
                setExpanded(false)
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <IssuePopoverContainer isExpanded={isExpanded}>
                {/* <StyledBadge badgeContent={data?.occurences} color='primary'> */}
                <IssueName>{title}</IssueName>
                {/* </StyledBadge> */}
                <IssueDetailsContainer>
                    <IssueDetails>{details}</IssueDetails>
                    {data?.dest !== "Direct" && (
                        <IssueDetails>
                            <a target='_blank' href={`${baseUrl}${data?.dest}`} rel='noreferrer'>{`${baseUrl}${data?.dest}`}</a>
                        </IssueDetails>
                    )}
                </IssueDetailsContainer>

                <ButtonContainer>
                    <SimpleButton onClick={handleClose}>Close</SimpleButton>
                </ButtonContainer>
            </IssuePopoverContainer>
        </StyledPopover>
    )
}
FlowNodePopover.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    click: PropTypes.shape({}),
    data: PropTypes.shape({
        type: PropTypes.string,
        subtype: PropTypes.string,
    }),
    clickedElementData: PropTypes.shape({
        data: PropTypes.shape({
            stack: PropTypes.string,
            message: PropTypes.string,
            type: PropTypes.string,
            subtype: PropTypes.string,
            class: PropTypes.string,
            occurences: PropTypes.number,
        }),
    }),
}
export default FlowNodePopover
