import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import NodeWrapper from "./NodeWrapper"
import constants from "../../../utils/constants"
import Handle from "../Handle"

const Container = styled.div`
    text-align: center;
    padding: 5px;
`

const NodeName = styled.p`
    color: ${(props) => (props.isCollapse ? constants.edgeColors.black : constants.edgeColors.red)};
    font-weight: bold;
    font-size: 16px;
`

const customNodeStyles = {
    //   boxShadow: "0px 12px 24px rgba(145, 158, 171, 0.12)",
    background: "#fff",
    padding: "0px 5px",
    borderRadius: 16,
    width: 101,
    height: 33,
    cursor: "pointer",
}

const AggregatedNode = ({ data, id }) => {
    return data.shouldDisplay ? (
        <NodeWrapper
            id={id}
            isHighlighted={data.isHighlighted}
            style={{
                ...customNodeStyles,
            }}
        >
            {data.issues > 0 && (
                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.left}
                    top='50%'
                    color={constants.edgeColors.red}
                />
            )}
            <div>
                <Container>
                    <NodeName isCollapse={data.issues === 0}>{data.issues > 0 ? ` + ${data.issues} more` : "collapse"} </NodeName>
                </Container>
            </div>
        </NodeWrapper>
    ) : (
        <div />
    )
}

AggregatedNode.propTypes = {
    data: PropTypes.shape({
        isHighlighted: PropTypes.bool,
        dest: PropTypes.string,
        issues: PropTypes.number,
        shouldDisplay: PropTypes.bool,
    }),
}
AggregatedNode.defaultProps = {
    data: {
        isHighlighted: false,
        dest: "",
    },
}
AggregatedNode.propTypes = {
    isCollapse: PropTypes.bool,
}
export default AggregatedNode
