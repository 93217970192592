import { cloneDeep } from "lodash"

function sortIssuesInFlow(flow) {
    // sorts issues by severity between each two flowpoints in a given flow
    // returns the same flow with an augmented array of sorted isssues;

    const output = cloneDeep(flow)

    let flowPoints = flow.flowPoints

    const flowPointsKeys = Object.keys(flowPoints)

    for (let i = 0; i < flowPointsKeys.length; i += 1) {
        const occurences = flowPoints[flowPointsKeys[i]].occurences

        const issues = flowPoints[flowPointsKeys[i]].issues

        if (issues) {
            const issuesKeys = Object.keys(issues)
            const sortedIssues = issuesKeys.sort((a, b) => {
                const scoreA = issues[a].occurencesOT * (issues[a].occurencesOT / issues[a].occurences)
                const scoreB = issues[b].occurencesOT * (issues[b].occurencesOT / issues[b].occurences)
                return scoreB - scoreA
            })
            output.flowPoints[flowPointsKeys[i]].sortedIssues = sortedIssues
        }
    }
    return output
}

export default sortIssuesInFlow

/*



numarul de exituri * procent de exituri ->

exituri ^ 2 / issues.occurences 


unu care apare de 5% si fute 99% din cei care vad bugul == corelate cu 5k exituri

^ cauzalitate probabil 5k exituri

exemplu:
CANNOT FINISH PAYMENT

unu care apare de 99% si fute 5% din cei care vad bugul == corelate cu 5k exituri

^ cauzalitate mai putine plm

exemplu:
error: undefined is not a function


*/
