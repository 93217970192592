import React from "react"
import PropTypes from "prop-types"

import { Badge } from "@mui/material"
import styled from "styled-components"
import constants from "../../../utils/constants"
import NodeWrapper from "./NodeWrapper"
import Handle from "../Handle"
import capitalizeFirstLetter from "../../../utils/capitaliseFirstLetter"
import getNodesDetails from "../../../utils/flowHelpers/getNodesDetails"

const Container = styled.div`
    text-align: center;
`

const IssueName = styled.p`
    font-size: 12px;
    color: ${(props) => props.color};
    font-weight: bold;
    line-height: 8px;
`

const StyledBadge = styled(Badge)`
    .MuiBadge-overlapRectangular {
        background-color: ${(props) => props.customcolor} !important;
    }
`

const IssueType = styled.p`
    font-size: 10px;
    font-weight: normal;
    line-heigh: 8px;
    color: ${constants.textColors.grey};
`
const customNodeStyles = {
    background: constants.edgeColors.lightRed,
    color: constants.edgeColors.red,
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    padding: "10px 10px",
    borderRadius: 16,
    width: 101,
    height: 37,
}

const TechnicalIssueNode = ({ data, id }) => {
    const { isWarning, isBounceWarning } = data
    let color = isWarning ? constants.edgeColors.yellow : constants.edgeColors.red
    let lightColor = isWarning ? constants.edgeColors.lightYellow : constants.edgeColors.lightRed
    if (isBounceWarning) {
        color = constants.edgeColors.orange
        lightColor = constants.edgeColors.lightOrange
    }

    const type = getNodesDetails.technical[data.type]?.name || data.type
    const subtype = getNodesDetails.technical[data.type]?.subtype[data.subtype].name || data.subtype
    return (
        <StyledBadge badgeContent={data.occurences} color='primary' customcolor={color} max={99999} >
            <NodeWrapper style={{ ...customNodeStyles, background: lightColor, color }} isHighlighted={data.isHighlighted} id={id}>
                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.left}
                    id={constants.handleType.mid}
                    top='50%'
                />

                <Handle type={constants.handleMethod.target} id={constants.handleType.top_center} top='-15%' />

                <Handle type={constants.handleMethod.source} id={constants.handleType.top_center} top='-15%' />

                <div>
                    <Container>
                        {/* <Occurences>{data.occurences}x</Occurences> */}
                        <IssueName>
                            {data.id}
                            {type} {subtype}
                        </IssueName>
                        <IssueType>{capitalizeFirstLetter(data.class)} </IssueType>
                    </Container>
                </div>
                <Handle
                    type={constants.handleMethod.source}
                    position={constants.handlePosition.right}
                    id={constants.handleType.mid}
                    top='50%'
                />

                <Handle
                    type={constants.handleMethod.source}
                    position={constants.handlePosition.bottom}
                    id={constants.handleType.bottom_center}
                    top='85%'
                />

                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.bottom}
                    id={constants.handleType.bottom_center}
                    top='85%'
                />
            </NodeWrapper>
        </StyledBadge>
    )
}

TechnicalIssueNode.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        subtype: PropTypes.string,
        sourceHandle: PropTypes.string,
        targetHandle: PropTypes.string,
        isWarning: PropTypes.bool,
        isBounceWarning: PropTypes.bool,
        isHighlighted: PropTypes.bool,
        occurences: PropTypes.number,
        class: PropTypes.string,
    }),
}
TechnicalIssueNode.defaultProps = {
    data: {
        type: "issue",
        subtype: "technical",
        isHighlighted: null,
    },
}

export default TechnicalIssueNode
