import { m } from "framer-motion"
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles"
import { Box, Card, Container, Typography, Grid, Link } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import Iconify from "../../components/Iconify"

const RootStyle = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up("xs")]: {
        paddingTop: theme.spacing(5),
    },
}))

const CardStyle = styled(Card)(({ theme }) => {
    return {
        border: 0,
        maxWidth: "100%",
        flexDirection: "row",
        margin: "auto",
        textAlign: "left",
        padding: theme.spacing(2),
        boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up("md")]: {
            boxShadow: "none",
        },
        "&.cardLeft": {
            boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            "&:before": {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                content: "''",
                margin: "auto",
                position: "absolute",
                width: "calc(100% - 40px)",
                height: "calc(100% - 40px)",
                borderRadius: Number(theme.shape.borderRadius) * 1,
            },
        },
    }
})

// ----------------------------------------------------------------------

export default function HomeGPTs() {
    return (
        <RootStyle>
            <Container>
                <m.div variants={varFade().inUp}>
                    <CardStyle className={"cardLeft"}>
                        <Link
                            target='_blank'
                            rel='noopener'
                            href='https://chat.openai.com/g/g-sNVZT6oAU-website-audit-ux-ui-content-by-flowpoint-ai/'
                        >
                            <Grid container>
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    sx={{ textAlign: "center", display: "flex", alignContent: "center", justifyContent: "center" }}
                                >
                                    <Typography
                                        variant='subtitle2'
                                        sx={{
                                            fontFamily: "Poppins, sans-serif",
                                            color: "white",
                                            background: "black",
                                            padding: "1px",
                                            width: "120px",
                                            height: "25px",
                                            borderRadius: "5px",
                                            textAlign: "center",
                                            mb: { xs: 2, md: 0 },
                                        }}
                                    >
                                        Free GPTs
                                    </Typography>
                                </Grid>
                                <Grid item md={10} xs={12}>
                                    <Typography variant='subtitle2' sx={{ fontFamily: "Poppins, sans-serif" }}>
                                        Unlock your website's full potential with a Free Audit (UX/UI & Content) by Flowpoint.ai - Just
                                        enter your URL to get started!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Link>
                    </CardStyle>
                </m.div>
            </Container>
        </RootStyle>
    )
}
