import { useState } from "react";
import { m } from "framer-motion";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Container,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
// components
import Image from "../../components/Image";
import { MotionViewport, varFade } from "../../components/animate";
import styledComponent from "styled-components";
import { PageNotFoundIllustration } from "../../assets";
import Iconify from "../../components/Iconify";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ----------------------------------------------------------------------

const IssueContainer = styledComponent.div`
  margin:auto;
  margin-top:10px;
  display: flex;
  height: 100%;
  margin:0px;
  margin-left:20px;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  img{
    max-width: 100%;
    height: 70px;
    width: auto;
   
  }
  @media only screen and (max-width: 768px) {
  margin: 5px;
   
  }
`;

const RootStyle = styled("div")(({ theme }) => ({
  // padding: theme.spacing(15, 0),
  // paddingTop: "90px",
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.up("lg")]: {
    // paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(7),
  },
  [theme.breakpoints.up("xs")]: {
    paddingBottom: theme.spacing(7),
    margin: "5px",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 720,
  margin: "auto",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    zIndex: 11,
    textAlign: "center",
    position: "relative",
  },
}));

// ----------------------------------------------------------------------

export default function HomeCleanInterfaces() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [isButtonHovered, setButtonHovered] = useState(false);
  const notify = (index) =>
    toast.error("🦄 Strange error!", {
      position: "top-right",
      toastId: `toast${index}`,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  setInterval(() => notify(1), 5100);
  setInterval(() => notify(2), 2500);

  return (
    <RootStyle>
      <Container
        component={MotionViewport}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "auto",
          overflow: "hidden",
          padding: '30px',
          borderRadius: "15px",
          backgroundColor: "#EFF2FB",
          color: "white",
        }}
      >
        {/* <ContentStyle>
          <m.div variants={varFade().inUp}>
            <Typography
              variant="h5"
              paragraph
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 5,
                ...(!isLight && {
                  textShadow: (theme) =>
                    `4px 4px 16px ${alpha(theme.palette.grey[800], 0.48)}`,
                }),
              }}
            >
              Flowpoint is trusted by customers around the world
            </Typography>
          </m.div>
        </ContentStyle> */}
        <Grid
          spacing={2}
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid md={3} xs={5} item  sx={{
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
          }}>
            <IssueContainer>
              <a href="https://www.capterra.com/reviews/282505/Flowpoint?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
                {" "}
                <img
                  border="0"
                  src="https://assets.capterra.com/badge/477a5b1982d21ded980739b6a0521a97.svg?v=2229841&p=282505"
                />{" "}
              </a>
            </IssueContainer>
          </Grid>

          <Grid md={3} xs={5} item >
            <IssueContainer>
              <a href="https://www.getapp.com/business-intelligence-analytics-software/a/flowpoint/reviews/">
                {" "}
                <img
                  border="0"
                  src="https://www.getapp.com/ext/reviews_widget/v1/dark/flowpoint-application"
                />{" "}
              </a>
            </IssueContainer>
          </Grid>

          <Grid md={3} xs={5} item sx={{
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
          }}>
            <IssueContainer>
              <a href="https://www.softwareadvice.com/marketing/flowpoint-profile">
                {" "}
                <img
                  border="0"
                  src="https://badges.softwareadvice.com/reviews/47ec8b2b-f7a3-496e-a85d-17c697387d10"
                />{" "}
              </a>
            </IssueContainer>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
