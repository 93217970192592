import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import constants from "../../../utils/constants"
import NodeWrapper from "./NodeWrapper"
import { Badge } from "@mui/material";
import { styled as materialStyled } from '@mui/material/styles';
import { Typography, Card, CardActionArea, CardContent, CardMedia, Popover } from '@mui/material';

import Handle from "../Handle"
import IconPeople from "../../../assets/icon_people"

const StyledButton = styled.button`
    margin-top:10px;
    background-color: black;
    box-shadow: 0px 4px 4px rgba(145, 158, 171, 0.1);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    width: 70px;
    padding: 0px 10px;
    height: 30px;
    font-weight: bold;
    color: white;
    display: initial;
    border-radius:8px;
`


const StyledCard = materialStyled(Card)(({ theme }) => ({
    width: 80,

    textAlign: "center",
    position: "relative",
    // background: "#daf1ff",
    // background:"#60d2fa",
    background:"transparent",
   border:"3px solid #ffd517",
    [theme.breakpoints.up('md')]: {
        width: 120,

    },

}));

const StyledCardContent = materialStyled(Card)(({ theme }) => ({
    padding: 12,
    // background: "#daf1ff",
    background:"transparent",
    borderColor:"#ffd517",
    borderWidth: 1,
    [theme.breakpoints.up('md')]: {
        padding: 18,
    },

}));



const customNodeStyles = {
    // height: 50,
    borderRadius: '15%',
    width: 120,
    scale: 2,
    // paddingTop: '40px'


}

const Container = styled.div`
    padding-top: 13px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
`
const Path = styled.span`
    font-size: 12px;
    line-height: 22px;
`

const Element = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    white-space: nowrap ;
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`

const Percentage = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    span {
        font-weight: bold;
        font-size: 14px;
    }
`
const Tag = styled.span`
    font-size: 12px;
    line-height: 22px;
`

const Sessions = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    span {
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
    }
`
const StyledBadge = styled(Badge)`
    .MuiBadge-overlapRectangular {
        background-color: ${(props) => props.customcolor} !important;
    }
`


const InsightsNode = ({ data, id }) => {
    let color = constants.edgeColors.green
    if (data.hasOnlyErrors) {
        color = constants.edgeColors.red
    }

    return (
        <div style={customNodeStyles}  id={id}>
            <StyledBadge badgeContent={23} color='primary' customcolor={color}  >
            <StyledCard style={{ position: "relative", width: 90, height: 90, transition: '0.2s', borderRadius: 50 }}>
                <div>
                    <StyledCardContent>
                        <Typography gutterBottom variant="p" component="div" color="#0a2540" style={{ fontWeight: "bold", marginBottom: 0 }}>
                             <img src="/icons/lightbulb.png" />
                        </Typography>
                      
                    </StyledCardContent>
                    
                </div>

            </StyledCard>
            </StyledBadge>
            <StyledButton>
                         Insights
                        </StyledButton>
        </div>
    )
}

InsightsNode.propTypes = {
    data: PropTypes.shape({
        start: PropTypes.bool,
        hasLeadsFrom: PropTypes.bool,
        isHighlighted: PropTypes.bool,
        path: PropTypes.string,
        element: PropTypes.string,
        tag: PropTypes.string,
        type: PropTypes.string,
        entered: PropTypes.number,
        end: PropTypes.bool,
        percentage: PropTypes.string,
    }),
}
InsightsNode.defaultProps = {
    data: {
        start: false,
        isHighlighted: null,
        path: "/",
        element: "N/A",
        tag: "N/A",
        type: "N/A",
        entered: 0,
        // percentage: 0,
      
        end: false,
    },
}

export default InsightsNode


/*




 
*/