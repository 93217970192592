import { useState } from "react";
import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
// components
import { MotionViewport, varFade } from "../../components/animate";
import styledComponent from "styled-components";

// ----------------------------------------------------------------------
const IssueContainer = styledComponent.div`
  margin:auto;
  margin-top:10px;
  display: flex;
  height: 100%;
 
  margin:0px;
  margin-left:20px;
  // background-color: #F4F6F8;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  img{
    // width: 450px;
    max-height: 80px;
    
  }
  @media only screen and (max-width: 768px) {
  margin: 5px;
   
  }
`;
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 456,
  margin: "auto",
  overflow: "hidden",
  paddingBottom: theme.spacing(10),
  padding: 20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: "#F4F7FD",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    maxWidth: "100%",
    paddingBottom: 50,
    paddingTop: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    maxWidth: "100%",
    paddingBottom: 50,
    paddingTop: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    maxWidth: "100%",
    paddingBottom: 50,
    paddingTop: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
}));

// ----------------------------------------------------------------------

export default function HomeNoSessions() {
  return (
    <Container component={MotionViewport} id="seeitlive">
      <ContentStyle>
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <Grid md={3} xs={3} item>
            <IssueContainer>
              <Typography variant="h3">+1M</Typography>
              <Typography variant="body2">analyzed sessions</Typography>
            </IssueContainer>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid md={3} xs={3} item>
            <IssueContainer>
              <Typography variant="h3">+50</Typography>
              <Typography variant="body2">registered organizations</Typography>
            </IssueContainer>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid md={3} xs={3} item>
            <IssueContainer>
              <Typography variant="h3">+200</Typography>
              <Typography variant="body2">generated insights</Typography>
            </IssueContainer>
          </Grid>
         
        </Grid>
      </ContentStyle>
    </Container>
  );
}

//
