import * as Yup from "yup"
import { useState } from "react"
import { useSnackbar } from "notistack"
import { useFormik, Form, FormikProvider } from "formik"
import { useRouter } from 'next/router';
import styledComponents from "styled-components"
// @mui
import { styled } from '@mui/material/styles';
import { Stack, TextField, Alert, Grid, Link, Typography, Button, Card, CardContent, Box } from "@mui/material"
import { CSVLink, CSVDownload } from "react-csv";
//
import { MotivationIllustration } from '../../../assets';
import { LoadingButton } from "@mui/lab"
// hooks
import useAuth from "../../../hooks/useAuth"
import useIsMountedRef from "../../../hooks/useIsMountedRef"
// components
import Iconify from "../../../components/Iconify"
import { IconButtonAnimate } from "../../../components/animate"
import { HOST_API } from "../../../config";
import { InlineWidget, openPopupWidget,useCalendlyEventListener } from "react-calendly";

// ----------------------------------------------------------------------

const StyledButton = styledComponents(Button)`

`

const SuccessContainer = styledComponents.div`
display: flex;
flex-direction:column;
justify-content:center;
align-items:center;`

const SuccessWrapper = styledComponents.div`
display: flex;
flex-direction:row;
justify-content:center;
align-items:center;
`

const Label = styledComponents.span`
    font-weight:bold;
`


const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    // backgroundColor: theme.palette.primary.lighter,
    maxWidth: '100%',
    transition:'1s',
   
    [theme.breakpoints.up('md')]: {
      height: '100%',
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }));

export default function OnboardingForm({partner, type, id, name, setSuccess}) {
    const { createOrganization } = useAuth()
    const isMountedRef = useIsMountedRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [showPassword, setShowPassword] = useState(false)
    const [csvData, setCSVData] = useState([])

    const [isSubmitted, setSubmitted] = useState(false)

    const router = useRouter();
   
    

    const OnboardingSchema = Yup.object().shape({
        companyName: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required("Company name required"),
        companyUrl: Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
        firstName: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required("First name is required"),
        lastName: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required("Last name is required"),
        email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    })


    const submitSuccess = () => {
        setSubmitted(true);
        setSuccess(true)
    }

    const resetSubmit = () => {
        if(formik) {
            formik.resetForm();
        }
        setSubmitted(false)
        setSuccess(false)
    }

    const isLocal = () => router?.query?.local

    const saveLocalCSV = (data) => { 
        const csvData = [
            ['First name', 'Last name', 'Email', 'Company', 'Website', 'Reference', 'Type', 'EntryId'],
            [data.firstName, data.lastName, data.email, data.companyName, data.companyUrl, data.referenceName, data.type,data.entryId]
        ]
        setCSVData(csvData)
    }


    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName:"",
            email: "",
            companyName: "",
            companyUrl: "",
    
        },
        validationSchema: OnboardingSchema,
        onSubmit: async (values, { setErrors, setSubmitting,resetForm }) => {
            const data = {  
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                companyName: values.companyName,
                companyUrl: values.companyUrl,
                referenceName: name,
                type,
                entryId: id,
                
            }
            try {
                const res = await fetch(`${HOST_API}/subscribe_exposition`, {
                    body: JSON.stringify(data),
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                                 
                      },
                      method:"POST"
                })
                const subscribeResponse = await res.json()

                if(isLocal()) {
                    // on local we show the success message anyway and we download on local
                    submitSuccess() 
                    // TODO download local csv
                    saveLocalCSV(data)
                } else {
                    // over the internet we care about the result
                    if(subscribeResponse.message === "ok") {
                        submitSuccess()
                    } else {
                        setSubmitting(false)
                        setErrors({ afterSubmit: "Error registering your interest" })
                    }
                }
               
               
                if (isMountedRef.current) {
                    setSubmitting(false)
                }
            } catch (error) {
                resetForm()
                if(isLocal()) {
                    // on local we always show success and save csv on local
                    submitSuccess()
                    saveLocalCSV(data)
                } else {
                if (isMountedRef.current) {
                    setSubmitting(false)
                    setErrors({ afterSubmit: "Error registering your interest" })
                }
            }
            }
        },
    })

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

    const renderSuccess = () => {
        return  (<RootStyle>
           <SuccessContainer>
               <SuccessWrapper>
        <CardContent
          
          sx={{
            
            color: 'grey.800',
            p: { md: 0 },
            pl: { md: 5 },
            p: 3
          }}
        >
          <Typography gutterBottom variant="h4" onClick={() => resetSubmit()}>
            Welcome to Flowpoint, {formik?.values?.firstName}
            <br /> 
          </Typography>
  
          <Typography variant="body2" sx={{  maxWidth: 480, mx: 'auto' }}>
          We will get back to you shortly via email with the next steps
          </Typography>

        </CardContent>
        <Box sx={{ml: 2, mr: 2}}>
        <Iconify icon="clarity:success-standard-line" width={70}  height={70} color={"#5BE584"} />
        </Box>
        </SuccessWrapper>
        <StyledButton variant='contained' size='large' onClick={() => {
            const {values} = formik
             const prefill = {
                "name":`${values?.firstName || ''} ${values?.lastName || ''}`,
                "email":values?.email || '',
                "customAnswers":{
                    "a1":values?.companyName || '',
                    "a2":values?.companyUrl || '',
                    "a3":`I registered on Flowpoint at ${partner?.title} and I will get ${partner?.discountTitle}`
                }
              

            }
            openPopupWidget({ url: "https://calendly.com/flowpoint-ai/demo", prefill });
        }}>
            Schedule a demo with us
        </StyledButton>
        </SuccessContainer>
        {/* <InlineWidget url="https://calendly.com/flowpoint-ai/onboarding" /> */}
        {csvData.length ? (<CSVDownload data={csvData}  />) : <div/>}
      </RootStyle>)
    }

    return isSubmitted ? renderSuccess() : (
        <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    {errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}
                    <Label>General details</Label>
                    <Grid    
                        container
                        spacing={1}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        autoComplete='firstName'
                        type='text'
                        label='First Name'
                        {...getFieldProps("firstName")}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                    />
                        </Grid>
                        <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        autoComplete='lastName'
                        type='text'
                        label='Last Name'
                        {...getFieldProps("lastName")}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                    />
                        </Grid>
                
                    </Grid>
                    <Grid  container
                        spacing={1}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        autoComplete='email'
                        type='text'
                        label='Email address'
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                        </Grid>
                        </Grid>
                   
                    <br />
                    <Label>Organization details</Label>
                    <TextField
                        fullWidth
                        autoComplete='companyName'
                        type='text'
                        label='Organization name'
                        {...getFieldProps("companyName")}
                        error={Boolean(touched.companyName && errors.companyName)}
                        helperText={touched.companyName && errors.companyName}
                    />
                    <TextField
                        fullWidth
                        autoComplete='companyUrl'
                        type='text'
                        label='Website url'
                        {...getFieldProps("companyUrl")}
                        error={Boolean(touched.companyUrl && errors.companyUrl)}
                        helperText={touched.companyUrl && errors.companyUrl}
                    />
                 
                    <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
                        Register
                    </LoadingButton>
                   
                    {/* <Stack direction='row' alignItems='center' justifyContent='center' sx={{ my: 2, width: '100%' }}>
                        <Link variant='subtitle2' target="_blank" href={`https://dashboard.flowpoint.ai/auth/register?ref=${name}&type=${type}&id=${id}`}>
                            Register now
                        </Link>
                </Stack> */}
                </Stack>
            </Form>
        </FormikProvider>
    )
}
