import React, { useState, useEffect, useRef } from "react"
import { Grid, Typography, Container, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { MotionViewport, varFade } from "../../components/animate"
import { AnimatePresence, m } from "framer-motion"
import Iconify from "../../components/Iconify"
import MenuPopover from "src/components/MenuPopover"
import Image from "next/image"
import ChatWindow from "../@dashboard/chat/ChatWindow"

const RootStyle = styled("div")(({ theme }) => ({
    padding: theme.spacing(4, 0),
    // backgroundColor: theme.palette.grey[900],
    // backgroundColor: "#F3F6FC",
    // background: "#00140C",
    backgroundImage: "linear-gradient(to right bottom, #00150D, #091E16, #1E372B)",
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(15, 0),
    },
    "& span": {
        borderRadius: "12px",
    },
}))
const ContentStyle = styled("div")(({ theme }) => ({
    width: "100%",
    textAlign: "center",
    // marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        marginBottom: 0,
    },
    [theme.breakpoints.up("xs")]: {
        textAlign: "left",
        marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
        textAlign: "left",
        marginTop: 0,
    },
}))

const StyledButton = styled(IconButton)(({ theme, pulsing }) => ({
    position: "absolute",
    bottom: 35,
    left: 35,
    boxShadow: pulsing ? "0 0 0 0 rgba(65, 197, 7, 0.7)" : "none",
    animation: pulsing ? "pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)" : "none",

    backgroundColor: "rgba(65, 197, 7)",

    color: "#ffffff",
    transition: "all 300ms ease-in-out",

    "&:hover": {
        animation: "none",
        background: "#283D7A",
    },
    "@keyframes pulsing": {
        to: {
            boxShadow: "0 0 0 30px rgba(65, 197, 7, 0)",
        },
    },
}))

const ImageScroller = ({ images, texts, description, conversation }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const [isPulsing, setIsPulsing] = useState(true)
    const refs = useRef([])
    const [containerPosition, setContainerPosition] = useState("sticky")
    const imageVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5 } },
    }
    useEffect(() => {
        refs.current = refs.current.slice(0, texts.length)

        const handleScroll = () => {
            const scrollPosition = window.scrollY
            const containerTop = refs.current[0].getBoundingClientRect().top
            const containerBottom = refs.current[refs.current.length - 1].getBoundingClientRect().bottom

            if (scrollPosition + 100 >= containerTop && scrollPosition + window.innerHeight <= containerBottom - 100) {
                setContainerPosition("sticky")
            } else {
                setContainerPosition("absolute")
            }

            for (let i = 0; i < refs.current.length; i++) {
                const { top } = refs.current[i].getBoundingClientRect()

                if (top < window.innerHeight / 2) {
                    setActiveIndex(i)
                }
            }
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [texts.length])

    const anchorRef = useRef(null)

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
        setIsPulsing(false)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <RootStyle>
            <Container maxWidth='xl'>
                <div
                    ref={(el) => (refs.current[0] = el)}
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        height: `${images.length * 500}px`,
                        flexDirection: "row",
                        color: "white",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                    }}
                >
                    <Grid container spacing={6} direction='row' justifyContent='space-between'>
                        <Grid item xs={12} md={4} style={{ textAlign: "left", alignItems: "center" }}>
                            <ContentStyle style={{ textAlign: "left" }}>
                                <div>
                                    {texts.map((text, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                height: "500px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                            ref={(el) => (refs.current[index] = el)}
                                        >
                                            <m.div>
                                                <Typography
                                                    component='div'
                                                    variant='overline'
                                                    sx={{
                                                        mb: 2,
                                                        color: "white",
                                                        textAlign: "left",
                                                        justifyItems: "flex-start",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        display: "flex",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <Iconify
                                                        icon={text.icon}
                                                        sx={{
                                                            width: 40,
                                                            height: 40,
                                                            color: "#122920",
                                                            marginRight: "10px",
                                                            backgroundColor: "#EAFF00",

                                                            padding: "5px",
                                                            borderRadius: "8px",
                                                        }}
                                                    />
                                                    {text.iconTitle}
                                                </Typography>
                                            </m.div>

                                            <m.div>
                                                <Typography variant='h2' sx={{ mb: 3 }}>
                                                    {text.title}
                                                </Typography>
                                            </m.div>

                                            <m.div>
                                                <Typography
                                                    sx={{
                                                        mb: 1,
                                                        color: "white",
                                                    }}
                                                >
                                                    {text.description}
                                                </Typography>
                                            </m.div>
                                        </div>
                                    ))}
                                </div>
                            </ContentStyle>
                        </Grid>

                        <Grid item xs={12} md={8} style={{ textAlign: "center", alignItems: "center" }}>
                            {/* <AnimatePresence exitBeforeEnter> */}
                            <div
                                style={{
                                    position: "sticky",
                                    top: "20%",
                                    overflow: "hidden", // Add this to hide the overflow of the blurred image
                                    borderRadius: "12px",
                                    // Add padding if needed
                                    // padding: "1em 0",
                                    padding: "20px",
                                    borderRadius: "24px",
                                    boxShadow: "0 4px 4px rgba(0,0,0,.25)",
                                    background: "linear-gradient(179.86deg,hsla(0,0%,100%,.15) .12%,hsla(0,0%,100%,.063) 99.88%)",
                                    boxShadow: "0 4px 4px rgba(0,0,0,.25)",
                                }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        // background: "#ffffff70",
                                        borderRadius: "12px",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        filter: "blur(4px)", // Apply the blur effect
                                        //backgroundImage: `url(${images[activeIndex]})`, // Set the background image

                                        backgroundSize: "cover", // Set the background size
                                        // Adjust the zIndex if needed
                                        zIndex: -1,
                                    }}
                                ></div>
                                <Image
                                    src={images[activeIndex]}
                                    priority={true}
                                    // key={images[activeIndex]}
                                    alt={`Image ${activeIndex}`}
                                    width={441} // Width you desire to display the image with
                                    height={268.9} //
                                    layout='responsive'
                                    sx={{
                                        borderRadius: "12px",
                                        boxShadow: "0 4px 4px rgba(0,0,0,.25)",
                                        transition: "opacity 0.1s ease-in-out",
                                    }}
                                />
                                {/* <StyledButton size='medium' ref={anchorRef} onClick={handleOpen} pulsing={isPulsing}>
                                    <Iconify icon={"uil:robot"} style={{ height: "25px", width: "25px", marginBottom: "2px" }} />
                                </StyledButton> */}

                                <MenuPopover
                                    open={open}
                                    arrow='bottom-center'
                                    onClose={handleClose}
                                    anchorEl={anchorRef.current}
                                    sx={{ width: "90%", maxWidth: "700px", height: "250px", p: 3 }}
                                >
                                    <ChatWindow conversation={conversation?.[activeIndex]} />
                                </MenuPopover>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </RootStyle>
    )
}

export default ImageScroller
