import { useState } from "react"
import { m } from "framer-motion"
import { useRouter } from "next/router"
// @mui
import { styled } from "@mui/material/styles"
import { Button, Box, Container, Typography, Grid } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import { openPopupWidget } from "react-calendly"
import Iconify from "../../components/Iconify"
import { DASHBOARD_URL } from "../../config"

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
    // maxWidth: 456,
    margin: "auto",
    overflow: "hidden",
    paddingBottom: theme.spacing(10),
    padding: 30,
    justifyContent: "space-around",
    borderRadius: "12px",
    border: "1px solid #D3D9DA",
    // backgroundImage: "linear-gradient(113.61380240615335deg, #FFF 5.273809523809524%,#EFF8FD 97.17857142857142%)",
    //  boxShadow: "rgba(145, 158, 171, 0.16) -40px 40px 80px",
    [theme.breakpoints.up("md")]: {
        display: "flex",
        maxWidth: "100%",
        paddingBottom: 50,
        paddingTop: 50,
        alignItems: "center",
    },
}))

// ----------------------------------------------------------------------

export default function HomeAdvertisement() {
    const { pathname } = useRouter()

    const router = useRouter()
    const name = router?.query?.name
    const index = router?.query?.index

    const onClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "TalkToSalesButton")
        }
    }

    const getDashboardUrl = () => {
        const isEventUrl = router.pathname?.startsWith("/events")
        const isPartnerUrl = router.pathname?.startsWith("/partners")
        const isCustomDashboard = isEventUrl || isPartnerUrl
        return isCustomDashboard
            ? `${DASHBOARD_URL}/auth/register?ref=${name}&type=${isEventUrl ? "events" : "partners"}&id=${index}`
            : DASHBOARD_URL
    }

    return (
        <Container
            sx={{
                display: "flex",
                justifyContent: "center",
                mt: { xs: 10, md: 1 },
            }}
        >
            <ContentStyle>
                <Grid container spacing={4} justifyContent='flex-start' alignItems='flex-start' sx={{ marginLeft: { md: "30px" } }}>
                    <Grid item xs={12} md={7}>
                        <Typography variant='h2' component='h2' sx={{ fontFamily: "Poppins, sans-serif", color: "#092540" }} gutterBottom>
                            The workspace
                        </Typography>
                        <Typography
                            variant='h2'
                            component='h2'
                            sx={{
                                fontFamily: "Poppins, sans-serif",
                            }}
                            gutterBottom
                        >
                            for your website
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: "#00140C",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                lineHeight: "1.778rem",
                                fontSize: { xs: "15px", md: "1rem" },
                            }}
                            gutterBottom
                        >
                            Our team is committed to offering assistance at each step of the process to ensure your success:
                        </Typography>
                        <ul style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                            <Typography
                                component='li'
                                variant='body1'
                                sx={{
                                    color: "#00140C",
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 500,
                                    lineHeight: "1.778rem",
                                    fontSize: { xs: "15px", md: "1rem" },
                                    "&::marker": {
                                        color: "#122920",
                                    },
                                }}
                                gutterBottom
                            >
                                Installation support
                            </Typography>
                            <Typography
                                component='li'
                                variant='body1'
                                sx={{
                                    color: "#00140C",
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 500,
                                    lineHeight: "1.778rem",
                                    fontSize: { xs: "15px", md: "1rem" },
                                    "&::marker": {
                                        color: "#122920",
                                    },
                                }}
                                gutterBottom
                            >
                                Set up your website flows
                            </Typography>
                            <Typography
                                component='li'
                                variant='body1'
                                sx={{
                                    color: "#00140C",
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 500,
                                    lineHeight: "1.778rem",
                                    fontSize: { xs: "15px", md: "1rem" },
                                    "&::marker": {
                                        color: "#122920",
                                    },
                                }}
                                gutterBottom
                            >
                                Analyse and monitor your data
                            </Typography>
                        </ul>
                        <m.div variants={varFade().inDown}>
                            <Button
                                size='medium'
                                variant='outlined'
                                endIcon={<Iconify icon={"material-symbols:calendar-month-rounded"} width={20} height={20} />}
                                target='_blank'
                                rel='noopener'
                                onClick={() => onClick()}
                                sx={{
                                    whiteSpace: "nowrap",
                                    borderRadius: "40px",
                                    // borderColor: "#122920",
                                    mt: 3,
                                    boxShadow: "none",
                                    // mr: 1,
                                    width: "180px",
                                    "&:hover": {
                                        bgcolor: "#122920",
                                        color: "white",
                                        borderColor: "#122920",
                                    },
                                }}
                            >
                                Request Demo
                            </Button>
                        </m.div>
                    </Grid>
                </Grid>
            </ContentStyle>
        </Container>
    )
}

//
