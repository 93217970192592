// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function PeopleIcon({ ...other }) {
    const theme = useTheme();
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;
    const PRIMARY_DARKER = theme.palette.primary.darker;

    return (
        <Box {...other}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.16" cx="12" cy="12" r="12" fill="#54D62C" />
                <path d="M11.6875 16.375C11.6875 16.375 10.875 16.375 10.875 15.5625C10.875 14.75 11.6875 12.3125 14.9375 12.3125C18.1875 12.3125 19 14.75 19 15.5625C19 16.375 18.1875 16.375 18.1875 16.375H11.6875ZM14.9375 11.5C15.584 11.5 16.204 11.2432 16.6611 10.7861C17.1182 10.329 17.375 9.70897 17.375 9.0625C17.375 8.41603 17.1182 7.79605 16.6611 7.33893C16.204 6.88181 15.584 6.625 14.9375 6.625C14.291 6.625 13.671 6.88181 13.2139 7.33893C12.7568 7.79605 12.5 8.41603 12.5 9.0625C12.5 9.70897 12.7568 10.329 13.2139 10.7861C13.671 11.2432 14.291 11.5 14.9375 11.5V11.5Z" fill="#40C700" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.238 16.375C10.1176 16.1214 10.0575 15.8433 10.0625 15.5625C10.0625 14.4616 10.615 13.3281 11.6355 12.54C11.1261 12.3831 10.5955 12.3063 10.0625 12.3125C6.8125 12.3125 6 14.75 6 15.5625C6 16.375 6.8125 16.375 6.8125 16.375H10.238Z" fill="#40C700" />
                <path d="M9.65625 11.5C10.195 11.5 10.7116 11.286 11.0926 10.9051C11.4735 10.5241 11.6875 10.0075 11.6875 9.46875C11.6875 8.93003 11.4735 8.41337 11.0926 8.03244C10.7116 7.65151 10.195 7.4375 9.65625 7.4375C9.11753 7.4375 8.60087 7.65151 8.21994 8.03244C7.83901 8.41337 7.625 8.93003 7.625 9.46875C7.625 10.0075 7.83901 10.5241 8.21994 10.9051C8.60087 11.286 9.11753 11.5 9.65625 11.5V11.5Z" fill="#40C700" />
            </svg>

        </Box>
    );
}
