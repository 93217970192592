import { useState, useEffect } from "react";
// @mui
import { Container, CircularProgress, Button, Chip, Card } from "@mui/material";
import ReactFlow, { Controls, Background } from "react-flow-renderer";
import { m, motion, useAnimation } from "framer-motion";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { isEmpty } from "lodash";
// hooks
import useSettings from "../../hooks/useSettings";
import useAuth from "../../hooks/useAuth";
// components

import IssueEdge from "./Edges/IssueEdge";
import WarningEdge from "./Edges/WarningEdge";
import BounceWarningEdge from "./Edges/BounceWarningEdge";
import DirectEdge from "./Edges/DirectEdge";
import TotalEdge from "./Edges/TotalEdge";

import TechnicalIssueNode from "./Nodes/TechnicalIssueNode";
import EmotionNode from "./Nodes/EmotionNode";
import BehaviouralIssueNode from "./Nodes/BehaviourIssueNode";
import FlowNode from "./Nodes/FlowNode";
import OtherFlowNode from "./Nodes/OtherFlowNode";
import DummyNode from "./Nodes/DummyNode";
import AggregatedNode from "./Nodes/AggregatedNode";
import InsightsNode from "./Nodes/InsightsNode";
import TechnicalIssuePopover from "./Popovers/TechnicalIssuePopover";
import InsightsPopover from "./Popovers/InsightsPopover";
import FlowNodePopover from "./Popovers/FlowNodePopover";

import prepareNodes from "../../utils/flowHelpers/prepareNodes";
import highlightFlow from "../../utils/flowHelpers/highlightFlow";
import sortIssuesInFlow from "../../utils/flowHelpers/sortIssuesInFlow";
import constants from "../../utils/constants";

import { getFlows } from "../../redux/slices/flows";

import AnimatedCursor from "../../utils/flowHelpers/AnimatedCursor";

import useOffSetTop from "../../hooks/useOffSetTop";

import { HEADER } from "../../config";
// ----------------------------------------------------------------------

const ClickText = styled.div`
  position: absolute;
  left: calc(50% - 130px);
  top: -40px;
  z-index: 1090;
  height: 180px;
  width: 260px;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: scale(${(props) => (props.shouldHide ? 0 : 1)});
  transition: 0.5s;
  img {
    position: absolute;
    min-width: 260px;
    max-width: 260px;
    height: auto;
  }
`;

const ScrollText = styled.div`
  position: absolute;
  left: calc(50% - 90px);
  top: -40px;
  z-index: 1090;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: scale(${(props) => (props.shouldHide ? 0 : 1)});
  transition: 0.5s;
  img {
    position: absolute;
    min-width: 180px;
    max-width: 180px;
    height: auto;
  }
`;

const DragText = styled.div`
  position: absolute;
  left: calc(50% - 140px);
  top: -40px;
  z-index: 1090;
  height: 180px;
  width: 280px;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: scale(${(props) => (props.shouldHide ? 0 : 1)});
  transition: 0.5s;
  img {
    position: absolute;
    min-width: 280px;
    max-width: 280px;
    height: auto;
  }
`;

const TryItButton = styled.div`
position:absolute;
left:calc(50% - 75px);
top:-20px;
z-index:1090;
height: 120px;
width: 120px;
border-radius: 50%;

display:flex;
justify-content:center;
flex-direction:column;
align-items:center;

img{
    top:-30px;
    left:-160px; 
    position:absolute;
    min-width: 140px;
    height: auto;
    @media (max-width: 768px) {
        left:-70px; 
        top:-40px;
      },
    
}
button{
    width: 150px;
}
`;

const BlurryOverlay = styled.div`
  position: absolute;
  pointer-events: ${(props) => (props.isDemoStarted ? "none" : "initial")};
  z-index: 1070;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: ${(props) =>
    props.isDemoStarted ? "blur(0px)" : "blur(4px)"};
  border-radius: 16px;
  transition: 1s;
`;

const TitleSection = styled.div`
  position: absolute !important;
  top: 10px;
  left: 10px;
  z-index:1000;
`;
// const TitleSection2 = styled.div`
//   position: absolute !important;
//   top: 50px;
//   left: 10px;
// `;
const StyledSection = styled(Card)`
  position: fixed !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 180px;
  width: 40px;
  bottom: calc(50% - 90px);
  z-index: 1090 !important;
  right: 0;
  text-align: center;
  align-items: center;
  border-radius: 0px !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background: rgb(255, 72, 66) !important;
  transition: 0.3s;
  :hover {
    width: 45px;
  }

  img {
    position: fixed;
    right: 50px;
    bottom: calc(50%);
    width: 130px;
    height: auto;
    @media (max-width: 768px) {
      display:none;
    }

  }
  button {
    background: rgb(255, 72, 66);
    height: 40px;
    width: 180px;
    margin-top: 10px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    margin-bottom: 10px;
    border-radius: 0;
    transform: rotate(-90deg) !important;
    transition: 0.3s;
    :hover {
      background: rgba(255, 72, 66, 0.9);

      height: 45px;
    }
  }
  span {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
//#5BE584;
const StyledChip = styled(Chip)`
  color: white !important;
  border: 1px solid #0a2540 !important;
  background: #0a2540 !important;
  font-size: 17px !important;
  :hover {
    border: 1px solid #0a2540 !important;
    background: #0a2540 !important;
    color: white;
  }
`;

const FullContainer = styled.div`
  height: 700px;

  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`;
const StyledReactFlow = styled(ReactFlow)`
  height: 100%;
  width: 100%;
  background: rgba(145, 158, 171, 0.08);
  border-radius: 16px;
`;
const StyledContainer = styled(Container)`
  height: 700px;
  margin-top: 50px;
  width: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

let elemToClick = null;
let simulatedDivClick = null;

export default function Flow(props) {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const flowId = props.flowId;
  const { user, organization } = useAuth();
  const organizations = user?.organizations;
  let clientId = organizations ? Object.keys(organizations)[0] : "clientId";

  const { isLoading, flows } = useSelector((state) => state.flows);
  const [isDemoStarted, setDemoStarted] = useState(false);
  const [shouldTutorialStart, setTutorialShouldStart] = useState(false);
  const [wasTutorialStarted, setTutorialStarted] = useState(false);
  const [elements, setElements] = useState([]);
  const [elementsObj, setElementsObj] = useState({});
  const [test, setTest] = useState("not yet");
  const [currentFlow, setCurrentFlow] = useState({});
  const [aggregatesObj, setAggregates] = useState({});
  const [defaultSortedFlow, setDefaultSortedFlow] = useState([]);
  const [canPaneMove, setPaneMovement] = useState(true);
  const [flowInstance, setFlowInstance] = useState(null);


  const [isExpanded, setExpanded] = useState({
    [constants.nodeTypes.technicalIssueNode]: false,
    [constants.nodeTypes.behaviouralIssueNode]: false,
    [constants.nodeTypes.otherFlowNode]: false,
    [constants.nodeTypes.insightsNode]:false,
  });
  const [click, setClicked] = useState(null);
  const [isDragging, setDraging] = useState(false);
  const [{ isPanelDragged, flowTransform }, setPanelDragging] = useState(false);
  const [wasPanelDragged, setPanelDragged] = useState(false);
  const [wasPanelScrolled, setPanelScrolled] = useState(false);
  const [wasNodeClicked, setNodeClicked] = useState(false);
  const [clickedElementData, setClickedElementData] = useState({});
  const [stepIndex, setStepIndex] = useState(0)

  const [st, setState] = useState(0);
  const forceUpdate = () => setState(st + 1);

  useEffect(() => {
    let apiFetch = true;
    if (clientId) {
      const func = async () => {
        const result = await dispatch(getFlows(clientId));
        if (apiFetch) {
          if (result && result.length) {
            const flow = result.find((item) => item.id === flowId) || {};

            if (!isEmpty(flow)) {
              setCurrentFlow(flow);

              const sortedFlow = sortIssuesInFlow(flow);

              setDefaultSortedFlow(sortedFlow);

              let aggregatesObj = {};
              if (sortedFlow?.flowPoints) {
                const flowPointsArray = Object.keys(sortedFlow.flowPoints);
                for (let i = 0; i < flowPointsArray.length; i += 1) {
                  aggregatesObj[`node_${flowPointsArray[i]}`] =
                    constants.DISPLAY_ISSUE_NO_DEFAULT;
                }
              }

              setAggregates(aggregatesObj);
              const { elements: elementsArray, elementsObj: elementsObjCopy } =
                prepareNodes(sortedFlow, aggregatesObj);
              setElements(elementsArray);
              setElementsObj(elementsObjCopy);
            }
          }
        }
      };

      func().catch(console.error);
      return () => {
        // cancel the subscription
        apiFetch = false;
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (flows && flows.length) {
      const flow = flows.find((item) => item.id === flowId) || {};

      if (!isEmpty(flow)) {
        setCurrentFlow(flow);

        const sortedFlow = sortIssuesInFlow(flow);

        setDefaultSortedFlow(sortedFlow);

        let aggregatesObj = {};
        if (sortedFlow?.flowPoints) {
          const flowPointsArray = Object.keys(sortedFlow.flowPoints);
          for (let i = 0; i < flowPointsArray.length; i += 1) {
            aggregatesObj[`node_${flowPointsArray[i]}`] =
              constants.DISPLAY_ISSUE_NO_DEFAULT;
          }
        }

        setAggregates(aggregatesObj);
        const { elements: elementsArray, elementsObj: elementsObjCopy } =
          prepareNodes(sortedFlow, aggregatesObj);
        
        setElements(elementsArray);
        setElementsObj(elementsObjCopy);
      }
    }
  }, [flows]);

  const onNodeDragStart = () => {
    setDraging(true);
  };
  const onNodeDragStop = () => {
    setDraging(false);
  };
  const onBackgroundStartDragging = (flowTransform) => {
    if (!isPanelDragged) {
      setPanelDragging({ isPanelDragged: true, flowTransform });
    }

    if (
      flowTransform.x !== 20 &&
      flowTransform.y !== 100 &&
      flowTransform.zoom === 0.5 &&
      !wasPanelDragged
    ) {
      setPanelDragged(true);
    }
    if (flowTransform.zoom !== 0.5 && !wasPanelScrolled) {
      setPanelScrolled(true);
    }
  };
  const onBackgroundEndDragging = (flowTransform) => {
    setPanelDragging({ isPanelDragged: false, flowTransform });
  };

  const applyHighlight = (data) => {
    const { highlightedElementsObj } = highlightFlow(
      elements,
      elementsObj,
      data
    );
    const elem = [...elements];
    const elemObj = { ...elementsObj };
    for (let i = 0; i < elem.length; i += 1) {
      if (highlightedElementsObj[elem[i].id] !== undefined) {
        // element should be highlighted
        elem[i].data.isHighlighted = true;
      } else {
        elem[i].data.isHighlighted = false;
      }
      // update the helper object as well
      elemObj[elem[i].id] = elem[i];
    }
    setNodeClicked(true);
    setElements(elem);
    setElementsObj(elemObj);
  };

  const shouldPopoverOpen = () =>
    isExpanded[constants.nodeTypes.technicalIssueNode] ||
    isExpanded[constants.nodeTypes.behaviouralIssueNode];

  const shouldInsightsPopoverOpen = () =>
    isExpanded[constants.nodeTypes.insightsNode]

  const shouldFlowNodePopoverOpen = () =>
    isExpanded[constants.nodeTypes.otherFlowNode];

  const onElementClick = (evt, data) => {
   


    evt.stopPropagation();
    setClicked(evt.target);
    if (data && data.type === constants.nodeTypes.aggregatedNode) {
      // we clicked on an aggregation node so here we apply logic for expanding or collapsing aggregated nodes
      const newAggregatedObj = { ...aggregatesObj };
      const dummyNodeId = data.data.parents[0];
      const flowPointSourceId = elementsObj[dummyNodeId].data.parents[0];
      if (data.data.issues > 0) {
        newAggregatedObj[flowPointSourceId] =
          newAggregatedObj[flowPointSourceId] +
          constants.AGGREGATED_ISSUES_EXPAND_FACTOR;
      } else {
        newAggregatedObj[flowPointSourceId] =
          constants.DISPLAY_ISSUE_NO_DEFAULT;
      }

      setAggregates(newAggregatedObj);

      const { elements: elementsArray, elementsObj: elementsObjCopy } =
        prepareNodes(defaultSortedFlow, newAggregatedObj);

      setElements(elementsArray);
      setElementsObj(elementsObjCopy);
    } else if (data && data.target && data.source) {
      applyHighlight(data);
      // this is an edge so we have to apply logic for edges
    } else {
      // we just clicked on a node so we have to apply logic for nodes

      if (
        data?.type &&
        isExpanded[constants.nodeTypes[data.type]] !== undefined
      ) {
        
        setClickedElementData(data);

        setExpanded({
          ...isExpanded,
          [constants.nodeTypes[data.type]]:
            !isExpanded[constants.nodeTypes[data.type]],
        });
      } else {
        setExpanded({
          [constants.nodeTypes.technicalIssueNode]: false,
          [constants.nodeTypes.behaviouralIssueNode]: false,
          [constants.nodeTypes.otherFlowNode]: false,
          [constants.nodeTypes.insightsNode]:false,
        });
      }
      if (data && !isExpanded[constants.nodeTypes[data.type]]) {
        applyHighlight(data);
      }
    }
  };

  function triggerEvent( elem, event ) {
    var clickEvent = new Event( event ); // Create the event.
    elem.dispatchEvent( clickEvent );    // Dispatch the event.
  }

  const simulateClick = (e) => {
    onElementClick(e,elements[0])
  }
  const closePopover = () => {
    if(elemToClick) {
      // closing the popover in the tutorial
      elemToClick.addEventListener('click', simulateClick);
      elemToClick.dispatchEvent(simulatedDivClick);
      elemToClick.removeEventListener('click', simulateClick)
      elemToClick = null
      simulatedDivClick = null;  
    }
  }
  const openPopover = () => {
    if(!elemToClick) {
    elemToClick =  document.getElementById("insightsNode")

    

    // triggerEvent( elemToClick, 'click' );

    elemToClick.addEventListener('click',simulateClick);

    simulatedDivClick = document.createEvent('MouseEvents');

    simulatedDivClick.initEvent(
      'click', /* Event type */
      true, /* bubbles */
      true, /* cancelable */
      document.defaultView, /* view */
      0, /* detail */
      0, /* screenx */
      0, /* screeny */
      0, /* clientx */
      0, /* clienty */
      false, /* ctrlKey */
      false, /* altKey */
      false, /* shiftKey */
      0, /* metaKey */
      null, /* button */
      null /* relatedTarget */
    );
      elemToClick.dispatchEvent(simulatedDivClick);
    } else {
      elemToClick.dispatchEvent(simulatedDivClick);
    }
  }

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = (index + (action === ACTIONS.PREV ? -1 : 1))
      if(stepIndex === 3) {
   
        openPopover() 
         setTimeout(() => {
          setStepIndex(stepIndex);
        },500)
      } else {
     
        closePopover()
        setStepIndex(stepIndex);
      }
   
    }

    if (action === "close") {
      setDemoStarted(false);
      setTutorialShouldStart(false);
   
    }
    if (action === "start") {
      forceUpdate();
    }
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // if the tour ended we can unlock the pane and start playing with it
      // setPaneMovement(true)
      setDemoStarted(false);
      closePopover();
      setTutorialShouldStart(false);
      setStepIndex(0);
      // elemToClick.removeEventListener('click')
    }
  };

  const onLoad = (reactFlowInstance) => {
    setFlowInstance(reactFlowInstance);
  };

  const scrollTo = (id, yOffset = 200) => {
    setTimeout(() => {
      // document
      //   .querySelector("#trydemo")
      //   .scrollIntoView({ behavior: "smooth" })

      const element = document.querySelector(id);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top:  y, behavior: "smooth" });
    }, 300);
  };
  

  const state = {
    steps: [
      {
        target: `#${
          elements[4]?.id ? elements[4]?.id.replace("/", "") : elements[4]?.id
        }`,
        content:
          "Where the users are coming from",
        disableBeacon: true,
      },

      {
        target: `#${elements[1]?.id}`,
        content:
          "This is a Flowpoint",
      },
      {
        target: `#${elements[31]?.id}`,
        content:
          "Issue node that shows you that some of your users are switching tabs and get distracted.",
      },
     // insightsNode
       {
        target: `#popoverInsightsNode`,
        content:
          "Automatically receive a list of actionable insights that will fix your most impactful issues.",
      },
      // {
      //   target: `#popover${elements[30]?.id}`,
      //   content:
      //     "Automatically receive a list of actionable insights that will fix your most impactful issues.",
      // },
      {
        target: `#${elements[16]?.id}`,
        content:
          "Emotion node that shows you how your users are feeling at this point in their journey.",
      },
      {
        target: `#${
          elements[18]?.id
            ? elements[18]?.id.replace("/", "")
            : elements[18]?.id
        }`,
        content:
          "Where the users are going after abandoning the flow",
      },
    ],
    stepIndex:0,
  };
  return (
    <>
      {!wasTutorialStarted && <BlurryOverlay isDemoStarted={isDemoStarted} />}

     {typeof window !== "undefined" && ( <AnimatedCursor
        isDragging={isPanelDragged}
        flowTransform={flowTransform}
        innerSize={8}
        outerSize={20}
        color="10, 37, 64"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
      />)}
      <TechnicalIssuePopover
        open={shouldPopoverOpen()}
        handleClose={onElementClick}
        organization={organization}
        click={click}
        clickedElementData={clickedElementData}
      />
      <InsightsPopover
        open={shouldInsightsPopoverOpen()}
        handleClose={onElementClick}
        organization={organization}
        click={click}
        clickedElementData={clickedElementData}
      />

      <FlowNodePopover
        open={shouldFlowNodePopoverOpen()}
        handleClose={onElementClick}
        click={click}
        organization={organization}
        clickedElementData={clickedElementData}
      />
      <StyledContainer maxWidth={themeStretch ? false : "xxl"} id="playfield">
        {!wasTutorialStarted && !isDemoStarted && (
          <TryItButton>
            <img src="/images/tryitt.webp" />
            <Button
              variant="contained"
              onClick={() => {
                setDemoStarted(true);
                scrollTo("#playfield", -120);
              }}
            >
              Enter playground
            </Button>
          </TryItButton>
        )}
        <TitleSection>
           <StyledChip label="Example "/>
        </TitleSection>
      

        {(wasTutorialStarted || isDemoStarted) && (
          <DragText shouldHide={wasPanelDragged}>
            <img src="/images/drag.webp" />
          </DragText>
        )}

        {(wasTutorialStarted || isDemoStarted) && wasPanelDragged && (
          <ScrollText shouldHide={wasPanelScrolled}>
            <img src="/images/scroll.webp" />
          </ScrollText>
        )}

        {(wasTutorialStarted || isDemoStarted) &&
          wasPanelDragged &&
          wasPanelScrolled && (
            <ClickText shouldHide={wasNodeClicked}>
              <img src="/images/nodeClick.webp" />
            </ClickText>
          )}
        {elements.length ? (
          <FullContainer>
            <StyledSection>
              {/* <StyledChip label="Start demo" /> */}
              {!wasTutorialStarted && <img src="/images/tryit3.png" />}
              <Button
                variant="contained"
                onClick={() => {

                
                  flowInstance.fitView();
                  flowInstance.setTransform({ x: 20, y: 100, zoom: 0.5 });
                  setDemoStarted(true);
                  setTutorialShouldStart(true);
                  scrollTo("#howitworks");
                  setTutorialStarted(true);
                }}
              >
                How it works
              </Button>
              {/* <span onClick={() => { setDemoStarted(true); setTutorialShouldStart(false); setPaneMovement(true) }}>Skip tutorial</span> */}
            </StyledSection>

            <StyledReactFlow
              zoomOnScroll={canPaneMove}
              paneMoveable={canPaneMove}
              // onPaneScroll={onPaneScroll}
              onLoad={onLoad}
              id={flowId}
              elements={elements}
              arrowHeadColor="grey"
              onNodeDragStart={onNodeDragStart}
              onNodeDragStop={onNodeDragStop}
              onMove={onBackgroundStartDragging}
              onMoveEnd={onBackgroundEndDragging}
              onPaneClick={() => applyHighlight(null)}
              onElementClick={onElementClick}
              defaultPosition={ [20, 100] }
              defaultZoom={-10}
              edgeTypes={{
                issueEdge: IssueEdge,
                warningEdge: WarningEdge,
                bounceWarningEdge: BounceWarningEdge,
                directEdge: DirectEdge,
                totalEdge: TotalEdge,
              }}
              nodeTypes={{
                emotionNode: EmotionNode,
                technicalIssueNode: TechnicalIssueNode,
                behaviouralIssueNode: BehaviouralIssueNode,
                flowNode: FlowNode,
                otherFlowNode: OtherFlowNode,
                dummyNode: DummyNode,
                aggregatedNode: AggregatedNode,
                insightsNode: InsightsNode
              }}
            >
              <Controls />
              <Background />
              <Joyride
                stepIndex={stepIndex}
                run={isDemoStarted && shouldTutorialStart}
                callback={handleJoyrideCallback}
                continuous={true}
                disableScrolling={true}
                disableScrollParentFix={true}
                showProgress={true}
                showSkipButton={true}
                steps={state.steps}
                styles={{
                  buttonClose: {
                    display: "none",
                  },
                  options: {
                    // arrowColor: '#e3ffeb',
                    // backgroundColor: '#e3ffeb',
                    // overlayColor: 'rgba(79, 26, 0, 0.4)',
                    primaryColor: "#0A2540", //'#5BE584',//'#40DFFF' //#0A2540
                    // textColor: '#004a14',
                    // width: 900,
                    zIndex: 1500,
                  },
                }}
              />
            </StyledReactFlow>
          </FullContainer>
        ) : (
          <Wrapper>
            <CircularProgress color="inherit" />
          </Wrapper>
        )}
      </StyledContainer>
    </>
  );
}
