import { Grid, Container, Typography } from "@mui/material"
import { SkeletonPostItem } from "../../components/skeleton"
// sections
import { BlogPostCard } from "../@dashboard/blog"
import { m } from "framer-motion"
// @mui
import { styled } from "@mui/material/styles"
// components
import Image from "next/image"
import { MotionViewport, varFade } from "../../components/animate"
import Iconify from "../../components/Iconify"

const ContentStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    position: "relative",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
        height: "100%",
        margin: "auto",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "800px",
    },
    [theme.breakpoints.up("xs")]: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
        textAlign: "left",
    },
}))

export default function BlogPosts() {
    const posts = [
        {
            title: "Exploring the Gaps: Beyond Google Analytics and Website Optimizer",
            metaTitle: "Exploring the Gaps: Beyond Google Analytics and Website Optimizer",
            metaDesc: "",
            socialImage:
                "https://blog.flowpoint.ai/_next/image/?url=https%3A%2F%2Fstorage.googleapis.com%2Fpublic_flowpoint_files%2Fprod%2Fblog%2FElXOMZ8tKkIMgx0gOOjiBM65TxPmWz.webp%3FGoogleAccessId%3Dflowpoint-public-api-cloud-sto%2540coral-codex-363209.iam.gserviceaccount.com%26Expires%3D1742162400%26Signature%3DsangMpbRwJMgyUdMlbmbY0YR4uYs1jzvgv4hHVZDM4TBHtMDjvKUS6rBktX2sQIkVLyMUTDXODBDjs0CH%252FKNjNG060p1MHxme4OktC4OXWGT8QNm7IIVJDza6q0ftWEf%252FRWMdTSRNapokiXJj4HUdGtZhv1z9KtIoaQK4iF3643QoBNxlNw%252FEdYEyMYjZY3myhd1%252BNuZpZygrxbUp1Z43EQRcK9LlHStQ%252Fo6Y%252FbNHkFblGc%252FgabFHfbrUtnyNGqkGEWgeUz5oOqD526tajea88GRK5V%252BQJd2dfdMYCpCSE9zU1V%252BhdWGGjsSCrSbNsnwJ7G8EpAP1GYvt6esbCukzg%253D%253D&w=828&q=75",
            url: "https://blog.flowpoint.ai/post/v2/exploring-the-gaps-beyond-google-analytics-and-website-optimizer/",
            index: 2,
            cover: "https://blog.flowpoint.ai/_next/image/?url=https%3A%2F%2Fstorage.googleapis.com%2Fpublic_flowpoint_files%2Fprod%2Fblog%2FElXOMZ8tKkIMgx0gOOjiBM65TxPmWz.webp%3FGoogleAccessId%3Dflowpoint-public-api-cloud-sto%2540coral-codex-363209.iam.gserviceaccount.com%26Expires%3D1742162400%26Signature%3DsangMpbRwJMgyUdMlbmbY0YR4uYs1jzvgv4hHVZDM4TBHtMDjvKUS6rBktX2sQIkVLyMUTDXODBDjs0CH%252FKNjNG060p1MHxme4OktC4OXWGT8QNm7IIVJDza6q0ftWEf%252FRWMdTSRNapokiXJj4HUdGtZhv1z9KtIoaQK4iF3643QoBNxlNw%252FEdYEyMYjZY3myhd1%252BNuZpZygrxbUp1Z43EQRcK9LlHStQ%252Fo6Y%252FbNHkFblGc%252FgabFHfbrUtnyNGqkGEWgeUz5oOqD526tajea88GRK5V%252BQJd2dfdMYCpCSE9zU1V%252BhdWGGjsSCrSbNsnwJ7G8EpAP1GYvt6esbCukzg%253D%253D&w=828&q=75",
            authorName: "Stefania Duma",
            avatarUrl: "/images/Stefania.webp",
        },
        {
            title: "Beyond Google Analytics: Simplifying Web Analytics with AI-Based Alternatives",
            metaTitle: "Beyond Google Analytics: Simplifying Web Analytics with AI-Based Alternatives",
            metaDesc: "",
            socialImage:
                "https://blog.flowpoint.ai/_next/image/?url=https%3A%2F%2Fstorage.googleapis.com%2Fpublic_flowpoint_files%2Fprod%2Fblog%2FLrfNAKJh9GoI0AuuBDlOEqR45MeHhT.webp%3FGoogleAccessId%3Dflowpoint-public-api-cloud-sto%2540coral-codex-363209.iam.gserviceaccount.com%26Expires%3D1742162400%26Signature%3D2AIdwQBS6ngESbvn5uRNu5SMkkr6i6ZowXzyMJyuaxJBz6J%252BJzgzrfkfSv5cwXMp75MWxzMZjcncOAFIgxPh1bQPbRnOcsHh5aPOvGeRUcCUmTfTrn0LmKsMmHXK9J3HQ8Uhad4huVP72i%252FO9c0GgNnfMH24XbMfsMnqf%252FN0eR1pOrSclGByESZs%252BNOw8i37RQCIvJi70Mij5JUZyaH1QohG6aNZBSN8bvGLN0qmK1piiLolOxOZeASdIwLlqGx0%252B5psWJCzDBfAn0Al1hJkkVxWO2uDJfnM5eXntcVRgkkjQ6nYFi%252FH1WMwDpHH9pj9zTTVAxx%252FWpWDDsF0UlcoIA%253D%253D&w=828&q=75",
            url: "https://blog.flowpoint.ai/post/v2/beyond-google-analytics-simplifying-web-analytics-with-ai-based-alternatives/",
            index: 1,
            cover: "https://blog.flowpoint.ai/_next/image/?url=https%3A%2F%2Fstorage.googleapis.com%2Fpublic_flowpoint_files%2Fprod%2Fblog%2FLrfNAKJh9GoI0AuuBDlOEqR45MeHhT.webp%3FGoogleAccessId%3Dflowpoint-public-api-cloud-sto%2540coral-codex-363209.iam.gserviceaccount.com%26Expires%3D1742162400%26Signature%3D2AIdwQBS6ngESbvn5uRNu5SMkkr6i6ZowXzyMJyuaxJBz6J%252BJzgzrfkfSv5cwXMp75MWxzMZjcncOAFIgxPh1bQPbRnOcsHh5aPOvGeRUcCUmTfTrn0LmKsMmHXK9J3HQ8Uhad4huVP72i%252FO9c0GgNnfMH24XbMfsMnqf%252FN0eR1pOrSclGByESZs%252BNOw8i37RQCIvJi70Mij5JUZyaH1QohG6aNZBSN8bvGLN0qmK1piiLolOxOZeASdIwLlqGx0%252B5psWJCzDBfAn0Al1hJkkVxWO2uDJfnM5eXntcVRgkkjQ6nYFi%252FH1WMwDpHH9pj9zTTVAxx%252FWpWDDsF0UlcoIA%253D%253D&w=828&q=75",
            authorName: "Vlad Niculescu",
            avatarUrl: "/images/Vlad.webp",
        },
        {
            title: "How to Easily Run a Website Audit for Free: The Essential Guide for Business Owners and Marketing Experts",
            metaTitle: "How to Easily Run a Website Audit for Free: The Essential Guide for Business Owners and Marketing Experts",
            metaDesc: "",
            socialImage:
                "https://blog.flowpoint.ai/_next/image/?url=https%3A%2F%2Fstorage.googleapis.com%2Fpublic_flowpoint_files%2Fprod%2Fblog%2Fs3tOiHAzzdAqH7FfCCl3uToqawpO0u.webp%3FGoogleAccessId%3Dflowpoint-public-api-cloud-sto%2540coral-codex-363209.iam.gserviceaccount.com%26Expires%3D1742162400%26Signature%3DSgrOwaSuq0OZFAtidCXkFzgpzeME9kUeNv56HnyT5ZSojiHWrxJnPURQEbPGyYBkzz411FWkPE9auyWHVr%252B0CfSVQc%252BYChfHdzZ1JsDlaSTIJi4WX1WYn2rGYTo2OMP3M5U9EC%252Bp7zGl6K2iDUE6cbx03bHOm14Lhao6hJ0Apxgm%252FhKj%252FK%252BT%252BLEs2bhv7fN9Qxy0RmBADpzR0omQCAS%252Fh4erL0O6kicripKMMbyg%252B34jExNkldy0Apd5Jf7dQSovUTXRTlzWCPz8U43DBd%252FCsN%252FNtdqR93xufJkueOWqH82pdAsbBCPD4jm9H19vVouDd7XJyy5btMVkl2HPIH9TPg%253D%253D&w=1080&q=75",
            url: "https://blog.flowpoint.ai/post/v2/how-to-easily-run-a-website-audit-for-free-the-essential-guide-for-business-owners-and-marketing-experts/",
            index: 0,
            cover: "https://blog.flowpoint.ai/_next/image/?url=https%3A%2F%2Fstorage.googleapis.com%2Fpublic_flowpoint_files%2Fprod%2Fblog%2Fs3tOiHAzzdAqH7FfCCl3uToqawpO0u.webp%3FGoogleAccessId%3Dflowpoint-public-api-cloud-sto%2540coral-codex-363209.iam.gserviceaccount.com%26Expires%3D1742162400%26Signature%3DSgrOwaSuq0OZFAtidCXkFzgpzeME9kUeNv56HnyT5ZSojiHWrxJnPURQEbPGyYBkzz411FWkPE9auyWHVr%252B0CfSVQc%252BYChfHdzZ1JsDlaSTIJi4WX1WYn2rGYTo2OMP3M5U9EC%252Bp7zGl6K2iDUE6cbx03bHOm14Lhao6hJ0Apxgm%252FhKj%252FK%252BT%252BLEs2bhv7fN9Qxy0RmBADpzR0omQCAS%252Fh4erL0O6kicripKMMbyg%252B34jExNkldy0Apd5Jf7dQSovUTXRTlzWCPz8U43DBd%252FCsN%252FNtdqR93xufJkueOWqH82pdAsbBCPD4jm9H19vVouDd7XJyy5btMVkl2HPIH9TPg%253D%253D&w=1080&q=75",
            authorName: "Vlad Niculescu",
            avatarUrl: "/images/Vlad.webp",
        },
    ]

    return (
        <Container>
            <ContentStyle style={{ textAlign: "center" }}>
                <m.div variants={varFade().inUp}>
                    <Typography
                        component='div'
                        variant='overline'
                        sx={{
                            mb: 2,
                            color: "#505668",
                            textAlign: "center",
                            justifyItems: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <Iconify icon='mdi:wireless' sx={{ width: 20, height: 20, color: "black", marginRight: "4px" }} /> Blog
                    </Typography>
                </m.div>
                <m.div variants={varFade().inUp}>
                    <Typography variant='h2' sx={{ mb: { xs: 1, md: 3 } }}>
                        Resources for growing your product
                    </Typography>
                </m.div>
            </ContentStyle>
            <Grid
                container
                spacing={3}
                style={{
                    maxWidth: 1200,
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                {posts
                    .sort((a, b) => a - b)
                    .reverse()
                    .map(({ title, index, avatarUrl, authorAvatarUrl, authorName, cover, createdAt, url }) =>
                        title ? (
                            <Grid key={index} item xs={10} sm={6} md={(index === 0 && 6) || 3}>
                                <BlogPostCard
                                    linkTo={url}
                                    avatarUrl={avatarUrl}
                                    cover={cover}
                                    key={index}
                                    title={`${title}`}
                                    createdAt={createdAt}
                                    authorName={authorName}
                                    authorAvatarUrl={authorAvatarUrl}
                                    index={index}
                                />
                            </Grid>
                        ) : (
                            <SkeletonPostItem key={index} />
                        )
                    )}
            </Grid>
        </Container>
    )
}
