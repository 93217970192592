import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import constants from "../../../utils/constants"
import NodeWrapper from "./NodeWrapper"
import { Badge } from "@mui/material";
import { styled as materialStyled } from '@mui/material/styles';
import { Typography, Card, CardActionArea, CardContent, CardMedia, Popover } from '@mui/material';

import Handle from "../Handle"
import IconPeople from "../../../assets/icon_people"


const StyledCard = materialStyled(Card)(({ theme }) => ({
    width: 80,

    textAlign: "center",
    position: "relative",
    background: "#daf1ff",
    [theme.breakpoints.up('md')]: {
        width: 120,

    },

}));

const StyledCardContent = materialStyled(Card)(({ theme }) => ({
    padding: 12,
    background: "#daf1ff",
    [theme.breakpoints.up('md')]: {
        padding: 18,
    },

}));



const customNodeStyles = {
    // height: 50,
    borderRadius: '15%',
    width: 120,
    scale: 2,
    // paddingTop: '40px'


}

const Container = styled.div`
    padding-top: 13px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: column;
`
const Path = styled.span`
    font-size: 12px;
    line-height: 22px;
`

const Element = styled.span`
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    white-space: nowrap ;
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`

const Percentage = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    span {
        font-weight: bold;
        font-size: 14px;
    }
`
const Tag = styled.span`
    font-size: 12px;
    line-height: 22px;
`

const Sessions = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    span {
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
    }
`
const StyledBadge = styled(Badge)`
    .MuiBadge-overlapRectangular {
        background-color: ${(props) => props.customcolor} !important;
    }
`

const items = { 
    confusion: { text: "Confusion", description: "Analyse the user group which do not understand your product and understand why.", icon: "https://images.unsplash.com/photo-1633613286848-e6f43bbafb8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"  }, // Lost focus / page close
    delight: { text: "Delight", description: "See how your top customers are behaving and what are the factors that drive them.", icon: "https://images.unsplash.com/photo-1542810205-0a5b379f9c52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80" }, // the ones that go straight to the finish line
    frustration: { text: "Frustration", description: "See if your users face challenges on your website and act upon them.", icon: "https://images.unsplash.com/photo-1618616191524-a9721186cbe4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2969&q=80" }, // rage clicking / hectic scrolling
    urgency: { text: "Urgency", description: "Are your users acting in a confident manner?", icon: "https://images.unsplash.com/photo-1624969862293-b749659ccc4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" }, // the ones that surf fast towards the finish line
    attention: { text: "Attention", description: "Detecting the level of attention your users have when using your website.", icon: "https://images.unsplash.com/photo-1599896442726-366ed32c63a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1773&q=80" }, // lost focus
    persistence: { text: "Persistence", description: "Detecting user perseverence to finish the defined flows", icon: "https://images.unsplash.com/photo-1571008840902-28bf8f9cd71a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"}  // Determination
}


const EmotionNode = ({ data, id }) => {
    let color = constants.edgeColors.green
    if (data.hasOnlyErrors) {
        color = constants.edgeColors.red
    }
    const percentage = parseFloat(data.percentage)
    return (
        <div style={customNodeStyles}  id={id}>
            {/* {(!data.start || data.hasLeadsFrom) && (
                <>
                    <Handle
                        type={constants.handleMethod.target}
                        position={constants.handlePosition.top}
                        top='100%'
                        id={constants.handleType.mid}
                    //  color={data.percentage === "100" ? constants.edgeColors.green : constants.edgeColors.blue}

                    />
                </>
            )} */}
             
            <StyledBadge badgeContent={ items[data.type] == items.confusion ? `${72.24}%` : `${27.76}%`} color='primary' customcolor={color}  >
            <StyledCard style={{ position: "relative", width: 80, transition: '0.2s', borderRadius: 50 }}>
                <div>
                    {/* <CardMedia
                        component="img"
                        //  height={clickedIndex === items.length - index - 1 ? '20' : '100'}
                        height={100}
                        style={{ transition: '0.2s' }}
                        image={items[data.type].icon}
                        alt="Flowpoint will see this"
                    /> */}
                     
                    <StyledCardContent>
                        <Typography gutterBottom variant="p" component="div" color="#0a2540" style={{ fontWeight: "bold", marginBottom: 0 }}>
                            { items[data.type] == items.confusion ?  <img src="/icons/confusion.png" /> : 
                            <img src="/icons/mind-blown.png" /> }
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ overflow: "hidden", transition: '0.2s', height: 0 }}>
                            {items[data.type].description}
                        </Typography>
                    </StyledCardContent>
                    
                </div>

            </StyledCard>
            </StyledBadge>
              

            {/* <Container>
                <Path>{data.path || "N/A"}</Path>
                <Element>{data.element || "N/A"}</Element>
                <Tag>
                    {data.tag} {data.type}
                </Tag>

                <Sessions>
                    <IconPeople />
                    <span>{data.entered}</span>
                    <Percentage>
                        <span>{data.percentage ? `${data.percentage}% ` : ""}</span>
                    </Percentage>
                </Sessions>
            </Container> */}

            {/* {!data.end && ( */}
            {/* <Handle
                type={constants.handleMethod.source}
                position={constants.handlePosition.right}
                top='50%'
                id={constants.handleType.mid}
            //  color={data.directPercentage === "100" ? constants.edgeColors.green : constants.edgeColors.blue}

            /> */}
            {/* )} */}
        </div>
    )
}

EmotionNode.propTypes = {
    data: PropTypes.shape({
        start: PropTypes.bool,
        hasLeadsFrom: PropTypes.bool,
        isHighlighted: PropTypes.bool,
        path: PropTypes.string,
        element: PropTypes.string,
        tag: PropTypes.string,
        type: PropTypes.string,
        entered: PropTypes.number,
        end: PropTypes.bool,
        percentage: PropTypes.string,
    }),
}
EmotionNode.defaultProps = {
    data: {
        start: false,
        isHighlighted: null,
        path: "/",
        element: "N/A",
        tag: "N/A",
        type: "N/A",
        entered: 0,
        // percentage: 0,
      
        end: false,
    },
}

export default EmotionNode


/*




 
*/