// @mui
import { styled } from "@mui/material/styles"
import { Divider, TableBody } from "@mui/material"
import { useEffect, useState } from "react"
import { m } from "framer-motion"
import { MotionViewport, varFade } from "src/components/animate"
import useResponsive from "src/hooks/useResponsive"
import Iconify from "src/components/Iconify"
import styledComponents from "styled-components"

// ----------------------------------------------------------------------
import * as Yup from "yup"

import { useSnackbar } from "notistack"
import { useFormik, Form, FormikProvider } from "formik"
import { useRouter } from "next/router"

// @mui

import { Stack, TextField, Alert, Grid, Link, Typography, Card, CardContent, Box } from "@mui/material"
import { CSVLink, CSVDownload } from "react-csv"
//

import { LoadingButton } from "@mui/lab"
// hooks
import useAuth from "../../hooks/useAuth"
import useIsMountedRef from "../../hooks/useIsMountedRef"
// components

import { HOST_API } from "../../config"
import { InlineWidget, openPopupWidget, useCalendlyEventListener } from "react-calendly"

const RootStyle = styled("div")(({ theme }) => ({
    // paddingTop: theme.spacing(8),
    color: "white",
    [theme.breakpoints.up("md")]: {
        // paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("xs")]: {
        // paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(2),
    },
    span: {
        color: "white",
    },
}))
const StyledButton = styledComponents(Button)`
padding-top: 30px
`

const ContentStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    position: "relative",
    // paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
        // height: "100%",
        margin: "auto",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "800px",
        marginTop: "0px",
    },
    [theme.breakpoints.up("xs")]: {
        marginBottom: theme.spacing(2),
        // marginTop: theme.spacing(5),
        textAlign: "left",
    },
}))

const SuccessContainer = styledComponents.div`
display: flex;
flex-direction: column;
justify-content:center;
align-items:center;`

const SuccessWrapper = styledComponents.div`
display: flex;
flex-direction: column;
justify-content:center;
align-items:center;
`

const Label = styledComponents.span`
    font-weight:bold;
`
const EventContainer = styledComponents.div`

  // background: #F3F6FD;
  borderRadius: 20px;
  height: 350px;
  overflow: hidden;
  -webkit-mask-image: -webkit-gradient(linear, left 60%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));


  `
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function TruncatedText({ text }) {
    const isDesktop = useResponsive("up", "sm")

    if (isDesktop && text.length > 35) {
        text = text.substring(0, 35) + "..."
    } else if (text.length > 23) {
        text = text.substring(0, 23) + "..."
    }

    return <span style={{ color: "white" }}>{text}</span>
}

function eventText(event) {
    if (!event) return ""
    if (event.type === "mouse_click") {
        let text = event.data?.element?.textContent || "element with no text"
        return (
            <span
                style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                }}
            >
                <Iconify icon={"icon-park-solid:click"} color='white' width='15px' height='15px' sx={{ marginRight: "5px" }} />
                <TruncatedText text={text} />
            </span>
        )
    }
    if (event.type === "page_scroll") {
        if (event.subtype === "scroll_up")
            return (
                <span
                    style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Iconify
                        icon={"fluent:phone-vertical-scroll-24-filled"}
                        color='white'
                        width='15px'
                        height='15px'
                        sx={{ marginRight: "5px" }}
                    />
                    Scrolled up
                </span>
            )
        return (
            <span
                style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    color: "white",
                }}
            >
                <Iconify
                    icon={"fluent:phone-vertical-scroll-24-filled"}
                    color='#9CC29B'
                    width='15px'
                    height='15px'
                    sx={{ marginRight: "5px" }}
                />
                Scrolled down
            </span>
        )
    }
    if (event.type === "session_end") {
        if (event.subtype === "focus_lost") {
            return "Focus lost"
        }
        if (event.subtype === "page_close") {
            return "Page closed"
        }
    }

    if (event.type === "session_start") {
        if (event.data?.performance?.type === "reload") {
            return "Page reload"
        }
        if (event.data?.performance?.type === "back_forward") {
            return "Back/forward browser button"
        }

        if (event.subtype === "page_close") {
            return "Page closed"
        }
        return "Session started"
    }

    if (event.type === "session_resume") {
        return "Focus regained"
    }

    if (event.type === "custom_event") {
        const eventName =
            event?.data?.data?.[0] ||
            (event.data?.method === "setUserAttributes" ? "User Attributes" : event.data?.method || "Custom event")
        const data = event.userAttributes || event?.data?.data?.[1] || null

        let icon = <Iconify icon={"icon-park-solid:click"} sx={{ color: "#9CC29B" }} />
        let eventNameText = eventName

        if (eventNameText.includes("Clicked on")) {
            icon = "🙈"
            eventNameText = "Form click - Password"
        }

        return (
            <>
                <div>
                    {icon} {eventNameText}
                </div>
                {data && <div style={{ fontSize: "0.8rem", marginLeft: "16px", color: "white" }}>{data.message}</div>}
            </>
        )
    } else {
        return null
    }
}

import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    FormControl,
    TableContainer,
    Paper,
    Input,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    Button,
    InputLabel,
    Container,
} from "@mui/material"
import { format } from "date-fns"

function MUTA_MA() {
    const [messages, setMessages] = useState([])

    // mock table to display events
    const table = (
        <TableContainer>
            <Table sx={{ minWidth: 200 }} size='small' aria-label='a dense table'>
                {/* <TableHead
                    sx={{
                        //  backgroundColor: "#EAF2E6!important",
                        border: "1px solid white",
                        borderRadius: "12px",
                        boxShadow: "none!important",
                    }}
                >
                    <TableRow
                        sx={{
                            // backgroundColor: "#F3F6FC!important",

                            boxShadow: "none!important",
                        }}
                    >
                        <TableCell
                            sx={{
                                backgroundColor: "transparent",
                                // backgroundImage: '#FFFFFF',
                                boxShadow: "none!important",
                                fontWeight: "500",
                                color: "white",
                            }}
                        >
                            Your recent actions
                        </TableCell>
                        <TableCell
                            sx={{
                                backgroundColor: "transparent",
                                // backgroundImage: '#FFFFFF',
                                boxShadow: "none!important",
                            }}
                        ></TableCell>
                    </TableRow>
                </TableHead> */}
                <TableBody sx={{ marginTop: "30px !important" }}>
                    {messages
                        .slice()
                        .reverse()
                        .map((item) => {
                            return (
                                <TableRow key={item.timestamp} sx={{ marginTop: "20px", verticalAlign: "baseline!important" }}>
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{
                                            paddingBottom: "10px",
                                            maxWidth: { md: "300px", xs: "100px", sm: "300px" },
                                            paddingLeft: {
                                                xs: "5px!important",
                                                md: "1px!important",
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: "500",
                                                fontFamily: "Inter, sans-serif",
                                                lineHeight: 1.57143,
                                                fontSize: "0.875rem",
                                                color: "white",
                                            }}
                                        >
                                            {item.message}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        component='th'
                                        scope='row'
                                        sx={{
                                            fontWeight: "500",
                                            fontSize: { xs: "12px", md: "12px" },
                                            padding: { xs: "0px!important", md: "24px" },
                                            width: { xs: "80px", md: "90px" },
                                            color: "white",
                                        }}
                                    >
                                        {format(new Date(item.timestamp || 0), "dd MMM, HH:mm")}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )

    // middleware function to pass data collected from flowpoint to frontend
    function onNewFlowpointEvent(event) {
        let message = eventText(event)
        if (message) {
            setMessages([
                ...messages,
                {
                    timestamp: Date.now(),
                    message: message,
                },
            ])
        }
    }

    // sends custom events to flowpoint and back to our frontend aswell
    function customEventWrapper(eventName, eventData) {
        if (window) {
            const newSize = window?.flowpoint.track(eventName, eventData)
            onNewFlowpointEvent({
                type: "custom_event",
                data: window.fpDataLayer[newSize - 1],
                timestamp: Date.now(),
            })
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            // pass middleware function from react to window.flowpoint (sdk) so the sdk sends it back
            window.flowpoint.onEvent = (newEvent) => onNewFlowpointEvent(newEvent)
        }
    })

    function OnboardingForm() {
        const { createOrganization } = useAuth()
        const isMountedRef = useIsMountedRef()
        const { enqueueSnackbar, closeSnackbar } = useSnackbar()
        const [showPassword, setShowPassword] = useState(false)
        const [csvData, setCSVData] = useState([])

        const [isSubmitted, setSubmitted] = useState(false)

        const router = useRouter()

        const OnboardingSchema = Yup.object().shape({
            companyName: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required("Company name required"),
            companyUrl: Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
            firstName: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required("First name is required"),
            lastName: Yup.string().min(2, "Too Short!").max(100, "Too Long!").required("Last name is required"),
            email: Yup.string().email("Email must be a valid email address").required("Email is required"),
        })

        const submitSuccess = () => {
            setSubmitted(true)
            // setSuccess(true)
        }

        const resetSubmit = () => {
            if (formik) {
                formik.resetForm()
            }
            setSubmitted(false)
            // setSuccess(false)
        }

        const isLocal = () => router?.query?.local

        const saveLocalCSV = (data) => {
            const csvData = [
                ["First name", "Last name", "Email", "Company", "Website", "Onboarding"],
                [data.firstName, data.lastName, data.email, data.companyName, data.companyUrl, data.onboarding],
            ]
            setCSVData(csvData)
        }

        const formik = useFormik({
            initialValues: {
                firstName: "",
                lastName: "",
                email: "",
                companyName: "",
                companyUrl: "",
            },
            validationSchema: OnboardingSchema,
            onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
                const data = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    companyName: values.companyName,
                    companyUrl: values.companyUrl,
                    onboarding: "enrolled_to_newsletter",
                }
                try {
                    const res = await fetch(`${HOST_API}/subscribe_exposition`, {
                        body: JSON.stringify(data),
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                        },
                        method: "POST",
                    })
                    const subscribeResponse = await res.json()

                    if (isLocal()) {
                        // on local we show the success message anyway and we download on local
                        submitSuccess()
                        // TODO download local csv
                        saveLocalCSV(data)
                    } else {
                        // over the internet we care about the result
                        if (subscribeResponse.message === "ok") {
                            submitSuccess()
                        } else {
                            setSubmitting(false)
                            setErrors({ afterSubmit: "Error registering your interest" })
                        }
                    }

                    if (isMountedRef.current) {
                        setSubmitting(false)
                    }
                } catch (error) {
                    resetForm()
                    if (isLocal()) {
                        // on local we always show success and save csv on local
                        submitSuccess()
                        saveLocalCSV(data)
                    } else {
                        if (isMountedRef.current) {
                            setSubmitting(false)
                            setErrors({ afterSubmit: "Error registering your interest" })
                        }
                    }
                }
            },
        })

        const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

        const renderSuccess = () => {
            return (
                <RootStyle>
                    <SuccessContainer>
                        <SuccessWrapper>
                            <Box sx={{ ml: 2, mr: 2 }}>
                                <Iconify icon='clarity:success-standard-line' width={70} height={70} color={"#5BE584"} />
                            </Box>
                            <CardContent
                                sx={{
                                    color: "grey.800",
                                    p: { md: 0 },
                                    pl: { md: 5 },
                                    p: 3,
                                    textAlign: "center",
                                }}
                            >
                                <Typography gutterBottom variant='h4' onClick={() => resetSubmit()}>
                                    Welcome to Flowpoint newsletter.
                                    <br />
                                </Typography>

                                <Typography variant='body2' sx={{ maxWidth: 480, mx: "auto" }}>
                                    We regularly send out emails about industry news and every time we update Flowpoint with new cool
                                    features.
                                </Typography>
                            </CardContent>
                        </SuccessWrapper>
                        <StyledButton
                            variant='contained'
                            size='large'
                            onClick={() => {
                                const { values } = formik
                                const prefill = {
                                    name: `${values?.firstName || ""} ${values?.lastName || ""}`,
                                    email: values?.email || "",
                                    customAnswers: {
                                        a1: values?.companyName || "",
                                        a2: values?.companyUrl || "",
                                        a3: "I registered on Flowpoint for newsletter.",
                                    },
                                }
                                openPopupWidget({
                                    url: "https://calendly.com/flowpoint-vlad/intro",
                                    prefill,
                                })
                            }}
                        >
                            Schedule an intro with us
                        </StyledButton>
                    </SuccessContainer>
                    {/* <InlineWidget url="https://calendly.com/flowpoint-ai/onboarding" /> */}
                    {csvData.length ? <CSVDownload data={csvData} /> : <div />}
                </RootStyle>
            )
        }

        return isSubmitted ? (
            renderSuccess()
        ) : (
            <FormikProvider value={formik}>
                <ContentStyle style={{ textAlign: "center" }}>
                    <m.div variants={varFade().inUp}>
                        <Typography
                            component='div'
                            variant='overline'
                            sx={{
                                mb: 1,
                                color: "#505668",
                                textAlign: "center",
                                justifyItems: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Iconify
                                icon='mdi:wireless'
                                sx={{
                                    width: 20,
                                    height: 20,
                                    color: "rgb(3, 213, 254)",
                                    marginRight: "4px",
                                }}
                            />{" "}
                            Newsletter
                        </Typography>
                    </m.div>
                    <m.div variants={varFade().inUp}>
                        <Typography variant='h6' sx={{ mb: { xs: 1, md: 1 } }}>
                            Get updates straight to your inbox. Enroll in our newsletter!
                        </Typography>
                    </m.div>
                </ContentStyle>
                <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                    {errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}
                    <Label>General details</Label>
                    <Grid
                        container
                        spacing={2}
                        justifyContent={{ xs: "center", md: "space-between" }}
                        sx={{ textAlign: { xs: "center", md: "left" }, mt: "1px" }}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                color='secondary'
                                sx={{ label: { color: "#fff" } }}
                                autoComplete='firstName'
                                type='text'
                                label='First Name'
                                {...getFieldProps("firstName")}
                                error={Boolean(touched.firstName && errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                                onClick={() => customEventWrapper("Form click - First name")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                sx={{ label: { color: "#fff" } }}
                                autoComplete='lastName'
                                type='text'
                                label='Last Name'
                                {...getFieldProps("lastName")}
                                error={Boolean(touched.lastName && errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                                onClick={() => customEventWrapper("Form click - Last name")}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        justifyContent={{ xs: "center", md: "space-between" }}
                        sx={{ textAlign: { xs: "center", md: "left" }, mt: "1px" }}
                    >
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                sx={{ label: { color: "#fff" } }}
                                autoComplete='email'
                                type='text'
                                label='Email address'
                                {...getFieldProps("email")}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                onClick={() => customEventWrapper("Form click - Email address")}
                            />
                        </Grid>
                    </Grid>
                    <Stack spacing={2}>
                        <br />
                        <Label>Organization details</Label>
                        <TextField
                            fullWidth
                            sx={{ label: { color: "#fff" } }}
                            autoComplete='companyName'
                            type='text'
                            label='Organization name'
                            {...getFieldProps("companyName")}
                            error={Boolean(touched.companyName && errors.companyName)}
                            helperText={touched.companyName && errors.companyName}
                            onClick={() => customEventWrapper("Form click - Company name")}
                        />
                        <TextField
                            fullWidth
                            sx={{ label: { color: "#fff" } }}
                            autoComplete='companyUrl'
                            type='text'
                            label='Website URL'
                            {...getFieldProps("companyUrl")}
                            error={Boolean(touched.companyUrl && errors.companyUrl)}
                            helperText={touched.companyUrl && errors.companyUrl}
                            onClick={() => customEventWrapper("Form click - Company Url")}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "40px",
                            }}
                        >
                            <LoadingButton
                                fullWidth
                                size='large'
                                type='submit'
                                variant='contained'
                                loading={isSubmitting}
                                sx={{
                                    background: "#FF4842",
                                    width: "200px",
                                    borderRadius: "50px",
                                }}
                            >
                                Enroll
                            </LoadingButton>
                        </div>

                        {/* <Stack direction='row' alignItems='center' justifyContent='center' sx={{ my: 2, width: '100%' }}>
                        <Link variant='subtitle2' target="_blank" href={`https://dashboard.flowpoint.ai/auth/register?ref=${name}&type=${type}&id=${id}`}>
                            Register now
                        </Link>
                </Stack> */}
                    </Stack>
                </Form>
            </FormikProvider>
        )
    }
    return (
        <div>
            <Container>
                <Grid container spacing={2} justifyContent='space-between'>
                    {/* <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: "left", alignItems: "center" }}
          >
            <Box
              sx={{
                background: "#F3F6FD",
                borderRadius: "20px",
                width: { md: "90%", xs: "100%" },
                padding: { md: "60px", xs: "20px" },
              
                maxHeight: { md: "720px", xs: "fit-content" },
              }}
            >
             
              {OnboardingForm()}{" "}
            </Box>
          </Grid> */}
                    <Grid item xs={12} md={12} style={{ textAlign: "left", alignItems: "center" }}>
                        <Box
                            sx={{
                                // backgroundImage:
                                // "linear-gradient(113.61380240615335deg, #FFF 5.273809523809524%,#EFF8FD 97.17857142857142%)",
                                // border: '.81px solid rgba(0,0,0,.1)',
                                background: "#132821",
                                borderRadius: "12px",
                                width: { md: "100%", xs: "100%" },
                                paddingX: { md: "20px", xs: "10px" },
                                paddingTop: { md: "20px", xs: "30px" },
                                paddingBottom: { md: "20px", xs: "100px", sm: "130px" },
                                maxHeight: { md: "520px", xs: "500px", sm: "500px" },
                                overflow: "hidden",
                                border: "1px solid rgb(211, 217, 218)",
                                // boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                                // boxShadow: "rgba(145, 158, 171, 0.16) -40px 40px 80px",
                                // maskImage: 'linear-gradient(to bottom, var(--colorTrueBlack) 60%, transparent 100%)'
                            }}
                        >
                            <Box
                                sx={{
                                    // background: "white",
                                    borderRadius: "20px",
                                    // p: 1,
                                    // boxShadow:
                                    //  "0 1.6px 3.25px rgba(0,0,0,.1), 0 0.81px 0.81px rgba(0,0,0,.06), 0 0 4.88px rgba(0,0,0,.1)",
                                    // padding: '10px'
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    paragraph
                                    sx={{ p: 2, backgroundColor: "#EAF2E6!important", borderRadius: "12px", color: "black" }}
                                >
                                    🔎 See for yourself by clicking around.
                                </Typography>
                                {/* <Typography
                variant="body2"
                sx={{
                  mb: 3,
                  color: "black",
                 
                }}
              >
                Flowpoint automatically captures where your users click, where
                they scroll, the time spent on different sections and many more.
              </Typography> */}
                                <EventContainer>{table}</EventContainer>
                                {/* <div style={{ height: "30px" }}>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    height: "50px",
                    color: "black",
                    fontSize: { md: "18px", xs: "12px", sm: "12px" },
                    fontWeight: "500",
                    mb: 5,
                  }}
                >
                  👈 Try it now and discover how every action is captured. Click
                  away to see for yourself!
                </Typography>
              </div> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default function About() {
    return (
        <RootStyle>
            {/* <ContentStyle style={{ textAlign: "center" }}>
        <m.div variants={varFade().inUp}>
          <Typography
            component="div"
            variant="overline"
            sx={{ mb: 2, color: "#505668", textAlign: "center", justifyItems: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          >
           <Iconify icon='mdi:wireless' sx={{ width: 20, height: 20, color: 'rgb(3, 213, 254)', marginRight: '4px' }} /> Newsletter
          </Typography>
        </m.div>
        <m.div variants={varFade().inUp}>
          <Typography variant="h2" sx={{ mb: { xs: 1, md: 3 } }}>
          Get updates straight to your inbox. Enroll in our newsletter!
          </Typography>
        </m.div>
      </ContentStyle> */}
            {MUTA_MA()}
        </RootStyle>
    )
}
