import { m } from "framer-motion"

import React from "react"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Container, Typography, Button } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import Iconify from "../../components/Iconify"
import StepperCustom from "src/components/Stepper"
import VerticalLinearStepper from "src/components/VerticalLinearStepper"
const CustomButton = styled(Button)(({ theme }) => ({}))
import useResponsive from "src/hooks/useResponsive"
const CardStyle = styled("div")(({ theme }) => ({
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    paddingBottom: theme.spacing(10),
    padding: 30,
    justifyContent: "space-around",
    // color: "black",
    borderRadius: Number(theme.shape.borderRadius) * 3,
    // background: "#00140C",
    background: "#F5F6F7",

    // boxShadow: "rgba(145, 158, 171, 0.16) -40px 40px 80px",
    [theme.breakpoints.up("md")]: {
        display: "flex",
        maxWidth: "100%",
        paddingBottom: 50,
        paddingTop: 50,
        alignItems: "center",
    },
}))

// ----------------------------------------------------------------------
const steps = [
    {
        label: "Data Sampling",
        description:
            "Our AI-driven system takes smart, small snapshots of your website's data to get an overview of what's happening without overwhelming resources.",
    },
    {
        label: "Data Validation",
        description: "We use AI to check that the data we collect is accurate and consistent, so you can trust the insights it provides.",
    },
    {
        label: "Privacy Compliance",
        description:
            "Our automated processes ensure we follow strict privacy rules, safeguarding user data and complying with regulations.",
    },
    {
        label: "Quality Assurance",
        description: "AI continuously checks data for errors and maintains its quality, ensuring you receive reliable insights.",
    },
    {
        label: "Redundancy Checks",
        description: "Our AI system identifies and removes duplicate data, streamlining information for better analysis.",
    },
    {
        label: "Accurate Data",
        description: "With AI-powered monitoring, your data remains up-to-date and precise, enabling you to make informed decisions.",
    },
]
export default function HomeDPO() {
    const isDesktop = useResponsive("up", "md")

    return (
        <Container component={MotionViewport}>
            <Box
                sx={{
                    textAlign: "center",
                    mb: { xs: 10, md: 10 },
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <m.div variants={varFade().inUp}>
                    <Typography component='div' variant='overline' sx={{ mb: 4, color: "#505668" }}>
                        DATA PROTECTION
                    </Typography>
                </m.div>
                <m.div variants={varFade().inDown}>
                    <Typography variant='h2' sx={{ fontFamily: "Poppins, sans-serif", maxWidth: "750px" }}>
                        We care about your data
                    </Typography>
                </m.div>
            </Box>
            {!isDesktop ? (
                <VerticalLinearStepper />
            ) : (
                <StepperCustom
                    strokeColor='white'
                    fillStroke='white'
                    activeColor='white'
                    activeProgressBorder='2px solid white'
                    submitBtn={<CustomButton>Submit</CustomButton>}
                    continueBtn={<CustomButton>Next</CustomButton>}
                    backBtn={<CustomButton>Back</CustomButton>}
                >
                    {steps.map((step, index) => (
                        <CardStyle key={index}>
                            <Typography variant='h5' sx={{ mb: 4 }}>
                                {step.label}
                            </Typography>
                            <Typography
                                variant='body1'
                                sx={{
                                    color: "black",
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 500,
                                    lineHeight: "1.778rem",
                                    fontSize: { xs: "15px", md: "1rem" },
                                }}
                                gutterBottom
                            >
                                {step.description}
                            </Typography>
                        </CardStyle>
                    ))}
                </StepperCustom>
            )}
        </Container>
    )
}
