import { useState } from "react";
import { m } from "framer-motion";
// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  useTheme,
  Alert,
  Slide,
  Skeleton,
  CircularProgress,
  Paper,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
// components
import Image from "../../components/Image";
import { MotionViewport, varFade } from "../../components/animate";
import styledComponent from "styled-components";
import { PageNotFoundIllustration } from "../../assets";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// ----------------------------------------------------------------------

const IssueContainer = styledComponent.div`
  margin:auto;
  margin-top:10px;
  display: flex;
  height: 100%;
  margin:0px;
  margin-left:20px;
  background-color: #F4F6F8;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  img{
    width: 450px;
  }
  @media only screen and (max-width: 768px) {
  margin-left:0px;
    margin-top:10px;
  }
`;
const ImageContainer = styledComponent.div`
  display:flex;
  justify-content:center;
  flex-direction:row;
  border-radius: 15px;
  padding: 10px;
  background-color: #F4F6F8;
`;

/*
    box-shadow: rgb(145 158 171 / 40%) -40px 40px 80px 0px;
    opacity: 1;
    transform: none;
*/

const StyledToastContainer = styled(ToastContainer)`
  position: absolute;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    position: relative;
    width: 100% !important;
    margin-top: 10px;
    height: 200px;
  }

  .Toastify__toast {
    @media only screen and (min-width: 768px) {
      width: 240px;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .Toastify__toast-container {
    position: absolute !important;
    @media only screen and (min-width: 768px) {
      max-width: 260px;
    }
  }
`;

const CarouselContainer = styledComponent.div`
width: 100%;
display: flex;
flex-direction:row;
justify-content:space-between;

`;
const errors = [{}];

const BoxDiv = styled("div")(({ theme }) => ({
  height: "auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  marginTop: 10,
  width: "100%",

  [theme.breakpoints.up("md")]: {
    height: 700,
    width: 700,
  },
}));

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(15, 0),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 720,
  margin: "auto",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    zIndex: 11,
    textAlign: "center",
    position: "relative",
  },
}));

const ToastDiv = styled(m.div)(({ theme }) => ({
  position: "relative",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    height: 180,
    width: "350px !important",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 5,
  },
}));

const SkeletonDiv = styled(m.div)(({ theme }) => ({
  position: "relative",
  backgroundColor: "white",
  padding: 10,
  borderRadius: 5,
  boxShadow: "rgb(145 158 171 / 40%) -40px 40px 80px 0px",
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    height: 180,
    width: 230,
    top: 20,
    left: 300,
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: "rgb(145 158 171 / 40%) -40px 40px 80px 0px",
    opacity: 1,
    transform: "none",
  },
}));

const PageNotFoundDiv = styled(m.div)(({ theme }) => ({
  position: "relative",
  marginTop: 40,
  border: "1px dotted black",
  borderRadius: 5,
  height: 130,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    position: "absolute !important",
    height: 140,
    width: 200,
    top: 210,
    left: 20,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    border: "1px dotted black",
    borderRadius: 5,
  },
}));

const CircularProgressDiv = styled(m.div)(({ theme }) => ({
  position: "relative",
  marginTop: 40,
  justifyContent: "center",
  display: "flex",
  [theme.breakpoints.up("md")]: {
    position: "absolute !important",
    height: 150,
    width: 150,
    top: 250,
    left: 205,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },
}));

const ButtonDiv = styled(m.div)(({ theme }) => ({
  position: "relative",
  marginTop: 40,
  justifyContent: "center",
  display: "flex",
  [theme.breakpoints.up("md")]: {
    height: 150,
    width: 220,
    top: 250,
    left: 320,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    borderRadius: 5,
    position: "absolute !important",
  },
}));

const CardsDiv = styled(m.div)(({ theme }) => ({
  position: "relative",
  marginTop: 40,
  [theme.breakpoints.up("md")]: {
    position: "absolute !important",
    height: 180,
    width: 500,
    left: 0,
    top: 420,
    borderRadius: 5,
  },
}));

// ----------------------------------------------------------------------

export default function HomeCleanInterfaces() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [isButtonHovered, setButtonHovered] = useState(false);
  const notify = (index) =>
    toast.error("🦄 Strange error!", {
      position: "top-right",
      toastId: `toast${index}`,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  setInterval(() => notify(1), 5100);
  setInterval(() => notify(2), 2500);

  return (
    <RootStyle>
      <Container
        component={MotionViewport}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <ContentStyle sx={{mb: { xs: 10, md: 10 },}}>
          {/* <m.div variants={varFade().inUp}>
            <Typography
              component="div"
              variant="overline"
              sx={{ mb: 2, color: "text.disabled" }}
            >
              Automated detection
            </Typography>
          </m.div> */}

          <m.div variants={varFade().inUp}>
            <Typography
              variant="h2"
              paragraph
              sx={{
                marginBottom: 5,
                ...(!isLight && {
                  textShadow: (theme) =>
                    `4px 4px 16px ${alpha(theme.palette.grey[800], 0.48)}`,
                }),
              }}
            >
              Identify issues at scale
            </Typography>
          </m.div>
          {/* <m.div variants={varFade().inUp}>
            <Typography
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "text.secondary"
                    : "text.primary",
              }}
            >
              Flowpoint understands the major pain points in your users' behaviour and uses the data to highlight the most impactful issues in your flows
            </Typography>
          </m.div> */}
        </ContentStyle>
        <Grid container>
          {/* <Grid item md={6} xs={12}>
            <ImageContainer >
              <BoxDiv sx={{


              }}>
                <ToastDiv
                  component={m.div}
                  variants={varFade().inUp}
                >
                  <StyledToastContainer />
                </ToastDiv>
                <SkeletonDiv
                  component={m.div}
                  variants={varFade().inUp}

                >
                  <Skeleton variant="text" />
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="rectangular" height={90} />
                </SkeletonDiv>
                <PageNotFoundDiv
                  component={m.div}
                  variants={varFade().inUp}

                >
                  <PageNotFoundIllustration sx={{ height: 80 }} />
                </PageNotFoundDiv>
                <CircularProgressDiv
                  component={m.div}
                  variants={varFade().inUp}
                  style={{

                  }}
                >
                  <CircularProgress />
                </CircularProgressDiv>
                <ButtonDiv
                  component={m.div}
                  variants={varFade().inUp}

                >
                  <Button
                    variant="contained"
                    disabled={isButtonHovered}
                    onMouseEnter={() => setButtonHovered(true)}>
                    {isButtonHovered ? "BUTTON NOT WORKING" : "GET FREE CAT"}
                  </Button>
                </ButtonDiv>
                <CardsDiv
                  component={m.div}
                  variants={varFade().inUp}
                  style={{

                  }}
                >
                  <Carousel style={{ padding: 10 }}>
                    <CarouselContainer style={{ padding: 20 }}>
                      <Card style={{ marginRight: 15, width: '100%' }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            width="200"
                            image="https://picsum.photos/seed/water/400/200"

                            alt="green iguana"
                          />

                        </CardActionArea>
                      </Card>
                      <Card style={{ marginRight: 15, width: '100%' }}>
                        <CardActionArea>
                          <CardMedia

                            component="img"
                            height="140"
                            width="200"
                            image="https://picsum.photos/seed/water/400/200"
                            alt="Image could not be loaded"
                          />

                        </CardActionArea>
                      </Card>
                      <Card style={{ width: '100%' }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            width="200"
                            style={{ padding: 10 }}
                            image="/static/images/cards/contemplative-reptile.jpg"
                            alt="Err...Flowpoint will see this"
                          />

                        </CardActionArea>
                      </Card>
                    </CarouselContainer>

                  </Carousel>
                </CardsDiv>


              </BoxDiv>

            </ImageContainer>
          </Grid> */}
          <Grid md={6} xs={12} item style={{ marginTop: 10 }}>
            <IssueContainer>
              <img src="/images/technical.webp" />
            </IssueContainer>
         
          </Grid>
          <Grid md={6} xs={12} item style={{ marginTop: 10 }}>
            <IssueContainer>
              <img src="/images/behaviour.webp" />
            </IssueContainer>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
