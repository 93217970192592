import { m } from "framer-motion"
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles"
import NextLink from "next/link"
import { Box, Grid, Button, Container, Typography } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import styledComponent from "styled-components"
import Iconify from "../../components/Iconify"
import CustomEventsNewsletter from "./CustomEventsNewsletter"
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    paddingTop: "50px",
    paddingBottom: "100px",
}))

const ContentStyle = styled("div")(({ theme }) => ({
    width: "100%",
    textAlign: "center",
    // marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        marginBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    [theme.breakpoints.up("xs")]: {
        textAlign: "left",
        marginTop: theme.spacing(5),
        paddingLeft: 10,
        paddingRight: 10,
    },
    [theme.breakpoints.up("sm")]: {
        textAlign: "left",
        marginTop: 0,
    },
}))

const ScreenStyle = styled(m.div)(({ theme }) => ({
    paddingRight: 2,
    paddingBottom: 1,
    maxWidth: 160,
    borderRadius: 8,
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
    [theme.breakpoints.up("sm")]: {
        maxWidth: 320,
        paddingRight: 4,
        borderRadius: 12,
    },
    "& img": {
        borderRadius: 8,
        [theme.breakpoints.up("sm")]: {
            borderRadius: 12,
        },
    },
}))

const COMMON = {
    scaleX: 0.86,
    skewY: 8,
    skewX: 0,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    opacity: 0,
}

const variantScreenLeft = {
    initial: COMMON,
    animate: { ...COMMON, translateX: "-50%", translateY: 40, opacity: 1 },
}

const variantScreenCenter = {
    initial: COMMON,
    animate: { ...COMMON, opacity: 1 },
}

const variantScreenRight = {
    initial: COMMON,
    animate: { ...COMMON, translateX: "50%", translateY: -40, opacity: 1 },
}

// ----------------------------------------------------------------------

export default function HomeHugePackElements() {
    const theme = useTheme()

    const isLight = theme.palette.mode === "light"

    const isRTL = theme.direction === "rtl"

    const screenLeftAnimate = variantScreenLeft

    const screenCenterAnimate = variantScreenCenter

    const screenRightAnimate = variantScreenRight

    return (
        <MotionViewport disableAnimatedMobile={false}>
            <RootStyle>
                <Container>
                    <Grid container spacing={6} justifyContent='space-between' alignContent='center' alignItems='center'>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                // paddingRight: "20px",
                            }}
                        >
                            <ContentStyle>
                                <m.div variants={varFade().inUp}>
                                    <Typography component='div' variant='overline' sx={{ mb: 2, color: "#505668" }}>
                                        Collect
                                    </Typography>
                                </m.div>

                                <m.div variants={varFade().inUp}>
                                    <Typography variant='h2' sx={{ mb: 3 }}>
                                        Capture user events
                                    </Typography>
                                </m.div>

                                <m.div variants={varFade().inUp}>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            color: "text.secondary",
                                            fontFamily: "Inter",
                                            fontWeight: 500,
                                            lineHeight: "1.778rem",
                                            fontSize: { xs: "15px", md: "1rem" },
                                        }}
                                        gutterBottom
                                    >
                                        Flowpoint automatically captures where your users click, scroll, the time spent on different
                                        sections and many more.
                                    </Typography>
                                    <ul style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                        <Typography
                                            component='li'
                                            variant='body1'
                                            sx={{
                                                color: "text.secondary",
                                                fontFamily: "Inter, sans-serif",
                                                fontWeight: 500,
                                                lineHeight: "1.778rem",
                                                fontSize: { xs: "15px", md: "1rem" },
                                                "&::marker": {
                                                    color: "#122920",
                                                },
                                            }}
                                            gutterBottom
                                        >
                                            Standard & Advanced event capturing
                                        </Typography>
                                        <Typography
                                            component='li'
                                            variant='body1'
                                            sx={{
                                                color: "text.secondary",
                                                fontFamily: "Inter, sans-serif",
                                                fontWeight: 500,
                                                lineHeight: "1.778rem",
                                                fontSize: { xs: "15px", md: "1rem" },
                                                "&::marker": {
                                                    color: "#122920",
                                                },
                                            }}
                                            gutterBottom
                                        >
                                            Cross-domain tracking
                                        </Typography>
                                        <Typography
                                            component='li'
                                            variant='body1'
                                            sx={{
                                                color: "text.secondary",
                                                fontFamily: "Inter, sans-serif",
                                                fontWeight: 500,
                                                lineHeight: "1.778rem",
                                                fontSize: { xs: "15px", md: "1rem" },
                                                "&::marker": {
                                                    color: "#122920",
                                                },
                                            }}
                                            gutterBottom
                                        >
                                            Lightweight script, optimised for performance
                                        </Typography>
                                        <Typography
                                            component='li'
                                            variant='body1'
                                            sx={{
                                                color: "text.secondary",
                                                fontFamily: "Inter, sans-serif",
                                                fontWeight: 500,
                                                lineHeight: "1.778rem",
                                                fontSize: { xs: "15px", md: "1rem" },
                                                "&::marker": {
                                                    color: "#122920",
                                                },
                                            }}
                                            gutterBottom
                                        >
                                            5-minute installation
                                        </Typography>
                                        <Typography
                                            component='li'
                                            variant='body1'
                                            sx={{
                                                color: "text.secondary",
                                                fontFamily: "Inter, sans-serif",
                                                fontWeight: 500,
                                                lineHeight: "1.778rem",
                                                fontSize: { xs: "15px", md: "1rem" },
                                                "&::marker": {
                                                    color: "#122920",
                                                },
                                            }}
                                            gutterBottom
                                        >
                                            GDPR & CCPA Ready
                                        </Typography>
                                    </ul>
                                    {/* <Typography
                  variant='subtitle1'
                    sx={{
                      mb: 1,
                      color: "text.secondary",
                    }}
                  >
                    Data is stored in secure encrypted buckets, and customers
                    have 100% ownership.
                  </Typography> */}
                                </m.div>
                                {/* <m.div variants={varFade().inUp}>
                  <Typography
                    sx={{
                      mb: 5,
                      color: "text.secondary",
                    }}
                  >
                    It does not affect page loading and performance.
                  </Typography>
                </m.div> */}
                                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    position: "relative",
                    justifyContent: "flex-start",
                    // borderRadius: "12px",
                    // background: '#F2F5FB',
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <m.div variants={varFade().inUp}>
                    <InsightsInProgressTwo>
                      <CardShadowTwo>
                        <Iconify
                          icon="heroicons-outline:cursor-click"
                          sx={{ height: "30px", width: "30px" }}
                        />
                      </CardShadowTwo>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <InsightText
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          Single atomic events: clicks, page views, scrolls and
                          more.
                        </InsightText>
                      </div>
                    </InsightsInProgressTwo>
                    <InsightsInProgressTwo>
                      <CardShadowTwo>
                        <Iconify
                          icon="material-symbols:temp-preferences-custom"
                          sx={{ height: "30px", width: "30px" }}
                        />
                      </CardShadowTwo>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <InsightText
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          Custom events
                        </InsightText>
                      </div>
                    </InsightsInProgressTwo>
                    <InsightsInProgressTwo>
                      <CardShadowTwo>
                        <Iconify
                          icon="ph:code-bold"
                          sx={{ height: "30px", width: "30px" }}
                        />
                      </CardShadowTwo>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <InsightText
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          5 minutes integration
                        </InsightText>
                      </div>
                    </InsightsInProgressTwo>
                    <InsightsInProgressTwo>
                      <CardShadowTwo>
                        <Iconify
                          icon="material-symbols:privacy-tip-outline-rounded"
                          sx={{ height: "30px", width: "30px" }}
                        />
                      </CardShadowTwo>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <InsightText
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          GDPR, CCPA compliant
                        </InsightText>
                      </div>
                    </InsightsInProgressTwo>
                    <InsightsInProgressTwo>
                      <CardShadowTwo>
                        <Iconify
                          icon="mdi:account-security-outline"
                          sx={{ height: "30px", width: "30px" }}
                        />
                      </CardShadowTwo>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <InsightText
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          Privacy focused
                          <a
                            href="/privacy"
                            target="_blank"
                            rel="noopener"
                            style={{ marginLeft: "5px" }}
                          >
                            Have a look
                          </a>
                        </InsightText>
                      </div>
                    </InsightsInProgressTwo>
                  </m.div>
                </Box> */}
                            </ContentStyle>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomEventsNewsletter />
                        </Grid>
                    </Grid>
                </Container>
            </RootStyle>
        </MotionViewport>
    )
}
