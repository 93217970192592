import React, { useState, useEffect } from "react"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { styled } from "@mui/material/styles"
import styledComponents from "styled-components"
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector"

// Define styled components
const StepperDiv = styledComponents.div`
    z-index: 3;
`

const ProgressBarDiv = styledComponents.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ProgressBarContainer = styledComponents.div`
    position: relative;
    width: 100%;
`

const Progress = styledComponents.div`
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: 3;
    transition: background 1s;
`

const Percent = styledComponents.div`
    position: absolute;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: width 1s;
`

const DefaultButton = styledComponents.button`
    background: #3a4047;
    color: #f3f4f5;
    cursor: pointer;
    outline: 0;
    box-shadow: none;
    padding: 0.4em 1em;
    border: none;
    border-radius: 45px;
`

const CARDS = [
    {
        icon: "icon-park-solid:check-one",
        title: "Data Sampling",
        description: "Track where users ",
        href: "/privacy/",
    },
    {
        icon: "icon-park-solid:check-one",
        title: "Data Validation",
        description: "Flowpoint leverages  ",
        href: "/privacy/",
    },
    {
        icon: "icon-park-solid:check-one",
        title: "Privacy Compliance",
        description: "Discover data-driven ",
        href: "/data-protection/",
    },
    {
        icon: "icon-park-solid:check-one",
        title: "Quality Assurance",
        description: "Track where users ",
        href: "/privacy/",
    },
    {
        icon: "icon-park-solid:check-one",
        title: "Redundancy Checks",
        description: "Flowpoint leverages  ",
        href: "/privacy/",
    },
    {
        icon: "icon-park-solid:check-one",
        title: "Accurate Data",
        description: "Discover data-driven ",
        href: "/data-protection/",
    },
]
const StepperComponent = (props) => {
    const {
        children = [],
        showProgressBar = true,
        defaultActiveStep = 1,
        backBtn,
        continueBtn,
        submitBtn,
        onContinue = () => {},
        onPrev = () => {},
        onSubmit = () => {},
        btnPos = "space-between",
        barWidth = "",
        strokeColor = "#cdd3d8",
        fillStroke = "#3a4047",
        stroke = 2,
        activeColor = "#3A4047",
        activeProgressBorder = "2px solid #f3f4f5",
        progressBarClassName = "",
        contentBoxClassName = "",
        allowClickControl = true,
    } = props

    const [active, setActive] = useState(0)

    useEffect(() => {
        if (defaultActiveStep <= children.length && defaultActiveStep > 0) {
            progress(defaultActiveStep - 1)
            setActive(defaultActiveStep)
        } else {
            progress(0)
            setActive(1)
        }
    }, [])

    useEffect(() => {
        if (children.length > 1 && showProgressBar) {
            // Progress Circle Border & Background Color
            children.map((_child, ind) => {
                const element = document.getElementById(`input_${ind}`)
                if (element.classList.length > 1) {
                    element.style.background = activeColor
                    element.style.border = activeProgressBorder
                } else {
                    element.style.background = "#fff"
                    element.style.border = "2px solid #3A4047"
                }
            })
        }
    }, [active])

    const previousStep = () => {
        if (!active || active <= 1) {
            return
        }
        progress(active - 2)
        const newActive = active - 1
        setActive(newActive)
        onPrev(newActive)
    }

    const nextStep = () => {
        if (!active || active >= children.length) {
            return
        }
        progress(active)
        const newActive = active + 1
        setActive(newActive)
        onContinue(newActive)
    }

    const progressClick = (ind) => {
        if (!allowClickControl) return
        setActive(ind + 1)
        progress(ind)
    }

    const progress = (stepNum) => {
        if (children.length > 1 && showProgressBar) {
            let p = (stepNum / (children.length - 1)) * 100
            document.getElementsByClassName("percent")[0].style.width = `${p}%`
        }

        if (children.length > 1 && showProgressBar) {
            for (let index = 0; index < children.length; index++) {
                const e = document.getElementById(`input_${index}`)
                const eInd = parseInt(e?.id?.split("_")[1])
                if (eInd === stepNum) {
                    e.classList.add("selected")
                    e.classList.remove("completed")
                }
                if (eInd <= stepNum) {
                    e.classList.add("completed")
                }
                if (eInd > stepNum) {
                    e.classList.remove("selected", "completed")
                }
            }
        }
    }

    return (
        <StepperDiv className={"stepperDiv"}>
            {children.length > 1 && showProgressBar && (
                <ProgressBarDiv className={`${"progressBarDiv"} ${progressBarClassName}`}>
                    <ProgressBarContainer className={"progressBarContainer"} style={{ width: barWidth }}>
                        <Progress className={"progress"} style={{ borderBottom: `${stroke}px solid ${strokeColor}` }}>
                            <Percent className={"percent"} style={{ borderBottom: `${stroke}px solid ${fillStroke}` }}></Percent>
                        </Progress>

                        <Stepper
                            activeStep={active - 1}
                            alternativeLabel
                            sx={{
                                // Apply the CSS classes to the Stepper component

                                "& .MuiStepConnector-line": {
                                    "&.Mui-completed": {
                                        borderColor: "blue",
                                        borderTopWidth: "2px",
                                    },
                                },

                                "& .MuiStepLabel-label": {
                                    transition: "color 2s",
                                },
                                "& .MuiStepIcon-root": {
                                    transition: "color 2s",
                                    "&.Mui-active": {
                                        color: "#007EFF!important",
                                    },

                                    "&:not(.Mui-active, .Mui-completed)": {
                                        color: "lightgrey!important",
                                    },
                                },
                            }}
                        >
                            {children.map((_child, ind) => (
                                <Step key={ind} id={`input_${ind}`}>
                                    <StepLabel onClick={() => progressClick(ind)}>{CARDS[ind].title}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </ProgressBarContainer>
                </ProgressBarDiv>
            )}

            <div className={contentBoxClassName}>
                {children.length > 1
                    ? children.map((child, index) => {
                          return active === index + 1 && child
                      })
                    : children}

                <div
                    style={{
                        display: "flex",
                        justifyContent: btnPos,
                        marginTop: "20px",
                    }}
                >
                    {active > 1 ? (
                        backBtn ? (
                            <span
                                onClick={previousStep}
                                style={{
                                    marginRight: "20px",
                                    backgroundColor: "#F5F6F7",
                                    borderRadius: "8px",
                                    border: "1px solid #E2E6E9",
                                }}
                            >
                                {backBtn}
                            </span>
                        ) : (
                            <DefaultButton className={"defaultBtn"} onClick={previousStep} style={{ marginRight: "20px" }}>
                                Go Back
                            </DefaultButton>
                        )
                    ) : (
                        <span></span>
                    )}
                    {active < children.length ? (
                        continueBtn ? (
                            <span
                                style={{
                                    marginRight: "20px",
                                    backgroundColor: "#F5F6F7",
                                    borderRadius: "8px",
                                    border: "1px solid #E2E6E9",
                                }}
                                onClick={() => nextStep()}
                            >
                                {continueBtn}
                            </span>
                        ) : (
                            <DefaultButton className={"defaultBtn"} onClick={() => nextStep()}>
                                Continue
                            </DefaultButton>
                        )
                    ) : submitBtn ? (
                        <></>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </StepperDiv>
    )
}

export default StepperComponent
