const pages = {
    default: {
        title: "AI-Powered Analytics for Website Conversions",
        description: "AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    partners: {
        title: "Flowpoint Affiliate Program.",
        description:
            "Earn up to 25% recurring revenue through our affiliate program | AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "cookie-notice": {
        title: "Flowpoint Cookie Notice",
        description:
            "Flowpoint cookie notice explains you how we use cookies and how we allow our customers to manage their cookies efficiently and safely.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "data-analytics": {
        title: "AI-Powered Analytics for Website Conversions",
        description: "AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "data-protection": {
        title: "Data Protection Agreement",
        description: "Flowpoint's Data Protection Agreement explains you how we handle data privacy and how we process data.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    faq: {
        title: "Flowpoint | Frequently Aked Questions",
        description:
            "Here are the most common questions and answers our customers have asked us. Feel free to contact us on the live chat if you have further queries.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "gdpr-statement": {
        title: "GDPR Statement for Clients | Flowpoint",
        description:
            "We value your privacy and your customers' privacy therefore we are fully commited to protect you on both legal and technical grounds. Read more about it here.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "ecommerce-case-study": {
        title: "How Flowpoint identified and resolved technical and UX issues for an eCommerce business | Flowpoint",
        description: "AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "custom-event-tracking": {
        title: "How Flowpoint's custom event tracking improved user retention during the onboarding process for a B2C web application",
        description: "AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    "cross-domain-tracking": {
        title: "How Flowpoint's cross-domain tracking capabilities improved conversion tracking and decision-making for a service provider",
        description: "AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    changelog: {
        title: "Changelog | Flowpoint",
        description: "Flowpoint's latest features, updates, improvements, and bug fixes.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    pricing: {
        title: "Pricing Plans for Flowpoint | AI-Powered Analytics for Website Conversions",
        description: "AI-powered website intelligence, optimization, and conversion tracking in one place.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    privacy: {
        title: "Flowpoint | Privacy Policy",
        description:
            "We value your privacy and your customers' privacy therefore we are fully commited to protect you on both legal and technical grounds. Read more about it here.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    terms: {
        title: "Flowpoint | Terms and Conditions",
        description: "Read more about Flowpoint's terms of use and don't hesitate to reach out to our team to discuss about anything.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    gptWebsiteAudit: {
        title: "Website Audit (UI/UX & Content) | AI Recommendations | ChatGPT GPT",
        description:
            "Get UI/UX and content recommendations and optimise your website conversion rates. Enter your website URL to begin your audit.",
        thumbnail: "https://flowpoint.ai/images/thumbnail_gpt_website_audit.jpg",
    },
    gptCodeRefactoring: {
        title: "Code Refactoring | Programming Assistant | ChatGPT GPT",
        description: "Paste code in any language and receive the refactored version immediately, aligned with industry best practices.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    gptBlogGenerator: {
        title: "Competitor SEO Blog Writer | ChatGPT GPT",
        description:
            "Enter the URL of a rival website to immediately create SEO-optimized articles that can compete for the same keywords.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
    gptWebsiteAudit2: {
        title: "Audit Your Website Free In Under 2 mins with AI",
        description:
            "Boost your website with a FREE UX & Content Audit! Just click below, submit your URL to ChatGPT Website Audit GPT, and get comprehensive insights in 2 minutes. ",
        thumbnail: "https://flowpoint.ai/images/thumbnail_gpt_website_audit.jpg",
    },
    gptSqlAssistant: {
        title: "Convert database inquiries from natural language to SQL queries and vice versa.",
        description:
            "Describe in plain natural language what you would like to retrieve from your relational database and automatically turn your thoughts into a SQL query.",
        thumbnail: "https://flowpoint.ai/images/thumbnail_gpt_website_audit.jpg",
    },
    gptAIToolRecommender: {
        title: "AI Tool Recommender",
        description:
            "Find the best AI tool for your needs from a database of over 20.000 tools. Get the best options for you in just 5 seconds.",
        thumbnail: "https://flowpoint.ai/images/thumbnail.jpeg",
    },
}

const seoText = (page) => {
    try {
        if (!page) {
            return pages["default"]
        } else return pages[page] ?? pages["default"]
    } catch (err) {
        return pages["default"]
    }
}

export default seoText
