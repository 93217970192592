import React from "react"
import { getBezierPath, getEdgeCenter, getSmoothStepPath } from "react-flow-renderer"
import styled from "styled-components"
import PropTypes from "prop-types"

import constants from "../../../utils/constants"
import ArrowMarker from "../Markers/ArrowMarker"
import { getHighlightStyle, getHighlightedLabelStyle, getEdgeLabelWidth } from "./utils"


const StyledButton = styled.button`
    background-color: ${constants.edgeColors.orange};
    box-shadow: 0px 4px 4px rgba(145, 158, 171, 0.1);
    border: none;
    cursor: pointer;
    border-radius: 8px,
    width: ${getEdgeLabelWidth()};
    padding: 0px 10px;
    height: 16px;
    font-weight: bold;
    color: white;
    display: initial;
    border-radius:8px;
`

const foreignObjectSize = getEdgeLabelWidth()

export default function BounceWarningEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data }) {
    const { isStepEdges, hasMarkerPadding, hasStartPadding } = data
    const edgePath = isStepEdges
        ? getSmoothStepPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })
        : getBezierPath({
            sourceX: hasStartPadding == false ? sourceX - 20 : sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })

    const padding = hasMarkerPadding ? "10" : "3"
    const percentage = parseFloat(data.percentage)
    return (
        <>
            {/* <ArrowMarker refX={padding} id={`arrow_bounce_warning_${padding}`} orient='auto' color={constants.edgeColors.orange} /> */}

            <path
                id={id}
                style={getHighlightStyle({ color: constants.edgeColors.orange, isHighlighted: data.isHighlighted, percentage })}
                className='react-flow__edge-path'
                d={edgePath}
                markerEnd={`url(#arrow_bounce_warning_${padding})`}
            />
            <foreignObject
                width={foreignObjectSize}
                height={25}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 5}
                className='edgebutton-foreignobject'
                requiredExtensions='http://www.w3.org/1999/xhtml'
            >
                {data.isHighlighted && (<StyledButton isHighlighted={data.isHighlighted} percentage={data.percentage} className='edgebutton' style={getHighlightedLabelStyle({ isHighlighted: data.isHighlighted })}>
                    {percentage < 1 ? '<1%' : `${percentage}%`}
                </StyledButton>)}
            </foreignObject>
        </>
    )
}

BounceWarningEdge.propTypes = {
    id: PropTypes.string,
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    style: PropTypes.shape({}),
    markerEndId: PropTypes.string,
    data: PropTypes.shape({
        isHighlighted: PropTypes.bool,
        percentage: PropTypes.string,
        isStepEdges: PropTypes.bool,
        hasMarkerPadding: PropTypes.bool,
        hasStartPadding: PropTypes.bool,
        isRelatedToEdge: PropTypes.bool,
        noStats: PropTypes.bool,
        index: PropTypes.number,
    }),
}
