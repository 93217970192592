import React from "react"
import { getBezierPath, getEdgeCenter, getSmoothStepPath } from "react-flow-renderer"
import styled from "styled-components"
import PropTypes from "prop-types"

import constants from "../../../utils/constants"
import ArrowMarker from "../Markers/ArrowMarker"
import { getHighlightStyle, getHighlightedLabelStyle, getEdgeLabelWidth } from "./utils"

const StyledButton = styled.button`
    background-color: ${constants.edgeColors.green};
    box-shadow: 0px 4px 4px rgba(145, 158, 171, 0.1);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    width: ${getEdgeLabelWidth()};
    padding: 0px 10px;
    height: 16px;
    font-weight: bold;
    color: white;
    display: initial;
    border-radius:8px;
`

const foreignObjectSize = getEdgeLabelWidth()

export default function FlowEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data }) {
    const { isStepEdges } = data
    const edgePath = isStepEdges
        ? getSmoothStepPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })
        : getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })
    const percentage = parseFloat(data.percentage)
    return (
        <>
            {/* <ArrowMarker refX='10' id='arrow_direct' orient='auto' color={constants.edgeColors.green} /> */}
            <path
                id={id}
                style={getHighlightStyle({ color: constants.edgeColors.green, isHighlighted: data.isHighlighted, percentage })}
                className='react-flow__edge-path'
                d={edgePath}
                markerEnd={"url(#arrow_direct)"}
            />
            <foreignObject
                width={foreignObjectSize}
                height={25}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 5}
                className='edgebutton-foreignobject'
                requiredExtensions='http://www.w3.org/1999/xhtml'
            >
                {data.percentage && (
                    <StyledButton isHighlighted={data.isHighlighted} percentage={data.percentage} className='edgebutton' style={getHighlightedLabelStyle({ isHighlighted: data.isHighlighted })}>
                        {percentage < 1 ? '<1%' : `${percentage}%`}
                    </StyledButton>
                )}
            </foreignObject>
        </>
    )
}

FlowEdge.propTypes = {
    id: PropTypes.string,
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    style: PropTypes.shape({}),
    data: PropTypes.shape({
        isHighlighted: PropTypes.bool,
        percentage: PropTypes.string,
        isStepEdges: PropTypes.bool,
    }),
}
