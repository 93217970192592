import React from "react"
import PropTypes from "prop-types"

const ArrowMarker = ({ id, color, orient = "auto", refX }) => (
    <marker id={id} viewBox='0 0 10 10' refX={refX} refY='5' markerUnits='strokeWidth' markerWidth='6' markerHeight='6' orient={orient}>
        <path d='M 0 0 L 10 5 L 0 10 z' fill={color} />
    </marker>
)

ArrowMarker.propTypes = {
    id: PropTypes.string,
    color: PropTypes.string,
    orient: PropTypes.string,
    refX: PropTypes.string,
}
export default ArrowMarker
