import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Popover, Badge } from "@mui/material"
import getNodesDetails from "../../../utils/flowHelpers/getNodesDetails"
import constants from "../../../utils/constants"
import { IconButtonAnimate } from "../../animate"
import Iconify from '../../Iconify';


import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  Collapse,
  TableCell as DefaultTableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton
} from '@mui/material';
// redux

// utils
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Image from '../../../components/Image';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import {
  ProductMoreMenu,
  ProductListHead,
  ProductListToolbar,
} from '../../../sections/@dashboard/e-commerce/product-list';

const TABLE_HEAD = [

    {id:"empty1"},
    { id: 'name', label: 'Insight', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'empty2' },
  ];





const IssuePopoverContainer = styled.div`
    width: 450px;
    background: #ffffff;
    border: ${props => `1px solid ${props.color}`};
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
    padding: 29px 27px;
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }
    
`
const StyledTableRow = styled(TableRow)`
    border-radius:8px;
    background-color: white !important;
    height:50px;
    
`

const StyledPopover = styled(Popover)`

    .MuiPopover-paper {
        
        border-radius: 16px;
        margin-top: 10px;
        box-shadow: 0px 12px 24px rgba(145, 158, 171, 0.25);
        opacity:1 !important;
      
    }
    
   
`
const IssueName = styled.h1`
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    margin-right:5px;
    word-wrap: break-word;
    margin-bottom: 10px;
`

const IssueDetailsContainer = styled.div`
    overflow-y: scroll;
   
    margin-bottom: 10px;
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }
`
const IssueDetails = styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 10px;
    word-wrap: break-word;
`
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const SimpleButton = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
`

const InsightsContainer = styled.div`
height:170px;
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}
`

const StyledBadge = styled(Badge)`
    .MuiBadge-overlapRectangular {
        background-color: ${(props) => props.customcolor} !important;
        right: -25px;
        bottom: 0px;
        top: auto;
    }
`

const TitleContainer = styled.div`
  display:flex;
  flex-direction:row;
`

const CloseButtonContainer = styled.div`
position:absolute;
top:10px;
right:10px;
`
const TechnicalIssuePopover = ({  open, handleClose, click, clickedElementData: { data, id } }) => {

    const [isExpanded, setExpanded] = useState(false)
    const [selected, setSelected] = useState([]);
    const [isRowExpanded, setRowExpanded] = useState(null)

    const type = (data?.class && getNodesDetails[data?.class][data?.type]?.name) || data?.type

    const insights = data?.insights || [];

    const subtype =
        (data?.class && data?.subtype && getNodesDetails[data?.class][data?.type]?.subtype[data?.subtype]?.name) || data?.subtype

    const color = data?.isWarning ? constants.edgeColors.yellow : constants.edgeColors.red
    const emptyRows = 0;
    const isNotFound = false;

    const handleSelectAllClick = (checked) => {
        if (checked) {
          const selected = insights.map((n) => n.name);
          setSelected(selected);
          return;
        }
        setSelected([]);
      };
    const handleClick = (index) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
      };

    return (
        <StyledPopover
            key={`popover${id}`}
            open={open}
            anchorEl={click}
            onClose={(event) => {
                if (handleClose) {
                    handleClose(event)
                }
                setExpanded(false)
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <IssuePopoverContainer isExpanded={isExpanded} id={`popover${id}`} key={`popover${id}`}>
                <CloseButtonContainer>
                    <IconButtonAnimate onClick={handleClose}>
                            <Iconify icon={'eva:close-fill'} width={20} height={20} />
                        </IconButtonAnimate>
                  </CloseButtonContainer>
                  <TitleContainer>
                  <IssueName>
                        {type} {subtype}
                    </IssueName>
                <StyledBadge badgeContent={data?.occurences} color='primary' customcolor={color} max={9999999}>
                 
                </StyledBadge>
                </TitleContainer>
                <IssueDetailsContainer>
                    <IssueDetails>{data?.message}</IssueDetails>
                    <IssueDetails>{data?.stack}</IssueDetails>
                    </IssueDetailsContainer>
                    <InsightsContainer>
                    <Scrollbar >
          
          </Scrollbar>
          </InsightsContainer>
               

                <ButtonContainer>
                
                  {/* <IconButtonAnimate onClick={() => console.log("sharing this")}>
                            <Iconify icon={'carbon:share'} width={20} height={20} />
                        </IconButtonAnimate> */}
                
                    {/* {trimmedStack?.length > STACK_THRESHOLD && (<SimpleButton onClick={() => setExpanded(!isExpanded)}>
                        {isExpanded ? "Less info" : "More info"}
                    </SimpleButton>)} */}
                </ButtonContainer>
            </IssuePopoverContainer>
        </StyledPopover>
    )
}
TechnicalIssuePopover.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    click: PropTypes.shape({}),
    data: PropTypes.shape({
        type: PropTypes.string,
        subtype: PropTypes.string,
    }),
    clickedElementData: PropTypes.shape({
        data: PropTypes.shape({
            stack: PropTypes.string,
            message: PropTypes.string,
            type: PropTypes.string,
            subtype: PropTypes.string,
            class: PropTypes.string,
            occurences: PropTypes.number,
        }),
    }),
}
export default TechnicalIssuePopover
