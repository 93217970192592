import { capitalCase } from 'change-case';
import { m, motion } from 'framer-motion';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
    Box,
    Stack,
    Radio,
    Tooltip,
    Container,
    Typography,
    RadioGroup,
    CardActionArea,
    FormControlLabel,
    Button,
} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Image from '../../components/Image';
import styledComponent from 'styled-components';
import { MotionViewport, varFade } from '../../components/animate';
import Flow from '../../components/flow/Flow'



// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(15, 0),
    },
}));

// ----------------------------------------------------------------------

export default function HomeColorPresets({ id }) {
    const { themeColorPresets, onChangeColor, colorOption } = useSettings();

    return (
        <RootStyle id="howitworks" >
            <Container component={MotionViewport} sx={{ position: 'relative', textAlign: 'center' }} >
                <m.div variants={varFade().inUp}>
                    <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
                        We use
                    </Typography>
                </m.div>

                <m.div variants={varFade().inUp}>
                    <Typography variant="h2" sx={{ mb: 3 }}>
                  Actionable insights to drive revenue
                    </Typography>
                </m.div>

                <m.div variants={varFade().inUp} >
                    <Typography
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'text.secondary' : 'text.primary',
                        }}
                    >
                       Flowpoint uses AI to tell you what to fix on your website to supercharge your conversions and drive more revenue.
                    </Typography>
                </m.div>
                <Box sx={{ position: 'relative' }} >

                    <Flow flowId={"fded5d8f8f19bc57775adc1552aaacd7c70deebd0a8a075cb0d43eeadcbd2c4f"} />
                </Box>

            </Container>

        </RootStyle>
    );
}
//fded5d8f8f19bc57775adc1552aaacd7c70deebd0a8a075cb0d43eeadcbd2c4f

//726aed57cb53c375182bb80823e004fa7af828c38d770c249867ee66858682cb