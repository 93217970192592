// ChatMessageItem.js
import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import { Avatar, Box, Typography } from "@mui/material"
import Iconify from "src/components/Iconify"
import { useState, useEffect } from "react"

const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(3),
}))

const ContentStyle = styled("div")(({ theme }) => ({
    display: "inline-block",
    borderRadius: theme.shape.borderRadius,
}))

const InfoStyle = styled(Typography)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
}))

const MessageImgStyle = styled("img")(({ theme }) => ({
    height: 200,
    minWidth: 296,
    width: "100%",
    cursor: "pointer",
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
}))

ChatMessageItem.propTypes = {
    message: PropTypes.object,
    conversation: PropTypes.object,
    isMe: PropTypes.bool,
}
ChatMessageItem.defaultProps = {
    isTyping: false,
}

export default function ChatMessageItem({ message, conversation, isMe, isTyping }) {
    if (isTyping) {
        // If isTyping is true, show typing indicator for user1
        return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Iconify
                    icon='eos-icons:three-dots-loading'
                    style={{ width: "50px", height: "50px", display: "flex", alignContent: "flex-start" }}
                />
                <Typography variant='subtitle2'>Typing...</Typography>
            </Box>
        )
    }
    if (!message) return null
    return (
        <RootStyle>
            <Box sx={{ display: "flex" }}>
                {isMe ? (
                    <Avatar
                        alt='Ava'
                        src=''
                        sx={{
                            width: 27,
                            height: 27,
                            mr: { md: 2, xs: 0.2 },
                            display: "flex",
                            justifyContent: "center",
                            justifyItems: "center",
                            alignContent: "center",
                            alignItems: "center",
                            background: "#00150D",
                            color: "white",
                        }}
                    >
                        <Iconify icon={"uil:robot"} style={{ height: "20px", width: "20px", marginBottom: "2px" }} />
                    </Avatar>
                ) : (
                    <Avatar
                        alt='You'
                        src=''
                        sx={{
                            width: 27,
                            height: 27,
                            mr: { md: 2, xs: 0.2 },
                            background: "#EAF2E6",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#9CC29B",
                        }}
                    ></Avatar>
                )}

                <Box sx={{ ml: 2 }}>
                    <InfoStyle
                        variant='subtitle1'
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                        }}
                    >
                        {isMe ? "Ava" : "You"}
                        <Typography variant='caption'> </Typography>
                    </InfoStyle>

                    <ContentStyle>
                        {isMe ? (
                            <Typography variant='body2' sx={{ fontWeight: 500 }}>
                                {message.body}
                            </Typography>
                        ) : (
                            <Typography variant='body2' sx={{ fontWeight: 500 }}>
                                {message.body}
                            </Typography>
                        )}
                    </ContentStyle>
                </Box>
            </Box>
        </RootStyle>
    )
}
