import { m } from "framer-motion"
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles"
import { Box, Card, Container, Typography } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import Iconify from "../../components/Iconify"
// ----------------------------------------------------------------------

const CARDS = [
    {
        icon: "uil:analytics",
        title: "Funnel Analytics",

        description: "Track where users come from and where they go on your website.",
    },
    {
        icon: "material-symbols:temp-preferences-custom",
        title: "AI-powered recommendations",
        description:
            "Flowpoint leverages artificial intelligence to provide actionable insights for improving website conversion rates, SEO, UX/UI, and content engagement.",
    },
    {
        icon: "ic:twotone-insights",
        title: "Conversion rate optimisation",
        description: "Discover data-driven recommendations to boost your sales, new registrations or other calls to action.",
    },
]

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`

const RootStyle = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(10),
    },
}))

const CardStyle = styled(Card)(({ theme }) => {
    const shadowCard = (opacity) =>
        theme.palette.mode === "light" ? alpha(theme.palette.grey[500], opacity) : alpha(theme.palette.common.black, opacity)

    return {
        border: "1px solid rgb(211, 217, 218)",
        maxWidth: 380,
        borderRadius: "12px",
        minHeight: 440,
        margin: "auto",
        textAlign: "left",
        padding: theme.spacing(5, 5, 2),
        boxShadow: "none",
        //boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up("md")]: {
            boxShadow: "none",
            //   backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        "&.cardLeft": {
            boxShadow: "none",
            //  boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            "&:before": {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                content: "''",
                margin: "auto",
                position: "absolute",
                width: "calc(100% - 40px)",
                height: "calc(100% - 40px)",
                borderRadius: Number(theme.shape.borderRadius) * 2,
            },
        },
        "&.cardCenter": {
            [theme.breakpoints.up("md")]: {
                marginTop: -40,
                // backgroundColor: theme.palette.background.paper,
                // boxShadow: "rgba(145, 158, 171, 0.16) -40px 40px 80px",
                //  boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                "&:before": {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    content: "''",
                    margin: "auto",
                    position: "absolute",
                    width: "calc(100% - 40px)",
                    height: "calc(100% - 40px)",
                    borderRadius: Number(theme.shape.borderRadius) * 2,
                    //   backgroundColor: theme.palette.background.paper,
                    // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
                },
            },
        },
        "&.cardRight": {
            boxShadow: "none",
            "&:before": {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                content: "''",
                margin: "auto",
                position: "absolute",
                width: "calc(100% - 40px)",
                height: "calc(100% - 40px)",
                borderRadius: Number(theme.shape.borderRadius) * 2,
            },
        },
    }
})

// ----------------------------------------------------------------------

export default function HomeMinimal() {
    const theme = useTheme()

    const isLight = theme.palette.mode === "light"

    return (
        <RootStyle>
            <Container component={MotionViewport}>
                <Box
                    sx={{
                        textAlign: "center",
                        mb: { xs: 10, md: 18 },
                    }}
                >
                    <m.div variants={varFade().inUp}>
                        <Typography component='div' variant='overline' sx={{ mb: 2, color: "#505668" }}>
                            Grow
                        </Typography>
                    </m.div>
                    <m.div variants={varFade().inDown}>
                        <Typography variant='h2' sx={{ fontFamily: "Poppins, sans-serif" }} gutterBottom>
                            Turn data into meaningful actions
                        </Typography>
                    </m.div>
                </Box>

                <Box
                    sx={{
                        display: "grid",
                        gap: { xs: 2, lg: 7 },
                        gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(3, 1fr)" },
                    }}
                >
                    <m.div variants={varFade().inUp}>
                        <CardStyle className={"cardLeft"}>
                            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins, sans-serif",
                                        color: "white",
                                        background: "#00140C",
                                        padding: "1px",
                                        width: "90px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        fontWeight: "500",
                                        lineHeight: 1.5,
                                        fontSize: "1rem",
                                    }}
                                    variant='body1'
                                >
                                    Content
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        fontFamily: "Poppins, sans-serif",
                                        color: "#41C606",
                                        flexDirection: "row",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "1px",
                                        borderRadius: "5px",

                                        textAlign: "center",
                                    }}
                                >
                                    <Iconify
                                        icon='fluent:arrow-up-12-filled'
                                        color='#41C606'
                                        style={{ marginRight: "3px", height: "20px" }}
                                    />{" "}
                                    10.37%
                                </Typography>
                            </Box>

                            <Typography variant='h5' paragraph sx={{ fontFamily: "Poppins, sans-serif", mt: 2 }}>
                                Update "Faq" Call To Action copy
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    mb: 2,
                                    //  fontSize: { xs: "15px", md: "18px" },
                                }}
                            >
                                <b>Findings:</b> Of 2,050 users, 220 (10.73%) clicked the "Faq" call to action. Enhance button appeal to
                                improve conversion.
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    mb: 2,
                                    //  fontSize: { xs: "15px", md: "18px" },
                                }}
                            >
                                <b>Recommendation:</b> Replace "Faq" with "Help Center" to suggest a more interactive, supportive space for
                                users to find answers to specific questions and issues.
                            </Typography>
                        </CardStyle>
                    </m.div>
                    <m.div variants={varFade().inUp}>
                        <CardStyle className={"cardCenter"}>
                            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        fontFamily: "Poppins, sans-serif",
                                        color: "white",
                                        fontWeight: 500,
                                        background: "#00140C",
                                        padding: "1px",
                                        width: "90px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    UX/UI
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        fontFamily: "Poppins, sans-serif",
                                        color: "#41C606",
                                        flexDirection: "row",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "1px",
                                        borderRadius: "5px",

                                        textAlign: "center",
                                    }}
                                >
                                    <Iconify
                                        icon='fluent:arrow-up-12-filled'
                                        color='#41C606'
                                        style={{ marginRight: "3px", height: "20px" }}
                                    />{" "}
                                    4.38%
                                </Typography>
                            </Box>

                            <Typography variant='h5' paragraph sx={{ fontFamily: "Poppins, sans-serif", mt: 2 }}>
                                Fix low contrast text
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    mb: 2,
                                    // fontSize: { xs: "15px", md: "18px" },
                                }}
                            >
                                <b>Findings:</b> Low-contrast text affects readability on: https://flowpoint.ai/pricing/
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    // mb: 2,
                                }}
                            >
                                Examples:
                            </Typography>
                            <ul style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                <Typography
                                    component='li'
                                    // variant="body2"
                                    sx={{
                                        // color: "text.secondary",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 500,
                                        // lineHeight: "1.778rem",
                                        // fontSize: { xs: "15px", md: "18px" },
                                        "&::marker": {
                                            color: "blue",
                                        },
                                    }}
                                    // gutterBottom
                                >
                                    CRO technical insights
                                </Typography>
                                <Typography
                                    component='li'
                                    // variant="body2"
                                    sx={{
                                        // color: "text.secondary",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 500,
                                        // lineHeight: "1.778rem",
                                        // fontSize: { xs: "15px", md: "18px" },
                                        "&::marker": {
                                            color: "blue",
                                        },
                                    }}
                                    // gutterBottom
                                >
                                    CRO UX/Ul insights
                                </Typography>
                                <Typography
                                    component='li'
                                    // variant="body1"
                                    sx={{
                                        // color: "text.secondary",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 500,
                                        // lineHeight: "1.778rem",
                                        // fontSize: { xs: "15px", md: "18px" },
                                        "&::marker": {
                                            color: "blue",
                                        },
                                    }}
                                    gutterBottom
                                >
                                    Issue event explorer
                                </Typography>
                            </ul>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    mb: 2,
                                    //  fontSize: { xs: "15px", md: "18px" },
                                }}
                            >
                                <b>Recommendations:</b> Fix contrast issues for the mentioned texts. Benefits Improving contrast may boost
                                conversions by up to 2.17% in the entire flow.
                            </Typography>
                        </CardStyle>
                    </m.div>
                    <m.div variants={varFade().inUp}>
                        <CardStyle className={"cardRight"}>
                            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        fontFamily: "Poppins, sans-serif",
                                        color: "white",
                                        fontWeight: 500,
                                        // backgroundImage:
                                        //   "radial-gradient(circle, rgba(0,62,166,1) 0%, rgba(0,124,199,1) 50%, rgba(0,176,255,1) 100%)",
                                        background: "#00140C",
                                        padding: "1px",
                                        width: "90px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    Technical
                                </Typography>
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        fontFamily: "Poppins, sans-serif",
                                        color: "#41C606",
                                        flexDirection: "row",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "1px",
                                        borderRadius: "5px",

                                        textAlign: "center",
                                    }}
                                >
                                    <Iconify
                                        icon='fluent:arrow-up-12-filled'
                                        color='#41C606'
                                        style={{ marginRight: "3px", height: "20px" }}
                                    />{" "}
                                    8.34%
                                </Typography>
                            </Box>
                            <Typography variant='h5' paragraph sx={{ fontFamily: "Poppins, sans-serif", mt: 2 }}>
                                Fix Javascript error
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    mb: 2,
                                    // fontSize: { xs: "15px", md: "18px" },
                                }}
                            >
                                <b>Findings: </b> 0.05% of users face "Uncaught TypeError" error.
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: "500",
                                    mb: 2,
                                    //  fontSize: { xs: "15px", md: "18px" },
                                }}
                            >
                                <b>Recommendation:</b> Fix client-side issue to improve user experience. Benefits Resolving this may boost
                                conversions by 0.1% in the entire flow.
                            </Typography>
                        </CardStyle>
                    </m.div>
                </Box>
                {/* <m.div variants={varFade().inUp}>
                    <CardStyle className={"cardLeft"}>
                        <Typography
                            variant='subtitle2'
                            sx={{
                                fontFamily: "Poppins, sans-serif",
                                color: "white",
                                // background: "#FFFFFF",
                                background: "#2D4181",
                                padding: "1px",

                                width: "120px",
                                height: "25px",
                                borderRadius: "5px",
                                textAlign: "center",
                                margin: "5px",
                            }}
                        >
                            Expert Audit
                        </Typography>
                        <Typography variant='subtitle2' sx={{ fontFamily: "Poppins, sans-serif", ml: 2 }}>
                            Get a pro-exclusive website audit from our experts.
                        </Typography>
                    </CardStyle>
                </m.div> */}
            </Container>
        </RootStyle>
    )
}
