import { m } from "framer-motion"
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles"
import { Box, Card, Container, Typography } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import Iconify from "../../components/Iconify"
// ----------------------------------------------------------------------

const CARDS = [
    {
        icon: "uil:analytics",
        title: "Funnel Analytics",
        description: "Track where users come from and where they go on your website. ",
    },
    {
        icon: "material-symbols:temp-preferences-custom",
        title: "AI-powered recommendations",
        description:
            "Flowpoint leverages artificial intelligence to provide actionable insights for improving website conversion rates, SEO, UX/UI, and content engagement.",
    },
    {
        icon: "ic:twotone-insights",
        title: "Conversion rate optimisation",
        description: "Discover data-driven recommendations to boost your sales, new registrations or other calls to action.",
    },
]

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`

const RootStyle = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(15),
    [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(15),
    },
}))

const CardStyle = styled(Card)(({ theme }) => {
    const shadowCard = (opacity) =>
        theme.palette.mode === "light" ? alpha(theme.palette.grey[500], opacity) : alpha(theme.palette.common.black, opacity)

    return {
        maxWidth: 380,
        height: 440,
        margin: "auto",
        textAlign: "left",
        padding: theme.spacing(10, 5, 0),
        boxShadow: "none",
        //  background: "#F1F2F2",
        borderRadius: "12px",
        border: "1px solid rgb(211, 217, 218)",
        //     boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up("xs")]: {
            minHeight: "fit-content",
            paddingBottom: "50px",
            paddingTop: "50px",
            //   boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        },
    }
})

// ----------------------------------------------------------------------

export default function HomeMinimal() {
    const theme = useTheme()

    const isLight = theme.palette.mode === "light"

    return (
        <RootStyle>
            <Container component={MotionViewport}>
                <Box
                    sx={{
                        textAlign: "center",
                        mb: { xs: 10, md: 18 },
                    }}
                >
                    <m.div variants={varFade().inUp}>
                        <Typography component='div' variant='overline' sx={{ mb: 2, color: "#505668" }}>
                            Goals
                        </Typography>
                    </m.div>
                    <m.div variants={varFade().inDown}>
                        <Typography variant='h2' sx={{ fontFamily: "Poppins, sans-serif" }}>
                            Analyze, Improve and Grow
                        </Typography>
                    </m.div>
                </Box>

                <Box
                    sx={{
                        display: "grid",
                        gap: { xs: 5, lg: 5 },
                        gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(3, 1fr)" },
                    }}
                >
                    {CARDS.map((card, index) => (
                        <m.div variants={varFade().inUp} key={card.title}>
                            <CardStyle>
                                <Iconify
                                    icon={card.icon}
                                    alt={card.title}
                                    sx={{
                                        marginBottom: "30px",
                                        color: index === 0 ? "#41C606" : index === 1 ? "red" : index === 2 ? "#5DBAFB" : "blue",
                                        marginX: "auto",
                                        width: 40,
                                        height: 40,
                                    }}
                                />

                                <Typography variant='h4' paragraph sx={{ fontFamily: "Poppins, sans-serif" }}>
                                    {card.title}
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    sx={{
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: "500",
                                        //  fontSize: { xs: "15px", md: "18px" },
                                        color: "#485752",
                                    }}
                                >
                                    {card.description}
                                </Typography>
                            </CardStyle>
                        </m.div>
                    ))}
                </Box>
            </Container>
        </RootStyle>
    )
}
