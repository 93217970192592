import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Handle from "../Handle"
import constants from "../../../utils/constants"

const customNodeStyles = {
    borderColor: "#000",
    color: "#000",
}

const arrowRight = {
    width: 0,
    height: 0,
    borderTop: "22px solid transparent",
    borderBottom: "22px solid transparent",
    borderLeft: "22px solid lightgrey",
}
const arrowLeft = {
    width: 0,
    height: 0,
    borderTop: "22px solid transparent",
    borderBottom: "22px solid transparent",
    borderRight: "22px solid lightgrey",
}

const circle = {
    width: 20,
    height: 20,
    background: "lightgrey",
    borderRadius: "50%",
}

const NodeWrapper = styled.div``

function renderLeftNodes(data) {
    const { hasIssues, hasDirect, hasOnlyErrors, nodePosition } = data

    if (hasOnlyErrors) {
        return (
            <Handle
                type={constants.handleMethod.target}
                position={constants.handlePosition.left}
                id={constants.handleType.upper}
                top='50%'
                color={constants.edgeColors.red}
            />
        )
    }
    if (hasIssues && hasDirect) {
        if (nodePosition === 0 || (data.nodePosition === 1 && data.parents.length === 1)) {
            return (
                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.left}
                    id={constants.handleType.bottom}
                    color={constants.edgeColors.green}
                    top='50%'
                />
            )
        }

        return (
            <>
                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.left}
                    id={constants.handleType.upper}
                    top='30%'
                    color={constants.edgeColors.yellow}
                />
                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.left}
                    id={constants.handleType.bottom}
                    color={constants.edgeColors.green}
                    top='70%'
                />
            </>
        )
    }
    if (hasIssues && !hasDirect) {
        if (data.nodePosition === 0) {
            return (
                <Handle
                    type={constants.handleMethod.target}
                    position={constants.handlePosition.left}
                    id={constants.handleType.upper}
                    top='50%'
                    color={constants.edgeColors.green}
                />
            )
        }
        return (
            <Handle
                type={constants.handleMethod.target}
                position={constants.handlePosition.left}
                id={constants.handleType.upper}
                top='50%'
                color={constants.edgeColors.yellow}
            />
        )
    }
    if (hasDirect && !hasIssues) {
        return (
            <Handle
                type={constants.handleMethod.target}
                position={constants.handlePosition.left}
                id={constants.handleType.bottom}
                top='50%'
                color={constants.edgeColors.green}
            />
        )
    }
}

function renderRightNodes(data) {
    const { hasIssues, hasDirect, nodePosition } = data

    if (hasIssues && hasDirect) {
        if (nodePosition === 0) {
            return (
                <>
                    <Handle
                        type={constants.handleMethod.source}
                        position={constants.handlePosition.right}
                        id={constants.handleType.upper}
                        top='30%'
                        color={constants.edgeColors.red}
                    />
                    <Handle
                        type={constants.handleMethod.source}
                        position={constants.handlePosition.right}
                        id={constants.handleType.bottom}
                        top='70%'
                        color={constants.edgeColors.green}
                    />
                </>
            )
        }
        if (data.nodePosition === 1 && data.parents.length === 1) {
            return (
                <>
                    <Handle
                        type={constants.handleMethod.source}
                        position={constants.handlePosition.right}
                        id={constants.handleType.upper}
                        top='50%'
                        color={constants.edgeColors.green}
                    />
                </>
            )
        }
        return (
            <>
                <Handle
                    type={constants.handleMethod.source}
                    position={constants.handlePosition.right}
                    id={constants.handleType.upper}
                    top='50%'
                    color={constants.edgeColors.green}
                />
            </>
        )
    }
    if (hasIssues && !hasDirect) {
        if (data.nodePosition === 0) {
            return (
                <Handle
                    type={constants.handleMethod.source}
                    position={constants.handlePosition.right}
                    id={constants.handleType.upper}
                    top='50%'
                    color={constants.edgeColors.red}
                />
            )
        }
        return (
            <Handle
                type={constants.handleMethod.source}
                position={constants.handlePosition.right}
                id={constants.handleType.upper}
                top='50%'
                color={constants.edgeColors.yellow}
            />
        )
    }
    if (hasDirect && !hasIssues) {
        return (
            <Handle
                type={constants.handleMethod.source}
                position={constants.handlePosition.right}
                id={constants.handleType.bottom}
                top='50%'
                color={constants.edgeColors.green}
            />
        )
    }
}
const DummyNode = ({ data, id }) => {
    let nodeStyle = data.index === 0 ? arrowLeft : arrowRight
    if (
        (!data.hasIssues && data.hasDirect) ||
        (data.hasIssues && !data.hasDirect) ||
        (data.nodePosition === 1 && data.parents.length === 1)
    ) {
        nodeStyle = circle
    }
    return (
        <NodeWrapper style={{ ...customNodeStyles, ...nodeStyle }} isHighlighted={data.isHighlighted} id={id}>
            {renderLeftNodes(data)}
            {renderRightNodes(data)}
        </NodeWrapper>
    )
}

DummyNode.propTypes = {
    data: PropTypes.shape({
        index: PropTypes.number,
        hasIssues: PropTypes.bool,
        isHighlighted: PropTypes.bool,
        hasDirect: PropTypes.bool,
    }),
}

DummyNode.defaultProps = {
    data: {
        index: 0,
        hasIssues: false,
        isHighlighted: false,
    },
}

export default DummyNode
