import { m } from "framer-motion"
import NextLink from "next/link"
import React, { useState } from "react"

// @mui
import { styled } from "@mui/material/styles"
import { Button, Box, Link, Container, Typography, Stack, Grid } from "@mui/material"

// components
import Image from "next/image"
import Iconify from "../../components/Iconify"

import { MotionContainer, varFade } from "../../components/animate"

import { openPopupWidget, InlineWidget } from "react-calendly"
import styledComponent from "styled-components"
import { gtag } from "../../utils/analytics"
import { useRouter } from "next/router"
import useResponsive from "src/hooks/useResponsive"
// ----------------------------------------------------------------------

const TextContainer = styledComponent.div`
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 16px;
  text-align:center;
  
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  // background-color: rgb(12,12,13, 0.8);

  `
const RootStyle = styled(m.div)(({ theme, isFeatures }) => ({
    position: "relative",
    //backgroundColor: "#140648", //theme.palette.grey[400],

    backgroundImage: "linear-gradient(to right bottom, #00150D, #091E16, #1E372B)",

    [theme.breakpoints.up("xs")]: {
        width: "100%",
        // height: isFeatures ? "1100px" : "1000px",
        // height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}))

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
    zIndex: 10,
    maxWidth: 1120,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    display: "flex",

    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "50px",

    [theme.breakpoints.up("md")]: {
        margin: "unset",
        textAlign: "left",
        paddingTop: "50px",
        paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.up("xs")]: {
        margin: "unset",
        textAlign: "left",
        paddingTop: "0px",
    },
}))

const logos = [
    {
        href: "https://www.producthunt.com/posts/flowpoint?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flowpoint",
        alt: "Flowpoint - Boost your website conversion rates with AI | Product Hunt",
        src: "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400018&theme=light",
        height: 140,
        width: 550,
    },
    {
        href: "https://www.futurepedia.io/tool/flowpoint?utm_source=flowpoint_embed",
        alt: "Futurepedia",
        src: "https://www.futurepedia.io/api/image-widget?toolId=baaed3aa-2a16-43ee-a918-ce090246f2aa",
        height: 58,
        width: 200,
    },

    {
        href: "https://theresanaiforthat.com/ai/flowpoint/?ref=featured&v=36463",
        alt: "theresanaiforthat",
        src: "https://media.theresanaiforthat.com/featured4.png",
        height: 55,
        width: 224,
    },

    {
        href: "https://www.capterra.com/reviews/282505/Flowpoint?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge",
        alt: "capterra",
        src: "/images/top-ai-tools.webp",
        height: 52,
        width: 220,
    },
    // Add more logos here in the same format
]
const LogoGridItem = ({ logo }) => (
    <Grid
        item
        xs={4}
        sm={3}
        md={3}
        sx={{
            justifyContent: "center",
            display: "flex",
        }}
    >
        <Link
            href={logo.href}
            target='_blank'
            aria-label={logo.alt}
            rel='noreferrer'
            sx={{
                display: "flex",
            }}
        >
            <img src={logo.src} alt={logo.alt} />
            {/* <Image
                alt={logo.alt}
                height={logo.height}
                width={logo.width}
                src={logo.src}
                style={{
                    borderRadius: logo.borderRadius || "2px",
                    background: logo.alt === "theresanaiforthat" ? "white" : "#ffffff00",
                    zIndex: 3,
                }}
            /> */}
        </Link>
    </Grid>
)

export const LogoSection = () => (
    <Box sx={{ flexGrow: 1, py: 4, textAlign: "center" }}>
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    borderBottom: 1.2,
                    flexGrow: 0.5,
                    mr: 2,
                    borderColor: "#092540",
                }}
            />
            <Typography variant='h3' component='h2' sx={{ fontFamily: "Poppins, sans-serif", color: "#092540" }} gutterBottom>
                Your peers think Flowpoint rocks
            </Typography>
            <Box
                sx={{
                    borderBottom: 1.2,
                    flexGrow: 0.5,
                    ml: 2,
                    borderColor: "#092540",
                }}
            />
        </Box>

        <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{
                height: 1,
                mt: 2,
                display: "flex",

                borderRadius: "2px",
            }}
        >
            {logos.map((logo, index) => (
                <LogoGridItem key={index} logo={logo} />
            ))}
        </Grid>
    </Box>
)
// ----------------------------------------------------------------------

export default function AiList() {
    return (
        <MotionContainer>
            <Container>
                <ContentStyle>
                    <m.div
                        style={{
                            zIndex: 9,
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        <LogoSection />
                    </m.div>
                </ContentStyle>
            </Container>
        </MotionContainer>
    )
}
