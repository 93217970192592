import { m } from "framer-motion"
import NextLink from "next/link"
import React, { useState } from "react"
import dynamic from "next/dynamic"
// @mui
import { styled } from "@mui/material/styles"
import { Button, Box, Link, Container, Typography, Stack, Grid } from "@mui/material"
// routes
import { PATH_DASHBOARD } from "../../routes/paths"
// components
import Image from "next/image"
import Iconify from "../../components/Iconify"
import TextIconLabel from "../../components/TextIconLabel"
import { MotionContainer, varFade } from "../../components/animate"
import Flow from "../../components/flow/Flow"
const Particles = dynamic(() => import("react-tsparticles"))
import particlesConfig from "./particlesConfig"
import NewsBanner from "../../components/NewsBanner"
import { openPopupWidget, InlineWidget } from "react-calendly"
import styledComponent from "styled-components"
import { gtag } from "../../utils/analytics"
import { useRouter } from "next/router"
import useResponsive from "src/hooks/useResponsive"
import Logo from "src/components/Logo"
// ----------------------------------------------------------------------

const TextContainer = styledComponent.div`
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 16px;
  text-align:center;
  
  display: flex;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  // background-color: rgb(12,12,13, 0.8);

  `
const RootStyle = styled(m.div)(({ theme, isFeatures }) => ({
    position: "relative",
    //backgroundColor: "#140648", //theme.palette.grey[400],

    backgroundImage: "linear-gradient(to right bottom, #00150D, #091E16, #1E372B)",

    [theme.breakpoints.up("xs")]: {
        width: "100%",
        // height: isFeatures ? "1100px" : "1000px",
        // height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}))

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
    zIndex: 10,
    maxWidth: 1120,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    display: "flex",

    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "50px",

    [theme.breakpoints.up("md")]: {
        margin: "unset",
        textAlign: "left",
        paddingTop: "50px",
        paddingBottom: theme.spacing(15),
    },
    [theme.breakpoints.up("xs")]: {
        margin: "unset",
        textAlign: "left",
        paddingTop: "0px",
    },
}))

const HeroImgStyle = styled(m.div)(({ theme }) => ({
    zIndex: 8,
    height: "auto",
    width: "90%",
    margin: "auto",

    [theme.breakpoints.up("xs")]: {
        marginBottom: 20,
        borderRadius: 8,
        width: "95%",
        // marginBottom: -80,
    },
    [theme.breakpoints.up("sm")]: {
        // marginBottom: -150,
        borderRadius: 8,
        width: "90%",
    },
    [theme.breakpoints.up("md")]: {
        // marginBottom: -350,
        borderRadius: 8,
        width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
        width: "80%",
        height: "auto",
        // marginBottom: -420,
        borderRadius: 12,
    },
    [theme.breakpoints.up("xl")]: {
        maxWidth: "1512px",
        minWidth: "auto",
        height: "auto",
        // marginBottom: -650,
        borderRadius: 12,
    },
}))

const StyledButton = styled(Button)`
    color: white;
    border: 1px solid white;
    font-size: 18px;
    :hover {
        background: white;
        color: #181a22;
    }
`
const logos = [
    {
        href: "https://www.producthunt.com/posts/flowpoint?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flowpoint",
        alt: "Flowpoint - Boost your website conversion rates with AI | Product Hunt",
        src: "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400018&theme=light",
        height: 170,
        width: 650,
        borderRadius: "8px",
    },
    {
        href: "https://www.futurepedia.io/tool/flowpoint?utm_source=flowpoint_embed",
        alt: "Flowpoint | Featured on Futurepedia",
        src: "https://www.futurepedia.io/api/image-widget?toolId=baaed3aa-2a16-43ee-a918-ce090246f2aa",
        height: 68,
        width: 266,
        borderRadius: "6px",
    },

    {
        href: "https://theresanaiforthat.com/ai/flowpoint/?ref=featured&v=36463",
        alt: "theresanaiforthat",
        src: "https://media.theresanaiforthat.com/featured4.png",
        height: 45,
        width: 174,
        borderRadius: "2px",
    },

    {
        href: "https://www.capterra.com/reviews/282505/Flowpoint?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge",
        alt: "capterra",
        src: "/images/top-ai-tools.webp",
        height: 43,
        width: 170,
        borderRadius: "2px",
    },
    // Add more logos here in the same format
]
const LogoGridItem = ({ logo }) => (
    <Grid item xs={4} sm={3} md={2} sx={{ justifyContent: "center", display: "flex" }}>
        <Link href={logo.href} target='_blank' aria-label={logo.alt} rel='noreferrer' sx={{ display: "flex" }}>
            <img src={logo?.src} alt={logo?.alt} style={{ height: "auto", background: "white", borderRadius: logo.borderRadius }} />
            {/* <Image
                alt={logo.alt}
                height={logo.height}
                width={logo.width}
                src={logo.src}
                style={{
                    padding: "10px",
                    borderRadius: logo.borderRadius || "8px",
                    background: "#fff",
                    minWidth: "fit-content!important",
                    maxWidth: "fit-content!important",
                }}
            /> */}
        </Link>
    </Grid>
)

export const LogoSection = () => (
    <Box sx={{ flexGrow: 1, py: 4, textAlign: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ borderBottom: 1.2, flexGrow: 0.5, mr: 2, borderColor: "#fff" }} />
            <Typography variant='subtitle1' component='div' gutterBottom sx={{ color: "#fff" }}>
                TRUSTED BY
            </Typography>
            <Box sx={{ borderBottom: 1.2, flexGrow: 0.5, ml: 2, borderColor: "#fff" }} />
        </Box>

        <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{
                height: 1,
                mt: 2,
                display: "flex",

                borderRadius: "8px",
            }}
        >
            {logos.map((logo, index) => (
                <LogoGridItem key={index} logo={logo} />
            ))}
        </Grid>
    </Box>
)
// ----------------------------------------------------------------------

export default function HomeHero({ title, subtitle, highlightedTitle }) {
    const [loading, setLoading] = useState(true)

    const { pathname } = useRouter()

    const isFeatures = pathname.startsWith("/features/")

    const handleLoad = () => {
        setLoading(false)
    }
    const onClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "TalkToSalesButton")
        }
        gtag_report_conversion()
    }

    function gtag_report_conversion() {
        gtag("event", "conversion", {
            send_to: "AW-11014059734/aCWdCKC1tJ0YENbt9IMp",
        })
        return false
    }
    const isDesktop = useResponsive("up", "sm")
    return (
        <MotionContainer>
            <RootStyle isFeatures={isFeatures}>
                <Container>
                    <ContentStyle>
                        <TextContainer>
                            <Box sx={{ mt: 17 }}>
                                {" "}
                                {/* <Logo hasTitle={true} isOffset={"false"} isOffsetHeader={"false"} titleColor={"white"} /> */}
                            </Box>
                            <m.div style={{ zIndex: 9, marginTop: "80px" }}>
                                <Typography
                                    component={isDesktop ? "h2" : "h2"}
                                    variant={isDesktop ? "h2" : "h2"}
                                    sx={{
                                        color: "#fff",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    {title}
                                    <Typography
                                        component={isDesktop ? "h2" : "h2"}
                                        variant={isDesktop ? "h2" : "h2"}
                                        sx={{
                                            fontFamily: "Poppins, sans-serif",
                                            color: "#fff",
                                        }}
                                    >
                                        {highlightedTitle}
                                    </Typography>
                                </Typography>
                            </m.div>

                            <m.div style={{ zIndex: 9, maxWidth: "700px" }}>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: "white",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: 500,
                                        lineHeight: "1.778rem",
                                        fontSize: { xs: "15px", md: "18px" },
                                        mt: 2.5,
                                    }}
                                    gutterBottom
                                >
                                    {subtitle}
                                </Typography>
                            </m.div>
                        </TextContainer>
                        <m.div
                            style={{
                                zIndex: 9,
                                display: "flex",
                                textAlign: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <NextLink
                                href='https://dashboard.flowpoint.ai/auth/register?membershipPlan=starter'
                                target='_blank'
                                rel='noopener'
                            >
                                <StyledButton
                                    size='large'
                                    variant='contained'
                                    target='_blank'
                                    onClick={() => {
                                        if (typeof window !== "undefined" && window.fbq) {
                                            window.fbq("track", "GetStartedButton")
                                        }
                                    }}
                                    endIcon={<Iconify icon={"bi:arrow-right"} width={20} height={20} />}
                                    sx={{
                                        margin: "5px",
                                        background: "#9CC29A",
                                        borderColor: "#9CC29A",
                                        boxShadow: "none",
                                        color: "white",
                                        borderRadius: "40px",
                                        fontSize: "14px",
                                        "&:hover": {
                                            borderColor: "#fff",
                                            background: "#122920",
                                            color: "white",
                                        },
                                        // fontFamily: "Poppins, sans-serif",
                                    }}
                                >
                                    TRY IT FREE
                                </StyledButton>
                            </NextLink>
                        </m.div>

                        {/* <m.div
                            style={{
                                zIndex: 9,
                                display: "flex",
                                textAlign: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <LogoSection />
                        </m.div> */}
                    </ContentStyle>
                </Container>
                <HeroImgStyle
                    sx={{
                        borderRadius: "12px",
                        width: "auto",
                        height: "auto",
                        boxShadow: { sm: "0px 40px 80px 0 rgb(145 158 171 / 40%)" },
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            paddingBottom: "calc(52.37268518518518% + 41px)",
                            height: 0,
                        }}
                    >
                        <iframe
                            src='https://demo.arcade.software/sbtsezNFgzfmb2uWN8zV?embed'
                            frameBorder='0'
                            loading='lazy'
                            onLoad={handleLoad}
                            webkitallowfullscreen='true'
                            mozallowfullscreen='true'
                            allowFullScreen
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                            title='Explore Flowpoint'
                        ></iframe>
                    </div>
                </HeroImgStyle>
            </RootStyle>
            {/* <Box sx={{ height: { sm: "100px", md: "60vh" } }}></Box> */}
        </MotionContainer>
    )
}
