import { m } from "framer-motion";
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, Grid, Button, Container, Typography } from "@mui/material";
// components
// import Image from "../../components/Image";
import { MotionViewport, varFade } from "../../components/animate";
import styledComponent from "styled-components";
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";
import Image from "next/image";

// ----------------------------------------------------------------------
const ImpactContainer = styledComponent.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
`;
const TypoContainer = styledComponent.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 20px;
`;
const IconWrapper = styledComponent.div`
    display: flex;
    border-radius: 50%;
    background-color: #dff3ea;
    width: 24px;
    height: 24px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;
const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(15, 0),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(24),
    paddingBottom: theme.spacing(15),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    marginBottom: 0,
  },
}));

const ScreenStyle = styled(m.div)(({ theme }) => ({
  paddingRight: 2,
  paddingBottom: 1,
  maxWidth: 160,
  height: "auto",
  borderRadius: 8,
  backgroundColor:
    theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  [theme.breakpoints.up("sm")]: {
    maxWidth: 320,
    paddingRight: 4,
    borderRadius: 12,
  },
  "& img": {
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
      borderRadius: 8,
      height: "auto",
    },
  },
}));

const COMMON = {
  scaleX: 0.86,
  skewY: 8,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  opacity: 0,
};

const variantScreenLeft = {
  initial: COMMON,
  animate: { ...COMMON, translateX: "-50%", translateY: 40, opacity: 1 },
};

const variantScreenCenter = {
  initial: COMMON,
  animate: { ...COMMON, opacity: 1 },
};

const variantScreenRight = {
  initial: COMMON,
  animate: { ...COMMON, translateX: "50%", translateY: -40, opacity: 1 },
};

// ----------------------------------------------------------------------

export default function HomeHugePackElements() {
  const theme = useTheme();

  const isLight = theme.palette.mode === "light";

  const isRTL = theme.direction === "rtl";

  const screenLeftAnimate = variantScreenLeft;

  const screenCenterAnimate = variantScreenCenter;

  const screenRightAnimate = variantScreenRight;

  return (
    <MotionViewport disableAnimatedMobile={false}>
      <RootStyle>
        <Container>
          <Grid container spacing={5} justifyContent="flex-start">
            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ContentStyle>
                <m.div variants={varFade().inUp}>
                  <Typography
                    component="div"
                    variant="overline"
                    sx={{ mb: 2, color: "#505668" }}
                  >
                    ACTION
                  </Typography>
                </m.div>

                <m.div variants={varFade().inUp}>
                  <Typography variant="h2" sx={{ mb: 5 }}>
                  AI suggestions for conversion problems
                  </Typography>
                </m.div>
                <m.div variants={varFade().inUp}>
                  <Typography
                    sx={{
                      mb: 5,
                      color: "text.secondary",
                    }}
                  >
                    Improve your website's conversion rates and optimise SEO
                    with AI generated improvement recommendations.
                  </Typography>
                </m.div>
                <m.div variants={varFade().inUp}>
                  <ImpactContainer>
                    <div style={{ width: "25px" }}>
                      <IconWrapper>
                        <Iconify
                          icon={"ant-design:rise-outlined"}
                          color="#36b37e"
                          width={14}
                          height={14}
                        />
                      </IconWrapper>{" "}
                    </div>
                    <TypoContainer>
                      <Typography variant="subtitle1" sx={{ mb: 0.3 }}>
                        Technical Insights
                      </Typography>
                      <Typography
                        sx={{
                          mb: 3,
                          color: "text.secondary",
                        }}
                      >
                        Have technical errors that
                        are impacting conversion rates.
                      </Typography>
                    </TypoContainer>
                  </ImpactContainer>

                  <ImpactContainer>
                    <div style={{ width: "25px" }}>
                      <IconWrapper>
                        <Iconify
                          icon={"ant-design:rise-outlined"}
                          color="#36b37e"
                          width={14}
                          height={14}
                        />
                      </IconWrapper>{" "}
                    </div>
                    <TypoContainer>
                      <Typography variant="subtitle1" sx={{ mb: 0.3 }}>
                        UX/UI Insights
                      </Typography>
                      <Typography
                        sx={{
                          mb: 3,
                          color: "text.secondary",
                        }}
                      >
                        Where bad UX is driving your
                        customers away.
                      </Typography>
                    </TypoContainer>
                  </ImpactContainer>
                  <ImpactContainer>
                    <div style={{ width: "25px" }}>
                      <IconWrapper>
                        <Iconify
                          icon={"ant-design:rise-outlined"}
                          color="#36b37e"
                          width={14}
                          height={14}
                        />
                      </IconWrapper>{" "}
                    </div>
                    <TypoContainer>
                      <Typography variant="subtitle1" sx={{ mb: 0.3 }}>
                        Content Insights
                      </Typography>
                      <Typography
                        sx={{
                          mb: 3,
                          color: "text.secondary",
                        }}
                      >
                       highlighting areas where improvements can be made
                      </Typography>
                    </TypoContainer>
                  </ImpactContainer>
                </m.div>
              </ContentStyle>
            </Grid>

            <Grid item xs={12} md={7} 
            sx={{
                  display: "flex",
                  alignItems: "center",
                  // position: "relative",
                  justifyContent: "center",
                }}
                >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                {/* {[...Array(3)].map((_, index) => (
                  <ScreenStyle
                    key={index}
                    variants={{
                      ...(index === 0 && screenLeftAnimate),
                      ...(index === 1 && screenCenterAnimate),
                      ...(index === 2 && screenRightAnimate),
                    }}
                    transition={{ duration: 0.72, ease: "easeOut" }}
                    sx={{
                      boxShadow: `${isRTL ? -80 : 80}px -40px 80px ${alpha(
                        isLight
                          ? theme.palette.grey[600]
                          : theme.palette.common.black,
                        0.48
                      )}`,
                      ...(index === 0 && {
                        zIndex: 3,
                        position: "absolute",
                      }),
                      ...(index === 1 && { zIndex: 2 }),
                      ...(index === 2 && {
                        zIndex: 1,
                        position: "absolute",
                        boxShadow: "none",
                      }),
                    }}
                  > */}
                    <Image
                      height={750}
                      width={640}
                      style={{
                        width: "auto",
                        height: "auto",
                      }}
                      src='/images/use-cases/recommendation.png'
                      // disabledEffect
                      // alt={`screen ${index + 1}`}
                      // src={`/images/screen_${isLight ? "light" : "dark"}_${
                      //   index + 1
                      // }.webp`}
                    />
                  {/* </ScreenStyle>
                ))} */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </MotionViewport>
  );
}
