import { useState } from "react"
import { m } from "framer-motion"
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Box, Container, Typography, useTheme, Grid, Card } from "@mui/material"
// components
// import Image from "../../components/Image";
import Image from "next/image"
import { MotionViewport, varFade } from "../../components/animate"
import styledComponent from "styled-components"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// ----------------------------------------------------------------------

const IssueContainer = styledComponent.div`
  position: relative;
  margin-top: 40px!important;
  margin-bottom: 40px!important;
  display: flex;
  // height: 320px;
  // width: 320px;
  margin: 15px;
  border-radius: 12px;
  // background-color: #F4F7FE;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  //box-shadow: 2px 2px 50px 2px #e4e9f4;
  img{
    width: auto;
    // box-shadow: 2px 2px 50px 2px #e4e9f4;
    border-radius: 12px;
  }
  span{
    border-radius: 12px;
    // box-shadow: 2px 2px 50px 2px #e4e9f4;
  }
  @media only screen and (max-width: 768px) {
  margin-left:0px;
    margin-top:10px;
  }
  @media only screen and (max-width: 1000px) {
    width: auto;
   
  }
`

const Typo = styled("div")(({ theme }) => ({
    textAlign: "center",
    // paddingLeft: "15px",
    // paddingRight: "15px",
    // paddingTop: '5px',
    // paddingBottom: '5px',
    // mb: "5px!important",
    // background: "#122920",
    color: "blue",

    display: "flex",
    justifyContent: "center",
    justifyItems: "center",

    // border: '1px solid grey'
}))

const RootStyle = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(15, 0),
    },
}))

const ContentStyle = styled("div")(({ theme }) => ({
    //   maxWidth: 720,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
        zIndex: 11,
        textAlign: "center",
        position: "relative",
    },
}))

// ----------------------------------------------------------------------

export default function HomeCleanInterfaces() {
    const theme = useTheme()
    const isLight = theme.palette.mode === "light"
    const [isButtonHovered, setButtonHovered] = useState(false)
    const notify = (index) =>
        toast.error("🦄 Strange error!", {
            position: "top-right",
            toastId: `toast${index}`,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

    setInterval(() => notify(1), 5100)
    setInterval(() => notify(2), 2500)

    return (
        <RootStyle>
            <Container
                component={MotionViewport}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <ContentStyle sx={{ mb: { xs: 1, md: 10 } }}>
                    <m.div variants={varFade().inUp}>
                        <Typography
                            variant='h2'
                            paragraph
                            sx={{
                                ...(!isLight && {
                                    textShadow: (theme) => `4px 4px 16px ${alpha(theme.palette.grey[800], 0.48)}`,
                                }),
                            }}
                        >
                            How it works
                        </Typography>
                    </m.div>
                </ContentStyle>
                <Grid container spacing={6}>
                    <Grid md={4} xs={12} item sx={{ marginTop: { md: 1, xs: 5 } }}>
                        <IssueContainer>
                            <Image alt='how-it-works' height={50} width={50} src='/images/works/howitworks1.webp' />
                        </IssueContainer>
                        <Box
                            sx={{
                                marginTop: "20px",
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{
                                    marginTop: "15px",
                                    mb: 1,

                                    textAlign: { md: "center", xs: "center" },
                                }}
                            >
                                Automatically
                            </Typography>
                            <Typography
                                // variant="subtitle2"
                                sx={{
                                    mb: 3,
                                    //
                                    color: "text.secondary",
                                    textAlign: { md: "center", xs: "center" },
                                }}
                            >
                                Easily capture user actions on your website, such as clicks, scrolls, and navigations.s.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        md={4}
                        xs={12}
                        item
                        // sx={{
                        //   marginTop: { md: 1, xs: 5 },
                        //   boxShadow: "2px 2px 50px 2px #e4e9f4",

                        // }}
                    >
                        <Box
                            sx={{
                                marginTop: { md: 1, xs: 5 },
                                boxShadow: "2px 2px 50px 2px #e4e9f4",
                            }}
                        >
                            <IssueContainer>
                                <Image alt='how-it-works' priority height={70} width={70} src='/images/works/howitworks2.webp' />
                            </IssueContainer>
                            <Box
                                sx={{
                                    marginTop: "20px",
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    sx={{
                                        marginTop: "15px",
                                        textAlign: { md: "center", xs: "center" },
                                        mb: 1,
                                    }}
                                >
                                    Easily see how
                                </Typography>
                                <Typography
                                    sx={{
                                        mb: 3,
                                        color: "text.secondary",
                                        textAlign: { md: "center", xs: "center" },
                                    }}
                                >
                                    Visualize user behavior data at scale to identify trends and patterns in how visitors interact..
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12} item sx={{ marginTop: { md: 1, xs: 5 } }}>
                        <IssueContainer>
                            <Image alt='how-it-works' height={70} width={70} src='/images/works/howitworks3.webp' />
                        </IssueContainer>
                        <Box
                            sx={{
                                marginTop: "20px",
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{
                                    marginTop: "15px",
                                    textAlign: { md: "center", xs: "center" },
                                    mb: 1,
                                }}
                            >
                                Get personalized
                            </Typography>
                            <Typography
                                sx={{
                                    mb: 3,
                                    color: "text.secondary",
                                    textAlign: { md: "center", xs: "center" },
                                }}
                            >
                                Utilize artificial intelligence to analyze user behavior and receive actionable recommendations.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    )
}
