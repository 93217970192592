export default {
    behaviour: {
        focus_lost: {
            name: "Tab switching",
            description: "Users which have been switching tabs during a user flow",
        },
        page_close: {
            name: "Page close",
            description: "Users which closed the page during a flow. ",
        },
        page_reload: {
            name: "Page reload",
            description: "Users which refreshed the website during usage.",
        },
    },
    emotion: {
        confusion: {
            name: "Confusion",
            description: "Confucius",
        },

    },
    technical: {
        console: {
            name: "Console",
            subtype: {
                error: { name: "Error" },
            },
        },
    },
}
