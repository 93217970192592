import React from "react"
import { Box, Divider, Stack, Typography } from "@mui/material"

import ChatMessageList from "./ChatMessageList"

export default function ChatWindow({ conversation }) {
  return (
    <Stack sx={{ flex: 1 }}>
      <ChatMessageList conversation={conversation} />

      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      ></Stack>
    </Stack>
  )
}
