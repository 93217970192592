import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepContent from "@mui/material/StepContent"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"

const steps = [
    {
        label: "Data Sampling",
        description:
            "Our AI-driven system takes smart, small snapshots of your website's data to get an overview of what's happening without overwhelming resources.",
    },
    {
        label: "Data Validation",
        description: "We use AI to check that the data we collect is accurate and consistent, so you can trust the insights it provides.",
    },
    {
        label: "Privacy Compliance",
        description:
            "Our automated processes ensure we follow strict privacy rules, safeguarding user data and complying with regulations.",
    },
    {
        label: "Quality Assurance",
        description: "AI continuously checks data for errors and maintains its quality, ensuring you receive reliable insights.",
    },
    {
        label: "Redundancy Checks",
        description: "Our AI system identifies and removes duplicate data, streamlining information for better analysis.",
    },
    {
        label: "Accurate Data",
        description: "With AI-powered monitoring, your data remains up-to-date and precise, enabling you to make informed decisions.",
    },
]

export default function VerticalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    return (
        <Box sx={{ p: 1, overflow:'hidden' }}>
            <Stepper activeStep={activeStep} orientation='vertical'>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>
                            <Typography variant='subtitle1'> {step.label}</Typography>
                        </StepLabel>
                        <StepContent>
                            <Typography variant='body2' sx={{ mt: 2, mb: 2 }}>
                                {step.description}
                            </Typography>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                        Back
                                    </Button>
                                    <Button variant='contained' onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                        {index === steps.length - 1 ? "Finish" : "Continue"}
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Back
                    </Button>
                </Paper>
            )}
        </Box>
    )
}
