import { useState } from "react"
import { m } from "framer-motion"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Grid, Container, Typography, Tab, Tabs } from "@mui/material"
// components
import Image from "next/image"
import { MotionViewport, varFade } from "../../components/animate"
import styledComponent from "styled-components"
import * as React from "react"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import SwipeableViews from "react-swipeable-views"
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    padding: theme.spacing(4, 0),
    // backgroundColor: theme.palette.grey[900],
    // backgroundColor: "#F3F6FC",
    backgroundImage: "linear-gradient(to right bottom, #00150D, #091E16, #1E372B)",
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(15, 0),
    },
}))

const ContentStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    position: "relative",
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
        height: "100%",
        marginBottom: 0,
        textAlign: "left",
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignItems: "flex-start",
        maxWidth: "800px",
        marginTop: "0px!important",
    },
    [theme.breakpoints.up("xs")]: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
        textAlign: "left",
    },
}))

// ----------------------------------------------------------------------

export default function HomeDarkMode() {
    const [value, setValue] = React.useState("1")

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <RootStyle>
            <Container component={MotionViewport} sx={{ position: "relative" }}>
                <Grid container direction='row-reverse' justifyContent='center'>
                    <Grid item xs={12} md={12} style={{ textAlign: "center", alignItems: "center" }}>
                        <ContentStyle style={{ textAlign: "center" }}>
                            <m.div variants={varFade().inUp}>
                                <Typography component='div' variant='overline' sx={{ mb: 2, color: "#f7f7f7", textAlign: "center" }}>
                                    Discover
                                </Typography>
                            </m.div>
                            <m.div variants={varFade().inUp}>
                                <Typography variant='h2' sx={{ mb: { xs: 1, md: 3 }, color: "white" }}>
                                    Understand Users, Improve Conversions
                                </Typography>
                            </m.div>
                        </ContentStyle>
                    </Grid>
                </Grid>
                <Grid container spacing={1.5}>
                    <Grid md={12} xs={12} item>
                        <Box
                            sx={{
                                width: "100%",
                                typography: "body1",
                                marginTop: "30px",
                                maxHeight: "100%",
                                minHeight: { md: "800px", xs: "100%" },
                            }}
                        >
                            <TabContext value={value}>
                                <Box>
                                    <TabList
                                        onChange={handleChange}
                                        centered
                                        // variant="scrollable"
                                        // scrollButtons
                                        // allowScrollButtonsMobile
                                        sx={{
                                            backgroundColor: { md: "#F3F6FC" },
                                            borderRadius: { xs: "0px", md: "50px" },
                                            "& .MuiTabs-flexContainer": {
                                                // justifyContent: { xs: "flex-start", md: "center" },
                                                flexWrap: "wrap",
                                            },
                                        }}
                                        TabIndicatorProps={{
                                            sx: {
                                                backgroundColor: "red",
                                                height: "0px",
                                            },
                                        }}
                                    >
                                        <Tab
                                            label='Where do visitors go?'
                                            value='1'
                                            sx={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                margin: "5px",
                                                textAlign: "center",
                                                textDecoration: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                width: "290px !important",
                                                minWidth: "190px",
                                                borderRadius: "50px",
                                                background: "#EAF2E6",
                                                marginRight: "0px!important",
                                                "&.Mui-selected": {
                                                    width: "290px !important",
                                                    marginRight: "0px!important",
                                                    borderRadius: "50px",
                                                    minWidth: "190px",
                                                    // color: "white",
                                                    textAlign: "center",
                                                    margin: "5px",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    fontSize: "16px",
                                                },
                                            }}
                                        />
                                        <Tab
                                            label='Identifying issues at scale?'
                                            value='2'
                                            sx={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                margin: "5px",
                                                textAlign: "center",
                                                textDecoration: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                width: "290px !important",
                                                minWidth: "190px",
                                                borderRadius: "50px",
                                                background: "#EAF2E6",
                                                marginRight: "0px!important",
                                                "&.Mui-selected": {
                                                    width: "290px !important",
                                                    marginRight: "0px!important",
                                                    borderRadius: "50px",
                                                    minWidth: "190px",
                                                    // color: "white",
                                                    textAlign: "center",
                                                    margin: "5px",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    fontSize: "16px",
                                                },
                                            }}
                                        />
                                        <Tab
                                            label='What issues are impactful?'
                                            value='3'
                                            sx={{
                                                alignItems: "center",
                                                marginRight: "0px!important",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                padding: "5px",
                                                margin: "5px",
                                                textAlign: "center",
                                                textDecoration: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                width: "290px !important",
                                                minWidth: "190px",
                                                borderRadius: "50px",
                                                background: "#EAF2E6",
                                                "&.Mui-selected": {
                                                    padding: "5px",
                                                    width: "290px !important",
                                                    marginRight: "0px!important",
                                                    borderRadius: "50px",
                                                    minWidth: "190px",
                                                    // color: "white",
                                                    textAlign: "center",
                                                    margin: "5px",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    fontSize: "16px",
                                                },
                                            }}
                                        />
                                        <Tab
                                            label='What is the ROI?'
                                            value='4'
                                            sx={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignContent: "center",
                                                margin: "5px",
                                                textAlign: "center",
                                                textDecoration: "none",
                                                marginRight: "0px!important",
                                                display: "flex",
                                                fontSize: "16px",
                                                width: "290px !important",
                                                minWidth: "190px",
                                                borderRadius: "50px",
                                                background: "#EAF2E6",
                                                "&.Mui-selected": {
                                                    width: "290px !important",
                                                    marginRight: "0px!important",
                                                    borderRadius: "50px",
                                                    minWidth: "190px",
                                                    // color: "white",
                                                    textAlign: "center",
                                                    margin: "5px",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    fontSize: "16px",
                                                },
                                            }}
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value='1'>
                                    <Box
                                        sx={{
                                            marginTop: "30px",
                                            height: { md: "500px" },
                                            textAlign: { xs: "center", md: "center" },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                mb: 5,
                                                color: "white",
                                            }}
                                        >
                                            See a bird's-eye view of your entire website traffic and identify the most common user journeys.
                                        </Typography>
                                        <Image
                                            src='/images/features/1.webp'
                                            //priority={false}
                                            alt='Discover Flowpoint'
                                            height={715}
                                            width={1144}
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='2'>
                                    <Box
                                        sx={{
                                            marginTop: "30px",
                                            height: { md: "500px" },
                                            textAlign: { xs: "left", md: "center" },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                mb: 5,
                                                color: "white",
                                            }}
                                        >
                                            See a comprehensive overview of your visitors' activities and quickly identify their pain points
                                            on a large scale within just a few minutes.
                                        </Typography>
                                        <Image
                                            src='/images/features/2.webp'
                                            //priority={false}
                                            alt='Discover Flowpoint'
                                            height={715}
                                            width={1144}
                                            style={{
                                                borderRadius: "12px",
                                                width: "auto",
                                                height: "auto",
                                            }}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='3'>
                                    <Box
                                        sx={{
                                            marginTop: "30px",
                                            height: { md: "500px" },
                                            textAlign: { xs: "left", md: "center" },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                mb: 5,
                                                color: "white",
                                            }}
                                        >
                                            Prioritize the most impactful areas for conversion rate optimization to efficiently allocate
                                            resources and boost results.
                                        </Typography>
                                        <Image
                                            src='/images/features/3.webp'
                                            //priority={false}

                                            alt='Discover Flowpoint'
                                            height={715}
                                            width={1144}
                                            style={{
                                                borderRadius: "12px",
                                                width: "auto",
                                                height: "auto",
                                            }}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='4'>
                                    <Box
                                        sx={{
                                            marginTop: "30px",
                                            textAlign: { xs: "left", md: "center" },
                                            height: { md: "500px" },
                                            width: "100%",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                mb: 5,
                                                color: "white",
                                            }}
                                        >
                                            Easily measure the return on investment and make data-driven decisions that grow your business.
                                        </Typography>
                                        <Image
                                            src='/images/features/4.webp'
                                            //priority={false}
                                            alt='Discover Flowpoint'
                                            height={715}
                                            width={1144}
                                            style={{
                                                borderRadius: "12px",
                                                width: "auto",
                                                height: "auto",
                                            }}
                                        />
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </RootStyle>
    )
}
