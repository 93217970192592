import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupIcon from '@mui/icons-material/Group';
import CodeIcon from '@mui/icons-material/Code';
// components
import Image from '../../components/Image';
import { MotionViewport, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const CARDS = [
    {
        icon: CodeIcon,
        title: 'Customer conversion',
        image: "/images/snippet.jpg",
        description: 'Track how your users convert into paying customers and see the most crucial factors that can raise your metrics.',
    },
    {
        icon: GroupIcon,
        title: 'Customer satisfaction',
        image: "/images/flowrecord.webp",
        description: 'Are your users happy with your online product?',
    },
    {
        icon: GroupIcon,
        title: 'Customer experience',
        image: "/images/sessions.webp",
        description: 'Do your users have a good experience while using your product? See their biggest bottlenecks and take action.',
    },

];

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(15),
    },
}));

const CardStyle = styled(Card)(({ theme }) => {
    const shadowCard = (opacity) =>
        theme.palette.mode === 'light'
            ? alpha(theme.palette.grey[500], opacity)
            : alpha(theme.palette.common.black, opacity);

    return {
        border: 0,
        maxWidth: 350,
        minHeight: 130,

        margin: 'auto',
        textAlign: 'center',
        padding: theme.spacing(10, 5, 10),
        boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up('md')]: {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        '&.cardFirst': {
            [theme.breakpoints.up('md')]: { marginTop: -40 },
            backgroundColor: "#282c34",
        },
        '&.cardSecond': {
            [theme.breakpoints.up('md')]: { marginTop: -10 },

            backgroundColor: theme.palette.background.paper,
            boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
            '&:before': {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                content: "''",
                margin: 'auto',
                position: 'absolute',
                width: 'calc(100% - 40px)',
                // height: 'calc(100% - 40px)',
                borderRadius: Number(theme.shape.borderRadius) * 2,

                backgroundColor: theme.palette.background.paper,
                // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
            },
        },
        '&.cardThird': {
            [theme.breakpoints.up('md')]: { marginTop: -150 },
            backgroundColor: theme.palette.background.paper,
            boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
            '&:before': {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                content: "''",
                margin: 'auto',
                position: 'absolute',
                width: 'calc(100% - 40px)',
                // height: 'calc(100% - 40px)',
                borderRadius: Number(theme.shape.borderRadius) * 2,
                // backgroundColor: theme.palette.background.paper,
                // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
            },
        },
        '&.cardForth': {
            [theme.breakpoints.up('xs')]: {
                marginTop: 0,
                backgroundColor: "#0c0c0d",

                boxShadow: `-40px 40px 80px 0 alpha(#000, 0.4)`,
                '&:before': {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    content: "''",
                    margin: 'auto',
                    position: 'absolute',
                    width: 'calc(100% - 40px)',
                    // height: 'calc(100% - 40px)',

                    borderRadius: Number(theme.shape.borderRadius) * 2,
                    backgroundColor: "#0c0c0d",
                    // backgroundColor: theme.palette.background.paper,
                    // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
                },
            },
            [theme.breakpoints.up('md')]: {
                marginTop: -50
            }
        },
    };
});

// ----------------------------------------------------------------------

export default function HomeMinimal() {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    return (
        <RootStyle>
            <Container component={MotionViewport}>
                <Box
                    sx={{
                        textAlign: 'center',
                        mb: { xs: 10, md: 25 },
                    }}
                >
                    <m.div variants={varFade().inUp}>
                        <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
                            Bring your business to the next level
                        </Typography>
                    </m.div>
                    <m.div variants={varFade().inDown}>
                        <Typography variant="h2">Flowpoint at the heart of your business</Typography>
                    </m.div>
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gap: { xs: 1, lg: 1 },
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
                        minHeight: 400,
                    }}
                >
                    {CARDS.map((card, index) => (
                        <m.div variants={varFade().inUp} key={card.title}>
                            <CardStyle className={(index === 0 && 'cardFirst') || (index === 1 && 'cardSecond') || (index == 2 && 'cardThird') || "cardForth"} >
                                {/* <card.icon style={{ fontSize: 50 }} /> */}
                                {/* <Image
                                    src={card.image}
                                    alt={card.title}
                                    sx={{
                                        mb: 10,
                                        mx: 'auto',
                                        width: "100%",
                                        height: "auto",

                                    }}
                                /> */}
                                < Typography variant="h5" paragraph style={{ color: (index == 0 || index == 3) ? "white" : "initial" }}>
                                    {card.title}
                                </Typography>
                                <Typography sx={{ color: (index == 0 || index == 3) ? "white" : "initial" }}>{card.description}</Typography>
                            </CardStyle>
                        </m.div>
                    ))}
                </Box>
            </Container>
        </RootStyle>
    );
}
