import { m } from "framer-motion";
import React, { useState } from "react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import dynamic from "next/dynamic";
// @mui
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Link,
  Container,
  Typography,
  Stack,
  Grid,
  Card,
} from "@mui/material";
import { AnimateSharedLayout, motion } from "framer-motion";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
import { DASHBOARD_URL } from "../../config";
// components
import Image from "../../components/Image";
import Iconify from "../../components/Iconify";
import TextIconLabel from "../../components/TextIconLabel";
import { MotionContainer, varFade } from "../../components/animate";
import Flow from "../../components/flow/Flow";
import LogoSingle from "../../components/LogoSingle";
const Particles = dynamic(() => import("react-tsparticles"));
import particlesConfig from "./particlesConfig";

import styledComponent from "styled-components";
import { openPopupWidget, InlineWidget } from "react-calendly";
import { fDate } from "../../utils/formatTime";
import OnboardingForm from "../auth/onboarding/OnboardingForm";
// ----------------------------------------------------------------------

/*
backdrop-filter: ${props => props.isDemoStarted ? 'blur(0px)' : 'blur(3px)'};
background: linear-gradient(to top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 100%);
*/

const StyledButton = styled(Button)`
margin-left:25px !important;
@media (max-width: 768px) {
   margin-left:0px !important;
  },
`;

const TextContainer = styledComponent.div`
  flex-direction:column;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 16px;
  text-align:center;
  display:flex;
  margin-bottom:40px;
  @media (max-width: 768px) {
    margin-top:200px !important;
    text-align:center;
  },
  `;

const LogoContainer = styledComponent.div`
  width: 30%;
  display: flex;
  position:relative;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-top:0px;
    justify-content:center;
    width: 80%;
   },

  `;
const PartnerLogo = styledComponent.img`
  width: 100%;
  `;

const RootStyle = styled(m.div)(({ theme }) => ({
  position: "relative",
  background: "#fafafa", //theme.palette.grey[400],
  [theme.breakpoints.up("xs")]: {
    top: 0,
    left: 0,
    width: "100%",
    minHeight: "100vh",
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
  },
  //   [theme.breakpoints.up("md")]: {
  //     top: 0,
  //     left: 0,
  //     width: "100%",
  //     height: "100vh",
  //     display: "flex",
  //     position: "fixed",
  //     alignItems: "center",
  //   },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 1120,
    margin: "auto",
    textAlign: "center",
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "100%",
    transition: "height 1s",

    [theme.breakpoints.up("md")]: {
      margin: "unset",
      textAlign: "left",
    },
  })
);

const HeroImgStyle = styled(m.img)(({ theme }) => ({
  borderRadius: 15,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 8,

  width: "100%",
  margin: "auto",

  [theme.breakpoints.up("xs")]: {
    marginBottom: 20,
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    height: "auto",
  },
}));

const StyledCard = styledComponent(Card)`
padding: 30px;
@media (max-width: 768px) {
  margin-bottom:30px;
 };
 min-height: ${(props) => (props.issubmitted === "true" ? "150px" : "530px")};
 transition: min-height 1s !important;
 max-width: 100%;
 

`;

// ----------------------------------------------------------------------

export default function HomeHero({ partner, type = "events", id, name }) {
  const [isSubmitted, setSubmitted] = useState(false);

  const { pathname } = useRouter();
  const router = useRouter();
  const index = router?.query?.index;

  const getDashboardUrl = () => {
    const isEventUrl = router.pathname?.startsWith("/events");
    const isPartnerUrl = router.pathname?.startsWith("/partners");
    const isCustomDashboard = isEventUrl || isPartnerUrl;
    return isCustomDashboard
      ? `${DASHBOARD_URL}/auth/register?ref=${name}&type=${
          isEventUrl ? "events" : "partners"
        }&id=${index}`
      : DASHBOARD_URL;
  };

  return (
    <MotionContainer>
      <RootStyle
        sx={{ backgroundColor: partner?.theme?.backgroundColor || "#fafafa" }}
      >
        <Container>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextContainer>
                <m.div variants={varFade().inRight} style={{ zIndex: 9 }}>
                  {/* sx={{ color: partner?.theme?.color || "common.black" }} */}
                  <Typography
                    variant="h1"
                    sx={{ color: partner?.theme?.color || "common.black" }}
                  >
                    {partner?.discountTitle}
                  </Typography>
                </m.div>

                <m.div variants={varFade().inRight} style={{ zIndex: 9 }}>
                  <Typography
                    variant="h4"
                    sx={{ color: partner?.theme?.secondaryColor, marginTop: 2 }}
                  >
                    {partner?.description}
                  </Typography>
                </m.div>
              </TextContainer>
              <LogoContainer>
                <PartnerLogo src={partner?.logoUrl} />
              </LogoContainer>
              <m.div variants={varFade().inDown}>
                <Button
                  size="large"
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick = {() => {
                    if (typeof window !== "undefined" && window.fbq) {
                      window.fbq('track', 'GetStartedButton');
                    }
                  }}
                  href={getDashboardUrl()}
                  sx={{
                    whiteSpace: "nowrap",
                    // mb: {xs: 5},
                    boxShadow: (theme) => theme.customShadows.z8,
                    color: (theme) =>
                      theme.palette.getContrastText(theme.palette.common.white),
                    bgcolor: "common.white",
                    "&:hover": { bgcolor: "grey.300" },
                  }}
                >
                  get started now
                </Button>
              </m.div>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center', flexDirection:"colummn",justifyContent:"center" }}>
          <ContentStyle>
       
            {/* <m.div 
               layout 
               variants={varFade().inRight}
               style={{ zIndex: 9, justifyContent:"center", display:"flex",flexDirection:"column", alignItems:"center", maxWidth:'100%', height: '100%'}}>
                  <StyledCard issubmitted={isSubmitted?.toString()}><OnboardingForm partner={partner} name={name} type={type} id={id} setSuccess={(flag) => setSubmitted(flag)}/></StyledCard>
            </m.div> */}
    
          </ContentStyle>
          </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </MotionContainer>
  );
}