import { Divider, Box, Grid, Typography, Container, Button, Card, IconButton, Input, Stack, Avatar, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useState, useEffect, useRef } from "react"
import { ChatWindow } from "src/sections/@dashboard/chat"
import Iconify from "src/components/Iconify"
import Link from "next/link"

const IssueContainer = styled("div")(({ theme }) => ({
    maxHeight: "620px",
    height: "620px",

    paddingTop: "70px",
    paddingBottom: "10px",

    borderRadius: "20px",
    //  backgroundImage: "linear-gradient(113.61380240615335deg, #fff 5.273809523809524%, #eff8fd 97.17857142857142%)",
    boxShadow: "0 1.6px 3.25px rgba(0, 0, 0, 0.1), 0 0.81px 0.81px rgba(0, 0, 0, 0.06), 0 0 4.88px rgba(0, 0, 0, 0.1)",
}))

const exampleConversation = {
    id: "1",
    messages: [
        {
            id: "m132",
            senderId: "user2",
            body: "I've noticed a drop in conversions by 15% in the past month. Can you help me pinpoint the issue?",
        },
        {
            id: "m332",
            senderId: "user1",
            body: "Of course! I looked into the user session data and identified the campaign landing page has a high bounce rate of 65%. It appears that users are leaving the page without engaging further.",
        },
        { id: "m343", senderId: "user2", body: "Do we know what might be causing this high bounce rate?" },
        {
            id: "m344",
            senderId: "user1",
            body: "Yes, the average page load time is currently at 5 seconds, which is above the ideal 2-second threshold. Improving this could positively impact user retention. Also, the main call-to-action button only has a click-through rate of 1.2%, which is well below the industry average.",
        },
        {
            id: "m349",
            senderId: "user2",
            body: "What actions do you suggest we take?",
        },
        {
            id: "m345",
            senderId: "user1",
            body: "I recommend optimizing images and scripts to reduce the page load time by at least 50%. Also, make the call-to-action button more prominent and consider an A/B test with different messaging to increase the click-through rate. Lastly, adding customer success stories can boost your conversion rates by providing social proof.",
        },
    ],
}

const AvaSection = ({ issueContainerRef }) => {
    const [inputValue, setInputValue] = useState("")

    const handleChange = (event) => {
        setInputValue(event.target.value)
    }
    return (
        <Container
            sx={{
                display: "flex",
                justifyContent: "center",
                mt: { xs: 10, md: 1 },
            }}
        >
            <Grid
                container
                spacing={6}
                justifyContent='flex-start'
                alignItems='center'
                sx={{
                    marginTop: { md: "30px" },
                    flexWrap: { xs: "wrap-reverse", sm: "wrap" },
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Grid item xs={12} md={7}>
                    <IssueContainer
                        ref={issueContainerRef}
                        sx={{
                            position: "relative",
                            paddingLeft: { md: "40px", xs: 2 },
                            paddingRight: { md: "40px", xs: 2 },
                            margin: { md: "40px 15px", xs: 1 },
                        }}
                    >
                        <Box
                            sx={{
                                background: "#00150D",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                height: "50px",
                                borderRadius: "20px 20px 0px 0px",
                                width: "100%",
                                zIndex: 34,
                                py: 1,
                                px: 3,
                                display: "flex",
                                alignContent: "center",
                            }}
                        >
                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                <Stack direction='row' alignItems='center' justifyContent='center'>
                                    <Avatar
                                        alt='Ava'
                                        src=''
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            mr: 1.5,
                                            display: "flex",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            // background: " #9CC29A",
                                            background: "white",
                                            // color: "#00150D",
                                            color: "#00150D",
                                        }}
                                    >
                                        <Iconify icon={"uil:robot"} style={{ height: "20px", width: "20px", marginBottom: "2px" }} />
                                    </Avatar>

                                    <Typography variant='subtitle2' sx={{ color: "white" }}>
                                        Ava ⚡ ChatGPT
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                height: "87%",
                                overflow: "hidden",
                                width: "100%",
                                "&:hover": {
                                    overflow: "auto",
                                },
                            }}
                        >
                            <ChatWindow conversation={exampleConversation} issueContainerRef={issueContainerRef} />
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "flex-end",
                                justifyItems: "flex-end",
                                overflow: "hidden",
                                alignContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                                height: "80px",
                                flexDirection: "column",
                            }}
                        >
                            <Stack
                                sx={{
                                    justifyContent: "center",

                                    width: "100%",
                                    flexDirection: "row",
                                    height: "40px",
                                    display: "flex",
                                    position: "relative",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                    border: "1px solid #8e8ea0",
                                    borderRadius: "20px",

                                    "& .MuiInputBase-input.MuiInput-input": {
                                        fontFamily: "Inter, sans-serif",
                                        fontSize: "14px",
                                        padding: "0px!important",
                                    },
                                }}
                            >
                                <Input
                                    value={inputValue}
                                    onChange={handleChange}
                                    size='small'
                                    fullWidth
                                    disableUnderline
                                    placeholder='Just ask Ava...'
                                    sx={{ pl: 3 }}
                                />

                                {inputValue.length >= 2 ? (
                                    <Link href='/pricing' passHref>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                mx: 0.4,
                                                fontSize: "12px",
                                                width: { md: "230px", xs: "300px" },
                                                borderRadius: "30px",
                                                // backgroundColor: "#1563B1",
                                                // borderColor: "#1563B1",
                                                boxShadow: "none",
                                            }}
                                        >
                                            Start your free trial
                                        </Button>
                                    </Link>
                                ) : (
                                    <Box
                                        sx={{
                                            mx: 0.8,
                                            color: "rgba(145, 158, 171, 0.8)",
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            justifyItems: "center",
                                        }}
                                    >
                                        <Iconify icon='solar:square-arrow-up-bold' width={30} height={30} />
                                    </Box>
                                )}
                            </Stack>
                        </Box>
                    </IssueContainer>
                </Grid>

                <Grid item xs={12} md={5} sx={{ padding: { xs: 2 }, ml: { xs: 2, md: 0 } }}>
                    {/* <Typography
                        variant='subtitle1'
                        sx={{
                            fontFamily: "Poppins, sans-serif",
                            color: "red",
                        }}
                    >
                        New
                    </Typography> */}
                    <Typography variant='h3' component='h2' sx={{ fontFamily: "Poppins, sans-serif", mb: 3 }} gutterBottom>
                        Ava Powered by ChatGPT
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            color: "text.secondary",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 500,
                            lineHeight: "1.778rem",
                            // fontSize: { xs: "15px", md: "18px" },
                        }}
                        gutterBottom
                    >
                        Stuck in data purgatory? Ava's your AI oracle, ready to decode your Flowpoint insights and guide you to conversion
                        heaven.
                    </Typography>
                    <div>
                        <Button
                            variant='outlined'
                            sx={{
                                // background: "white",
                                // border: "1px solid #122920",
                                mt: 2,
                                // color: "#1E376C",
                                borderRadius: "40px",
                                "&:hover": {
                                    borderColor: "#122920",
                                    background: "#122920",
                                    color: "white",
                                },
                            }}
                            href='https://app.arcade.software/share/EnUaSlfJWFNqO2XCID5y'
                            target='_blank'
                        >
                            Demo Ava
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AvaSection
