import PropTypes from "prop-types"
import { useEffect, useState, useRef, useCallback } from "react"
import Scrollbar from "../../../components/Scrollbar"
import Iconify from "src/components/Iconify"
import ChatMessageItem from "./ChatMessageItem"
import { Avatar, Box, Typography } from "@mui/material"

ChatMessageList.propTypes = {
  conversation: PropTypes.object.isRequired,
}

export default function ChatMessageList({ conversation }) {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    if (currentMessageIndex < conversation.messages.length) {
      const message = conversation.messages[currentMessageIndex]
      const isUser1 = message.senderId === "user1"

      // If it's user1's turn to show a message, simulate typing
      if (isUser1) {
        setIsTyping(true) // Start showing "Typing..."

        const typingTimeout = setTimeout(() => {
          setIsTyping(false) // Stop showing "Typing..."
          // Proceed to display the message after the typing simulation
          setCurrentMessageIndex((prevIndex) => prevIndex + 1)
        }, 100) // Simulate typing for 2 seconds

        // Return a cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(typingTimeout)
      } else {
        // If it's user2's message, no typing simulation is needed
        setIsTyping(false)
        const messageTimeout = setTimeout(() => {
          setCurrentMessageIndex((prevIndex) => prevIndex + 1)
        }, 50) // Short delay before showing user2's message

        return () => clearTimeout(messageTimeout)
      }
    }
  }, [currentMessageIndex, conversation.messages])

  return (
    <Scrollbar>
      {conversation.messages.slice(0, currentMessageIndex).map((message) => (
        <ChatMessageItem key={message.id} message={message} conversation={conversation} isMe={message.senderId === "user1"} />
      ))}

      {/* {isTyping && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon='eos-icons:three-dots-loading'
            style={{ width: "50px", height: "50px", display: "flex", alignContent: "flex-start" }}
          />
          <Typography variant='subtitle2'>Generating your personalized response now...</Typography>
        </Box>
      )} */}
    </Scrollbar>
  )
}
