import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import NodeWrapper from "./NodeWrapper"
import constants from "../../../utils/constants"
import Handle from "../Handle"

const Container = styled.div`
    text-align: left;
    padding-top: 7px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 7px;
    max-height: 33px;
`

const NodeName = styled.p`
    font-size: 12px;
    color: ${(props) => (props.dest === constants.defaultNodeTypes.external ? "white" : "black")};
    font-weight: ${(props) => (props.dest === constants.defaultNodeTypes.external || props.dest === "Direct" ? "bold" : "normal")};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90px;
`

const customNodeStyles = {
    // boxShadow: "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
    borderRadius: "16px",
    background: "#fff",
    width: "auto",
    maxWidth: 120,
    height: 33,
}

const exitNodeStyle = {
    background: "#212B36",
    boxShadow: "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
}

function getNodeName(data) {
    if (data.dest === constants.defaultNodeTypes.external) {
        return "Exit"
    }
    return data.dest
}
function getNodeHandle({ isPreFlowNode, color }) {
    return isPreFlowNode ? (
        <Handle
            type={constants.handleMethod.source}
            position={constants.handlePosition.right}
            top='50%'
            color={constants.edgeColors.green}
        />
    ) : (
        <Handle type={constants.handleMethod.target} position={constants.handlePosition.left} top='50%' color={color} />
    )
}

const OtherFlowNode = ({ data, id }) => {
    const isPreFlowNode = data.parents ? false : true

    let color = constants.edgeColors.red
    if (data.isBounceWarning) {
        color = constants.edgeColors.orange
    }
    if (data.isLastEndpoint) {
        color = constants.edgeColors.green
    }
    const additionalStyle = data.dest === constants.defaultNodeTypes.external ? exitNodeStyle : {}

    let key = id.replace('/', '');;


    return (
        <NodeWrapper
            id={key}
            isHighlighted={data.isHighlighted}
            style={{
                ...customNodeStyles,
                ...additionalStyle,
            }}
        >
            {getNodeHandle({ isPreFlowNode, color })}
            <div >
                <Container isExit={data.dest === constants.defaultNodeTypes.external} >
                    <NodeName dest={data.dest}>{getNodeName(data)}</NodeName>
                </Container>
            </div>
        </NodeWrapper>
    )
}

OtherFlowNode.propTypes = {
    data: PropTypes.shape({
        isHighlighted: PropTypes.bool,
        isBounceWarning: PropTypes.bool,
        dest: PropTypes.string,
        parents: PropTypes.array,
        isLastEndpoint: PropTypes.bool,
    }),
}
OtherFlowNode.defaultProps = {
    data: {
        isHighlighted: false,
        isBounceWarning: false,
        dest: "",
    },
}

export default OtherFlowNode
