import { get, isEqual } from "lodash"
import constants from "../constants"
import getNodesDetails from "./getNodesDetails"

const X_STARTING_POINT_FLOW_NODE = 300 // placing a flow point node at X from the left to right
const X_PADDING_NODES_FLOW_NODE = X_STARTING_POINT_FLOW_NODE + 350 // spacing the flow nodes at X pixels
const X_PADDING_NODES_FLOW_NODE_DIRECT = X_STARTING_POINT_FLOW_NODE - 100
const Y_STARTING_HEIGHT_FLOW_NODE = 600 // placing a flow point node at Y pixels from the top to bottom
const Y_STARTING_HEIGHT_DUMMY_NODE = Y_STARTING_HEIGHT_FLOW_NODE + 40 // placing a flow point node at Y pixels from the top to bottom
const ERROR_NODE_HEIGHT = 30 // height of an error node
const EMOTION_NODE_HEIGHT = 50
const Y_ERROR_NODE_PADDING = 65 // padding around an error node on the Y axis
const Y_EMOTION_NODE_PADDING = 400
const X_ERROR_PADDING = 30 // padding between left for the error node on the X axis
const X_DUMMY_PADDING = 10 // starting X of the DUMMY NODE
const X_EMOTION_PADDING = -190
const X_DUMMY_BETWEEN_PADDING = 720
const X_OTHER_FLOW_PADDING = X_PADDING_NODES_FLOW_NODE + 100// padding left of the other flow node
const X_OTHER_FLOW_PADDING_ADDITIONAL = 300
const X_END_FLOW_PADDING = 100
const Y_STARTING_POINT_ERROR = Y_STARTING_HEIGHT_FLOW_NODE - 70 // starting position of the error node on the Y axis
const Y_STARTING_POINT_EMOTION = Y_STARTING_HEIGHT_FLOW_NODE + -500
const Y_LEADS_FROM_NODE = Y_STARTING_HEIGHT_FLOW_NODE + 45
const Y_LEADS_TO_END_NODE = Y_STARTING_HEIGHT_FLOW_NODE + 45
const Y_DUMMY_SHIFT_NODE = 12

const BOUNCE_RATE_WARNING_THRESHOLD = 30
const PERCENTAGE_DECIMALS_USED = 2

const NODE = "node"
const NODE_DUMMY = "node_dummy"
const NODE_AGGREGATED = "node_aggregated"
const NODE_ERROR = "node_error"
const NODE_EMOTION = "node_emotion"
const NODE_ENDPOINT = "node_endpoint"
const NODE_STARTPOINT = "node_start"
const EDGE = "edge"

function getNoOfDecimals(value) {
    if (value === 100) {
        return 0
    } else return PERCENTAGE_DECIMALS_USED
}
function flowNodePosition(index, parentHasIssues, parentPosition) {
    return {
        x:
            X_STARTING_POINT_FLOW_NODE +
            (parentPosition.x || 0) +
            (index > 0 ? (parentHasIssues ? X_PADDING_NODES_FLOW_NODE : X_PADDING_NODES_FLOW_NODE_DIRECT) : 0),
        y: Y_STARTING_HEIGHT_FLOW_NODE,
    }
}

function errorNodePosition(i, index, skipPositionY, parentHasIssues, parentPosition) {
    return {
        x: X_STARTING_POINT_FLOW_NODE + X_ERROR_PADDING + (parentPosition.x || 0) + X_PADDING_NODES_FLOW_NODE_DIRECT,
        // (parentHasIssues ? X_PADDING_NODES_FLOW_NODE : X_PADDING_NODES_FLOW_NODE_DIRECT), // padding between flow nodes
        y: Y_STARTING_POINT_ERROR - index * Y_ERROR_NODE_PADDING - skipPositionY * ERROR_NODE_HEIGHT,
    }
}

function emotionNodePosition(i, index, skipPositionY, parentHasIssues, parentPosition) {
    return {
        x: X_STARTING_POINT_FLOW_NODE + X_EMOTION_PADDING + (parentPosition.x || 0) + X_PADDING_NODES_FLOW_NODE_DIRECT,
        // (parentHasIssues ? X_PADDING_NODES_FLOW_NODE : X_PADDING_NODES_FLOW_NODE_DIRECT), // padding between flow nodes
        y: Y_STARTING_POINT_EMOTION + index * Y_EMOTION_NODE_PADDING + skipPositionY * EMOTION_NODE_HEIGHT,
    }
}

function insightsNodePosition() {
    return {
        x: 0,//X_STARTING_POINT_FLOW_NODE + X_EMOTION_PADDING  + X_PADDING_NODES_FLOW_NODE_DIRECT,
        // (parentHasIssues ? X_PADDING_NODES_FLOW_NODE : X_PADDING_NODES_FLOW_NODE_DIRECT), // padding between flow nodes
        y: 0,//Y_STARTING_POINT_EMOTION  * Y_EMOTION_NODE_PADDING  ,
    }
}

function dummyNodePosition(position = 0, isShifted = true, parentHasIssues, parentPosition) {
    return {
        x:
            X_DUMMY_PADDING +
            (parentPosition.x || 0) +
            X_PADDING_NODES_FLOW_NODE_DIRECT +
            // (parentHasIssues ? X_PADDING_NODES_FLOW_NODE : X_PADDING_NODES_FLOW_NODE_DIRECT) +
            position * X_DUMMY_BETWEEN_PADDING,
        y: Y_STARTING_HEIGHT_DUMMY_NODE + (isShifted ? Y_DUMMY_SHIFT_NODE : 0),
    }
}
function otherFlowNodePosition(i, index, skipPosition, parentHasIssues, parentPosition) {
    return {
        x:
            X_OTHER_FLOW_PADDING +
            //   Y_ERROR_NODE_PADDING * index +
            (parentPosition.x || 0) +
            X_PADDING_NODES_FLOW_NODE_DIRECT,
        // (parentHasIssues ? X_PADDING_NODES_FLOW_NODE : X_PADDING_NODES_FLOW_NODE_DIRECT) +

        y: Y_STARTING_POINT_ERROR - skipPosition * Y_ERROR_NODE_PADDING - index * Y_ERROR_NODE_PADDING,
    }
}

function startFlowNodePosition(index, skipPosition) {
    return {
        x: 0,
        y: Y_LEADS_FROM_NODE - skipPosition * Y_ERROR_NODE_PADDING - index * Y_ERROR_NODE_PADDING,
    }
}

function endFlowNodePosition(i, index, skipPosition, parentHasIssues, parentPosition) {
    return {
        x:
            X_END_FLOW_PADDING +
            //   Y_ERROR_NODE_PADDING * index +
            (parentPosition.x || 0) +
            X_OTHER_FLOW_PADDING_ADDITIONAL,
        y: Y_LEADS_TO_END_NODE - skipPosition * Y_ERROR_NODE_PADDING - index * Y_ERROR_NODE_PADDING,
    }
}

function generateFlowPoint({ id, data, index }) {
    let directPercentage = ((data?.flowPoint?.direct?.occurences || 0) / data?.flowPoint?.occurences) * 100 || 0
    directPercentage = directPercentage.toFixed(getNoOfDecimals(directPercentage)) || null

    return {
        id: `${NODE}_${id}`,
        data: {
            path: get(data, "flowPoint.url.valuePretty") || "",
            element: get(data.flowPoint, "name"),
            tag: get(data.flowPoint, "html.tag"),
            type: get(data.flowPoint, "type"),
            percentage: data.percentage ? data.percentage.toFixed(getNoOfDecimals(data.percentage)) : 0,
            directPercentage,
            entered: get(data.flowPoint, "occurences"),
            start: index === 0,
            end: index === data.flowPoints.length - 1,
            parents: data.parents,
            isHighlighted: null,
            hasLeadsFrom: data.hasLeadsFrom,
        },
        type: constants.nodeTypes.flowNode,
        position: flowNodePosition(index, data.parentHasIssues, data.parentPosition),
    }
}

function generateDummyNode({ id, data }) {
    return {
        id: `${NODE_DUMMY}_${id}`,
        data: {
            issue: "Dummy node",
            index: data.nodePosition,
            hasIssues: data.hasIssues,
            hasDirect: data.hasDirect,
            parents: data.parents,
            nodePosition: data.nodePosition,
            isHighlighted: null,
        },
        type: constants.nodeTypes.dummyNode,
        position: dummyNodePosition(
            data.nodePosition,
            (!data.hasIssues && data.hasDirect) || (data.hasIssues && !data.hasDirect),
            data.parentHasIssues,
            data.parentPosition
        ),
        index: 0,
    }
}

function generateAggregatedNode({ data, i, j, errorPositionCounterY }) {
    return {
        id: `${NODE_AGGREGATED}_${data.id}`,
        data: {
            issues: data.issues,
            parents: data.parents,
            shouldDisplay: data.shouldDisplay,
        },
        type: constants.nodeTypes.aggregatedNode,
        position: errorNodePosition(i, j, errorPositionCounterY, data.parentHasIssues, data.parentPosition),
    }
}

function generateErrorNode({ data, i, j, errorPositionCounterY }) {
    return {
        id: `${NODE_ERROR}_${data.flowPointParent}_${i}_${j}_${data.issuesObject[j]}`,
        data: {
            message: get(data, "issue.data[0]"),
            insights: data?.insights,
            stack: get(data, "issue.data[1]"),
            type: get(data, "issue.type"),
            subtype: get(data, "issue.subtype"),
            class: data.class,
            isWarning: data.isWarningEdge && data.stayOccurences > data.exitOccurences,
            isBounceWarning: data.isBounceWarning || false,
            leadsTo: true,
            parents: data.parents,
            isHighlighted: null,
            occurences: data.occurences,
        },
        type: data.class === "technical" ? constants.nodeTypes.technicalIssueNode : constants.nodeTypes.behaviouralIssueNode,
        position: errorNodePosition(i, j, errorPositionCounterY, data.parentHasIssues, data.parentPosition),
    }
}


function generateEmotionNode({ data, i, j, emotionPositionCounterY }) {
    return {
        id: `${NODE_EMOTION}_${data.flowPointParent}_${i}_${j}_${data.issuesObject[j]}`,
        data: {
            type: get(data, "issue.type"),
            subtype: get(data, "issue.subtype"),
            class: data.class,
            isWarning: data.isWarningEdge && data.stayOccurences > data.exitOccurences,
            isBounceWarning: data.isBounceWarning || false,
            leadsTo: true,
            parents: data.parents,
            isHighlighted: null,
            occurences: data.occurences,
        },
        type: constants.nodeTypes.emotionNode,
        position: emotionNodePosition(i, j, emotionPositionCounterY, data.parentHasIssues, data.parentPosition),
    }
}

function generateInsightsNode({ data }) {
    return {
        id: `insightsNode`,
        data,
        type: constants.nodeTypes.insightsNode,
        position: insightsNodePosition(),
    }
}

function generateStartPointNode({ data, j, otherFlowPositionCounterY, parent }) {
    return {
        id: `${NODE_STARTPOINT}_${data.id}`,
        data: {
            dest: data.dest,
            isHighlighted: null,
            isBounceWarning: data.isBounceWarning || false,
        },
        type: constants.nodeTypes.otherFlowNode,
        position: startFlowNodePosition(j, otherFlowPositionCounterY),
        parent,
    }
}

function generateFlowEndpointNode({ data, i, j, otherFlowPositionCounterY, parent }) {
    return {
        id: `${NODE_ENDPOINT}_${data.id}`,
        data: {
            dest: data.dest,
            parents: data.parents,
            isHighlighted: null,
            isBounceWarning: data.isBounceWarning || false,
            isLastEndpoint: true,
        },
        type: constants.nodeTypes.otherFlowNode,
        position: endFlowNodePosition(i, j, otherFlowPositionCounterY, data.parentHasIssues, data.parentPosition),
        parent,
    }
}

function generateEndpointNode({ data, i, j, otherFlowPositionCounterY, parent }) {
    return {
        id: `${NODE_ENDPOINT}_${data.id}`,
        data: {
            dest: data.dest,
            parents: data.parents,
            isHighlighted: null,
            isBounceWarning: data.isBounceWarning || false,
        },
        type: constants.nodeTypes.otherFlowNode,
        position: otherFlowNodePosition(i, j, otherFlowPositionCounterY, data.parentHasIssues, data.parentPosition),
        parent,
    }
}

function generateTotalEdge({ source, target, data }) {
    return {
        id: `${EDGE}_${source}-${target}`,
        source,
        target,
        animated: true,
        sourceHandle: constants.handleType.mid,
        targetHandle: constants.handleType.mid,
        type: constants.edgeTypes.totalEdge,
        data: {
            isErrorEdge: data.isErrorEdge,
            percentage: data.edgePercent.toFixed(getNoOfDecimals(data.edgePercent)),
            index: data.edgePosition,
            isStepEdges: constants.edgeStyle.step,
            isHighlighted: null,
        },
    }
}

function generateIssueEdge({
    source,
    target,
    data: { issue, isWarningEdge = false, percentage, isBounceWarning = false },
    hasMarkerPadding,
    hasStartPadding,
    sourceHandle,
    targetHandle,
    isRelatedToEdge,
}) {
    return {
        id: `${EDGE}_${source}-${target}`,
        source,
        target,
        sourceHandle,

        targetHandle,
        animated: true,
        type: isWarningEdge
            ? constants.edgeTypes.warningEdge
            : isBounceWarning
                ? constants.edgeTypes.bounceWarningEdge
                : constants.edgeTypes.issueEdge,
        data: {
            percentage: (issue?.percent || percentage || 0).toFixed(PERCENTAGE_DECIMALS_USED),
            isStepEdges: constants.edgeStyle.step,
            isRelatedToEdge,
            hasMarkerPadding,
            hasStartPadding,
            isHighlighted: null,
        },
    }
}

function generateOutOfFlowEdge({ source, target, data }) {
    const percentage = (((data?.occurences || 0) / data?.parentOccurences) * 100).toFixed(PERCENTAGE_DECIMALS_USED) || null

    return {
        id: `${EDGE}_${source}-${target}`,
        source,
        target,
        sourceHandle: constants.handleType.bottom,
        targetHandle: constants.handleType.bottom,
        animated: true,
        type: constants.edgeTypes.directEdge,

        data: {
            percentage,
            isStepEdges: constants.edgeStyle.step,
            isHighlighted: null,
        },
    }
}
function generateDirectEdge({ source, target, data }) {
    let percentage = data.percentage ? data.percentage.toFixed(getNoOfDecimals(data.percentage)) : null
    if (!percentage) {
        percentage = ((data?.flowPoint?.direct?.occurences || 0) / data?.flowPoint?.occurences) * 100 || 0
        percentage = percentage.toFixed(getNoOfDecimals(percentage)) || null
    }

    return {
        id: `${EDGE}_${source}-${target}`,
        source,
        target,
        sourceHandle: constants.handleType.bottom,
        targetHandle: constants.handleType.bottom,
        animated: true,
        type: constants.edgeTypes.directEdge,

        data: {
            percentage,
            isStepEdges: constants.edgeStyle.step,
            isHighlighted: null,
        },
    }
}

function getIssueObject(issue) {
    if (issue?.class === "technical") {
        return issue
    }
    if (issue?.class === "behaviour") {
        let data = []
        data.push(getNodesDetails.behaviour[issue?.type]?.description)
        return { ...issue, data }
    }

    if (issue?.class === "emotion") {
        let data = []
        data.push(getNodesDetails.emotion[issue?.type]?.description)
        return { ...issue, data }
    }
    return issue
}


function prepareNodes(entry, aggregatesObj) {
    // assigning ids to each node
    const elements = []
    const elementsObj = {}

    const data = entry ? entry.flowPoints : {}
    const allIssues = entry ? entry.issues : {}

    const allEmotions = entry ? entry.emotions : {}

    if (data) {
        const flowPoints = Object.keys(data)

        // Inserting the insights node in the elements array 

        const insightsNode = generateInsightsNode({
            data: {
                insights:[]
            },
        })

        elements.push(insightsNode)
        elementsObj['insightsElem'] = insightsNode

        for (let i = 0; i < flowPoints.length; i += 1) {
            const flowPoint = { ...data[flowPoints[i]] }

            // adding a flowpoint to the list;

            let parentHasIssues = false

            if (flowPoint.parent) {
                const parentNode = data[flowPoint.parent]

                if (parentNode && parentNode.issues) {
                    if (Object.keys(parentNode.issues).length > 0) {
                        parentHasIssues = true
                    }
                }
            }
            // generic flow node
            let graphParent = i >= 1 ? `${NODE_DUMMY}_2_${i - 1}` : null

            if (!elementsObj[`${NODE_DUMMY}_2_${i - 1}`] && flowPoint.parent) {
                graphParent = `${NODE}_${flowPoint.parent}`
            }

            const parentPosition = elementsObj[`${NODE}_${flowPoint.parent}`]
                ? elementsObj[`${NODE}_${flowPoint.parent}`].position
                : { x: 0 }

            const node = generateFlowPoint({
                id: flowPoints[i],
                index: i,
                data: {
                    flowPoints,
                    flowPoint,
                    parentHasIssues,
                    parentPosition,
                    percentage: (flowPoint.occurences / data[flowPoints[0]].occurences) * 100,
                    hasLeadsFrom: Object.keys(flowPoint?.leadsFrom?.path || {}).length ? true : false,
                    parents: [graphParent],
                },
            })
            elements.push(node)
            elementsObj[node.id] = node

            if (
                elementsObj[`${NODE_DUMMY}_2_${i - 1}`] &&
                elementsObj[`${NODE_DUMMY}_2_${i - 1}`].data?.parents.length === 1 &&
                elementsObj[`${NODE_DUMMY}_2_${i - 1}`].data?.parents[0] === `${NODE_DUMMY}_${i - 1}`
            ) {
                graphParent = `${NODE_DUMMY}_${i - 1}`

                const dummyElementToUpdateIndex = elements.map((x) => x.id).indexOf(elementsObj[`${NODE_DUMMY}_2_${i - 1}`].id)

                elements.splice(dummyElementToUpdateIndex, 1)
                delete elementsObj[`${NODE_DUMMY}_2_${i - 1}`]

                if (data[flowPoints[i - 1]].direct) {
                    const edgeDirect = generateDirectEdge({
                        source: graphParent,
                        target: node.id,
                        data: { percentage: (data[flowPoints[i]].occurences / data[flowPoints[i - 1]].occurences) * 100 },
                    })
                    elements.push(edgeDirect)
                    elementsObj[edgeDirect.id] = edgeDirect
                }
            }

            let issuesObject = {}
            let sortedIssues = []

            let emotionsObject = {}
            let sortedEmotions = []

            if (data[flowPoints[i]]?.issues) {
                issuesObject = Object.keys(data[flowPoints[i]].issues)
                sortedIssues = data[flowPoints[i]].sortedIssues || issuesObject
            } else {
                sortedIssues = issuesObject
            }

            if (data[flowPoints[i]]?.emotions) {
                emotionsObject = Object.keys(data[flowPoints[i]].emotions)
                sortedEmotions = data[flowPoints[i]].sortedEmotions || emotionsObject
            } else {
                sortedEmotions = emotionsObject
            }

            if (flowPoint.leadsFrom && flowPoint.parent === null) {
                if (flowPoint.leadsFrom.path) {
                    const destinationsKeys = Object.keys(flowPoint.leadsFrom.path)

                    let directEndpoint = null
                    let directOccurences = 0
                    for (let i = 0; i <= destinationsKeys.length; i += 1) {
                        if (destinationsKeys[i]) {
                            const nodeEndpoint = generateStartPointNode({
                                data: {
                                    parentPosition,
                                    id: `${node.id}${destinationsKeys[i]}`,
                                    dest: destinationsKeys[i],
                                    parents: [],
                                    isBounceWarning: false,
                                }, // the parent is the error object
                                j: i,
                                otherFlowPositionCounterY: 0,
                            })
                            if (!elementsObj[nodeEndpoint.id]) {
                                elements.push(nodeEndpoint)
                                elementsObj[nodeEndpoint.id] = nodeEndpoint
                            }
                            const edgeDirect = generateOutOfFlowEdge({
                                source: nodeEndpoint.id,
                                target: node.id,
                                data: {
                                    occurences: flowPoint.leadsFrom.path[destinationsKeys[i]].occurences,
                                    parentOccurences: flowPoint.occurences,
                                },
                            })
                            elements.push(edgeDirect)
                            elementsObj[edgeDirect.id] = edgeDirect

                            // // in this case we have to create a direct endpoint

                            // if (flowPoint.leadsFrom.path[destinationsKeys[i]].occurences < flowPoint.occurences) {
                            //     // we are upating the directOccurences on the go based on each leadsFrom node we encounter and then we update the node so we can update the percentage on the edge
                            //     directOccurences += flowPoint.occurences - flowPoint.leadsFrom.path[destinationsKeys[i]].occurences

                            //     directEndpoint = generateStartPointNode({
                            //         data: {
                            //             parentPosition,
                            //             id: `${node.id}_direct`,
                            //             dest: "Direct",
                            //             parents: [],
                            //             isBounceWarning: false,
                            //         }, // the parent is the error object
                            //         j: i + 1,
                            //         otherFlowPositionCounterY: Object.keys(flowPoint.leadsFrom.path || {}).length
                            //             ? Object.keys(flowPoint.leadsFrom.path || {}).length - 1
                            //             : 0,
                            //     })
                            //     if (!elementsObj[directEndpoint.id]) {
                            //         elements.push(directEndpoint)
                            //         elementsObj[directEndpoint.id] = directEndpoint
                            //         const edgeDirect = generateOutOfFlowEdge({
                            //             source: directEndpoint.id,
                            //             target: node.id,
                            //             data: {
                            //                 occurences: directOccurences,
                            //                 parentOccurences: flowPoint.occurences,
                            //             },
                            //         })
                            //         elements.push(edgeDirect)
                            //         elementsObj[edgeDirect.id] = edgeDirect
                            //     } else {
                            //         const directPointToUpdateIndex = elements.map((x) => x.id).indexOf(directEndpoint.id)
                            //         // updating direct occurences on the go
                            //         if (directPointToUpdateIndex !== null && directPointToUpdateIndex !== undefined) {
                            //             elements[directPointToUpdateIndex].data = {
                            //                 ...elements[directPointToUpdateIndex].data,
                            //                 occurences: directOccurences,
                            //             }
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
            // this is the last flowPoint of the flow but we want to display where a user goes after this flow ends.
            if (flowPoint.leadsTo) {
                let otherFlowPositionCounterY = 0
                if (flowPoint.leadsTo?.external) {

                    const nodeEndpoint = generateFlowEndpointNode({
                        data: {
                            parentHasIssues,
                            parentPosition: node.position,
                            id: `${flowPoints[i]}_end_external`,
                            dest: constants.defaultNodeTypes.external,
                            parents: [node.id],
                            isBounceWarning: false,
                        }, // the parent is the error object
                        i,
                        j: 0,
                        otherFlowPositionCounterY,
                    })


                    if (!elementsObj[nodeEndpoint.id]) {
                        elements.push(nodeEndpoint)
                        elementsObj[nodeEndpoint.id] = nodeEndpoint
                        otherFlowPositionCounterY += 1
                        const edgeDirect = generateOutOfFlowEdge({
                            source: node.id,
                            target: nodeEndpoint.id,
                            data: { occurences: flowPoint.leadsTo?.external.occurences, parentOccurences: flowPoint.occurences },
                        })
                        elements.push(edgeDirect)
                        elementsObj[edgeDirect.id] = edgeDirect
                    }
                }
                if (flowPoint.leadsTo?.internal) {
                    const leadsToKeys = Object.keys(flowPoint.leadsTo?.internal)

                    for (let k = 0; k < leadsToKeys.length; k += 1) {
                        const issueEndpoint = flowPoint.leadsTo?.internal[leadsToKeys[k]]

                        if (issueEndpoint) {
                            const destinations = Object.keys(issueEndpoint)
                            for (let p = 0; p < destinations.length; p += 1) {

                                const nodeEndpoint = generateFlowEndpointNode({
                                    data: {
                                        parentHasIssues,
                                        parentPosition: node.position,
                                        id: `${flowPoints[i]}_${destinations[p]}_end_internal`,
                                        dest: destinations[p],
                                        parents: [node.id],
                                        isBounceWarning: false,
                                    }, // the parent is the error object
                                    i,
                                    j: 0,
                                    otherFlowPositionCounterY,
                                })
                                if (!elementsObj[nodeEndpoint.id]) {
                                    elements.push(nodeEndpoint)
                                    elementsObj[nodeEndpoint.id] = nodeEndpoint
                                    otherFlowPositionCounterY += 1
                                    const edgeDirect = generateOutOfFlowEdge({
                                        source: node.id,
                                        target: nodeEndpoint.id,
                                        data: {
                                            occurences: flowPoint.leadsTo?.internal.dest[destinations[p]].occurences,
                                            parentOccurences: flowPoint.occurences,
                                        },
                                    })
                                    elements.push(edgeDirect)
                                    elementsObj[edgeDirect.id] = edgeDirect
                                }


                            }
                        }
                    }
                }
            }
            // if there is a connection with another flowpoint we should create an edge between the two
            if (flowPoint.next) {
                const targetPercent = get(data[flowPoint.next], "occurences") || 0
                const sourcePercent = get(flowPoint, "occurences") || 0
                const edgePercent = (targetPercent / sourcePercent) * 100

                const hasIssues = issuesObject.length > 0
                const hasDirect = flowPoint?.direct?.occurences ? true : false

                let dummyLeftNode = null
                let dummyRightNode = null
                let otherFlowPositionCounterY = 0
                if (hasIssues) {
                    // placing a dummy node so we can split into direct and indirect flows
                    dummyLeftNode = generateDummyNode({
                        id: i,
                        data: {
                            parentHasIssues,
                            parentPosition: node.position,
                            hasIssues,
                            hasDirect,
                            nodePosition: 0,
                            parents: [node.id], // the left dummy node always has one parent which is the previous flow point
                        },
                        index: i,
                    })
                    elements.push(dummyLeftNode)
                    elementsObj[dummyLeftNode.id] = dummyLeftNode

                    // placing the second ddummy node so we can combine the edges back into one and connect it to the next point
                    // this has to appear either if there is a direct edge between the two dummy nodes or if there are error nodes which bring back to the flowpoint.
                    dummyRightNode = generateDummyNode({
                        id: `2_${i}`,
                        data: {
                            parentHasIssues,
                            hasIssues,
                            parentPosition: node.position,
                            hasDirect,
                            nodePosition: 1,
                            parents: [dummyLeftNode.id], // the right dummy node has one or multiple parents, one is the left dummy node the others are
                            // potential errorNodes but we will update this later
                        },
                        index: i,
                    })

                    elements.push(dummyRightNode)
                    elementsObj[dummyRightNode.id] = dummyRightNode

                    if (flowPoint?.direct?.occurences) {
                        const edgeDirect = generateDirectEdge({
                            source: dummyLeftNode.id,
                            target: dummyRightNode.id,
                            data: { flowPoint },
                        })
                        elements.push(edgeDirect)
                        elementsObj[edgeDirect.id] = edgeDirect
                    }
                    const edgeTotalLeft = generateTotalEdge({
                        source: node.id,
                        target: dummyLeftNode.id,
                        data: {
                            edgePosition: 0,
                            edgePercent: targetPercent === 0 ? 100 : targetPercent,
                            isErrorEdge: targetPercent === 0 ? true : false,
                        },
                    })

                    elements.push(edgeTotalLeft)
                    elementsObj[edgeTotalLeft.id] = edgeTotalLeft

                    const edgeTotalRight = generateTotalEdge({
                        source: dummyRightNode.id,
                        target: `${NODE}_${flowPoint.next}`,
                        data: { edgePosition: 1, edgePercent },
                    })

                    elements.push(edgeTotalRight)
                    elementsObj[edgeTotalRight.id] = edgeTotalRight
                } else {
                    if (flowPoint?.direct?.occurences) {
                        const edgeDirect = generateDirectEdge({
                            source: node.id,
                            target: `${NODE}_${flowPoint.next}`,
                            data: { flowPoint },
                        })
                        elements.push(edgeDirect)
                        elementsObj[edgeDirect.id] = edgeDirect
                    }
                }

                let errorPositionCounterY = 0
                let emotionPositionCounterY = 0
                let aggregatedIssueNo = 0

                for (let j = 0; j < sortedEmotions.length; j += 1) {
                    let emotion = flowPoint.emotions[sortedEmotions[j]]
                    // add issue nodes

                    const leadsTo = emotion?.leadsTo
                    const leadsToLength = leadsTo ? Object.keys(leadsTo).length : 1

                    // if one of the errors are then pointing to a flowPoint then this edge can be considered a warning edge because there are
                    // still users who are continuing the flow (not all of the users are leaving the flow)
                    const isWarningEdge =
                        leadsTo &&
                        Object.keys(leadsTo)
                            .map((item) => leadsTo[item].dest === constants.defaultNodeTypes.flowpoint)
                            .find((item2) => item2 === true)

                    // if the percentages of people exiting from this error is higher than the people going to the
                    // next flow point then this is considered to be a proper error node otherwise we can consider it
                    // a warning edge
                    let exitOccurences = 0
                    let stayOccurences = 0

                    if (emotion?.leadsTo) {
                        const leadsToKeys = Object.keys(emotion.leadsTo)

                        for (let k = 0; k < leadsToKeys.length; k += 1) {
                            const emotionEndpoint = emotion.leadsTo[leadsToKeys[k]]
                            if (emotionEndpoint.dest !== constants.defaultNodeTypes.flowpoint) {
                                exitOccurences += emotionEndpoint.occurences
                            } else {
                                stayOccurences += emotionEndpoint.occurences
                            }
                        }
                    }

                    // We aggregate all the low severity errors into one node


                    let nodeError = null

                    nodeError = generateEmotionNode({
                        i,
                        j,
                        emotionPositionCounterY,
                        data: {
                            flowPointParent: node.id,
                            parentHasIssues,
                            parentPosition: node.position,
                            issuesObject: emotionsObject,
                            issue: getIssueObject(allEmotions[sortedEmotions[j]]),
                            class: allEmotions[sortedEmotions[j]].class,
                            isWarningEdge,
                            exitOccurences,
                            stayOccurences,
                            occurences: flowPoint.emotions[sortedEmotions[j]].occurences,
                            parents: [dummyLeftNode.id], // each issue node has a left dummy node as a parent
                        },
                    })
                    //set a dummy node on top of everything
                    emotionPositionCounterY += leadsToLength


                    if (!nodeError) break

                    if (!elementsObj[nodeError.id]) {
                        elements.push(nodeError)
                        elementsObj[nodeError.id] = nodeError
                    } else {
                        // if there is an error that is occured in multiple places we have to create a duplicate node
                        // and link it accordingly
                        const errorIndex = elements.findIndex((item) => item.id === nodeError.id)
                        if (!isEqual(elements[errorIndex].data.parents, nodeError.data.parents)) {
                            nodeError.id = `${nodeError.id}${elements[errorIndex].data.parents.join("_")}`
                            elements.push(nodeError)
                            elementsObj[nodeError.id] = nodeError
                        }
                    }


                    // add edges from the main node to the issue nodes.
                    const edgeIssue = generateIssueEdge({
                        source: dummyLeftNode.id,
                        target: nodeError.id,
                        data: {
                            isWarningEdge: isWarningEdge && stayOccurences > exitOccurences,
                            issue: emotion,
                            percentage: (emotion.occurences / flowPoint.occurences) * 100,
                        },
                        sourceHandle: constants.handleType.upper,
                        targetHandle: constants.handleType.mid,
                        hasMarkerPadding: false,
                        hasStartPadding: false,
                    })
                    elements.push(edgeIssue)
                    elementsObj[edgeIssue.id] = edgeIssue

                    // compute nodes for the issue endpoints
                    if (emotion?.leadsTo) {

                        const leadsToKeys = Object.keys(emotion.leadsTo)

                        for (let k = 0; k < leadsToKeys.length; k += 1) {
                            const emotionEndpoint = emotion.leadsTo[leadsToKeys[k]]
                            if (emotionEndpoint && emotionEndpoint.dest) {
                                if (
                                    emotionEndpoint.dest !== constants.defaultNodeTypes.flowpoint &&
                                    emotionEndpoint.dest !== constants.defaultNodeTypes.external
                                ) {
                                    const destinations = Object.keys(emotionEndpoint.dest)
                                    for (let p = 0; p < destinations.length; p += 1) {
                                        const isBounceWarning =
                                            (emotionEndpoint.dest[destinations[p]].occurences / flowPoint.occurences) * 100 <=
                                            BOUNCE_RATE_WARNING_THRESHOLD

                                        const nodeEndpoint = generateEndpointNode({
                                            data: {
                                                parentPosition: node.position,
                                                id: `${flowPoints[i]}${destinations[p]}`,
                                                dest: destinations[p],
                                                parents: [nodeError.id],
                                                isBounceWarning,
                                            }, // the parent is the error object
                                            i,
                                            j,
                                            otherFlowPositionCounterY,
                                        })


                                        if (!elementsObj[nodeEndpoint.id]) {
                                            otherFlowPositionCounterY += 1
                                            elements.push(nodeEndpoint)
                                            elementsObj[nodeEndpoint.id] = nodeEndpoint
                                        } else {
                                            // if the node has already been added we should probably update the parent array this time
                                            const endpointNodeToUpdateIndex = elements.map((x) => x.id).indexOf(nodeEndpoint.id)
                                            if (endpointNodeToUpdateIndex !== null && endpointNodeToUpdateIndex !== undefined) {
                                                elements[endpointNodeToUpdateIndex].data.parents = [
                                                    ...elements[endpointNodeToUpdateIndex].data.parents,
                                                    nodeError.id,
                                                ]
                                            }
                                        }


                                        const edgeIssue2 = generateIssueEdge({
                                            source: nodeError.id,
                                            target: nodeEndpoint.id,
                                            data: {
                                                issuesObject,
                                                issue: emotion,
                                                percentage: (emotionEndpoint.dest[destinations[p]].occurences / emotion.occurences) * 100,
                                                isBounceWarning,
                                            },
                                            hasMarkerPadding: true,
                                            hasStartPadding: false,
                                            sourceHandle: constants.handleType.mid,
                                            targetHandle: constants.handleType.mid,
                                        })

                                        if (!elementsObj[edgeIssue2.id]) {
                                            elements.push(edgeIssue2)
                                            elementsObj[edgeIssue2.id] = edgeIssue2
                                        }
                                    }
                                } else if (emotionEndpoint.dest === constants.defaultNodeTypes.flowpoint) {
                                    const edgeIssue2 = generateIssueEdge({
                                        source: nodeError.id,
                                        target: dummyRightNode.id,
                                        sourceHandle: constants.handleType.mid,
                                        targetHandle: constants.handleType.upper,
                                        hasMarkerPadding: true,
                                        hasStartPadding: false,
                                        data: {
                                            isWarningEdge: true,
                                            issue: emotion,
                                            percentage: (emotion.occurencesFP / emotion.occurences) * 100,
                                        },
                                    })

                                    //update the dummyNodeRight parents
                                    const dummyElementToUpdateIndex = elements.map((x) => x.id).indexOf(dummyRightNode.id)

                                    if (dummyElementToUpdateIndex !== null && dummyElementToUpdateIndex !== undefined) {
                                        elements[dummyElementToUpdateIndex].data.parents = [
                                            ...elements[dummyElementToUpdateIndex].data.parents,
                                            nodeError.id,
                                        ]
                                    }
                                    if (!elementsObj[edgeIssue2.id]) {
                                        elements.push(edgeIssue2)
                                    }

                                    elementsObj[edgeIssue2.id] = edgeIssue2
                                } else if (emotionEndpoint.dest === constants.defaultNodeTypes.external) {
                                    const nodeEndpoint = generateEndpointNode({
                                        data: {
                                            parentHasIssues,
                                            parentPosition: node.position,
                                            id: `${flowPoints[i]}${emotionEndpoint.dest}`,
                                            dest: emotionEndpoint.dest,
                                            parents: [nodeError.id],
                                            isBounceWarning: false,
                                        }, // the parent is the error object
                                        i,
                                        j,
                                        otherFlowPositionCounterY,
                                    })


                                    if (!elementsObj[nodeEndpoint.id]) {
                                        //  otherFlowPositionCounterY += 1
                                        elements.push(nodeEndpoint)
                                        elementsObj[nodeEndpoint.id] = nodeEndpoint
                                    } else {
                                        // if the node has already been added we should probably update the parent array this time
                                        const endpointNodeToUpdateIndex = elements.map((x) => x.id).indexOf(nodeEndpoint.id)
                                        if (endpointNodeToUpdateIndex !== null && endpointNodeToUpdateIndex !== undefined) {
                                            elements[endpointNodeToUpdateIndex].data.parents = [
                                                ...elements[endpointNodeToUpdateIndex].data.parents,
                                                nodeError.id,
                                            ]
                                        }
                                    }


                                    const edgeIssue2 = generateIssueEdge({
                                        source: nodeError.id,
                                        target: nodeEndpoint.id,
                                        data: {
                                            issuesObject,
                                            issue,
                                            percentage: (emotionEndpoint.occurences / emotion.occurences) * 100,
                                            isBounceWarning: false,
                                        },
                                        hasMarkerPadding: true,
                                        hasStartPadding: false,
                                        sourceHandle: constants.handleType.mid,
                                        targetHandle: constants.handleType.mid,
                                    })

                                    if (!elementsObj[edgeIssue2.id]) {
                                        elements.push(edgeIssue2)
                                        elementsObj[edgeIssue2.id] = edgeIssue2
                                    }
                                }
                            }
                        }
                    }


                }
                for (let j = 0; j < sortedIssues.length; j += 1) {
                    let issue = flowPoint.issues[sortedIssues[j]]
                    // add issue nodes

                    const leadsTo = issue?.leadsTo
                    const leadsToLength = leadsTo ? Object.keys(leadsTo).length : 1

                    // if one of the errors are then pointing to a flowPoint then this edge can be considered a warning edge because there are
                    // still users who are continuing the flow (not all of the users are leaving the flow)
                    const isWarningEdge =
                        leadsTo &&
                        Object.keys(leadsTo)
                            .map((item) => leadsTo[item].dest === constants.defaultNodeTypes.flowpoint)
                            .find((item2) => item2 === true)

                    // if the percentages of people exiting from this error is higher than the people going to the
                    // next flow point then this is considered to be a proper error node otherwise we can consider it
                    // a warning edge
                    let exitOccurences = 0
                    let stayOccurences = 0

                    if (issue?.leadsTo) {
                        const leadsToKeys = Object.keys(issue.leadsTo)

                        for (let k = 0; k < leadsToKeys.length; k += 1) {
                            const issueEndpoint = issue.leadsTo[leadsToKeys[k]]
                            if (issueEndpoint.dest !== constants.defaultNodeTypes.flowpoint) {
                                exitOccurences += issueEndpoint.occurences
                            } else {
                                stayOccurences += issueEndpoint.occurences
                            }
                        }
                    }

                    // We aggregate all the low severity errors into one node

                    if (j > aggregatesObj[`${NODE}_${flowPoints[i]}`]) {
                        aggregatedIssueNo += 1
                        // create aggregated node and accumulate

                        const nodeAggregated = generateAggregatedNode({
                            i,
                            j: j - aggregatedIssueNo + 1,
                            errorPositionCounterY,
                            data: {
                                parentHasIssues,
                                parentPosition: node.position,
                                issues: aggregatedIssueNo,
                                shouldDisplay: sortedIssues.length > constants.DISPLAY_ISSUE_NO_DEFAULT,
                                id: flowPoints[i],
                                parents: [dummyLeftNode.id], // each issue node has a left dummy node as a parent
                            },
                        })
                        if (!elementsObj[nodeAggregated.id]) {
                            elements.push(nodeAggregated)
                            elementsObj[nodeAggregated.id] = nodeAggregated
                            const edgeIssue = generateIssueEdge({
                                source: dummyLeftNode.id,
                                target: nodeAggregated.id,
                                data: {},
                                sourceHandle: constants.handleType.upper,
                                targetHandle: constants.handleType.mid,
                                hasMarkerPadding: true,
                                hasStartPadding: false,
                            })
                            elements.push(edgeIssue)
                            elementsObj[edgeIssue.id] = edgeIssue
                        } else {
                            const nodeAggregatedIndex = elements.findIndex((item) => item.id === nodeAggregated.id)
                            elements[nodeAggregatedIndex] = nodeAggregated
                            elementsObj[nodeAggregated.id] = nodeAggregated
                        }
                        continue
                    }
                    if (
                        j === sortedIssues.length - 1 &&
                        aggregatedIssueNo === 0 &&
                        sortedIssues.length < aggregatesObj[`${NODE}_${flowPoints[i]}`]
                    ) {
                        const nodeAggregated = generateAggregatedNode({
                            i,
                            j: j - aggregatedIssueNo + 1,
                            errorPositionCounterY,
                            data: {
                                parentHasIssues,
                                parentPosition: node.position,
                                issues: aggregatedIssueNo,
                                shouldDisplay: sortedIssues.length > constants.DISPLAY_ISSUE_NO_DEFAULT,
                                id: flowPoints[i],
                                parents: [dummyLeftNode.id], // each issue node has a left dummy node as a parent
                            },
                        })
                        elements.push(nodeAggregated)
                        elementsObj[nodeAggregated.id] = nodeAggregated
                    }
                    let nodeError = null

                    nodeError = generateErrorNode({
                        i,
                        j,
                        errorPositionCounterY,
                        data: {
                            flowPointParent: node.id,
                            parentHasIssues,
                            parentPosition: node.position,
                            issuesObject,
                            insights: issue.insights,
                            issue: getIssueObject(allIssues[sortedIssues[j]]),
                            class: allIssues[sortedIssues[j]].class,
                            isWarningEdge,
                            exitOccurences,
                            stayOccurences,
                            occurences: flowPoint.issues[sortedIssues[j]].occurences,
                            parents: [dummyLeftNode.id], // each issue node has a left dummy node as a parent
                        },
                    })
                    //set a dummy node on top of everything
                    errorPositionCounterY += leadsToLength


                    if (!nodeError) break

                    if (!elementsObj[nodeError.id]) {
                        elements.push(nodeError)
                        elementsObj[nodeError.id] = nodeError
                    } else {
                        // if there is an error that is occured in multiple places we have to create a duplicate node
                        // and link it accordingly
                        const errorIndex = elements.findIndex((item) => item.id === nodeError.id)
                        if (!isEqual(elements[errorIndex].data.parents, nodeError.data.parents)) {
                            nodeError.id = `${nodeError.id}${elements[errorIndex].data.parents.join("_")}`
                            elements.push(nodeError)
                            elementsObj[nodeError.id] = nodeError
                        }
                    }


                    // add edges from the main node to the issue nodes.
                    const edgeIssue = generateIssueEdge({
                        source: dummyLeftNode.id,
                        target: nodeError.id,
                        data: {
                            isWarningEdge: isWarningEdge && stayOccurences > exitOccurences,
                            issue,
                            percentage: (issue.occurences / flowPoint.occurences) * 100,
                        },
                        sourceHandle: constants.handleType.upper,
                        targetHandle: constants.handleType.mid,
                        hasMarkerPadding: false,
                        hasStartPadding: false,
                    })
                    elements.push(edgeIssue)
                    elementsObj[edgeIssue.id] = edgeIssue

                    // compute nodes for the issue endpoints
                    if (issue?.leadsTo) {

                        const leadsToKeys = Object.keys(issue.leadsTo)

                        for (let k = 0; k < leadsToKeys.length; k += 1) {
                            const issueEndpoint = issue.leadsTo[leadsToKeys[k]]
                            if (issueEndpoint && issueEndpoint.dest) {
                                if (
                                    issueEndpoint.dest !== constants.defaultNodeTypes.flowpoint &&
                                    issueEndpoint.dest !== constants.defaultNodeTypes.external
                                ) {
                                    const destinations = Object.keys(issueEndpoint.dest)
                                    for (let p = 0; p < destinations.length; p += 1) {
                                        const isBounceWarning =
                                            (issueEndpoint.dest[destinations[p]].occurences / flowPoint.occurences) * 100 <=
                                            BOUNCE_RATE_WARNING_THRESHOLD

                                        const nodeEndpoint = generateEndpointNode({
                                            data: {
                                                parentPosition: node.position,
                                                id: `${flowPoints[i]}${destinations[p]}`,
                                                dest: destinations[p],
                                                parents: [nodeError.id],
                                                isBounceWarning,
                                            }, // the parent is the error object
                                            i,
                                            j,
                                            otherFlowPositionCounterY,
                                        })


                                        if (!elementsObj[nodeEndpoint.id]) {
                                            otherFlowPositionCounterY += 1
                                            elements.push(nodeEndpoint)
                                            elementsObj[nodeEndpoint.id] = nodeEndpoint
                                        } else {
                                            // if the node has already been added we should probably update the parent array this time
                                            const endpointNodeToUpdateIndex = elements.map((x) => x.id).indexOf(nodeEndpoint.id)
                                            if (endpointNodeToUpdateIndex !== null && endpointNodeToUpdateIndex !== undefined) {
                                                elements[endpointNodeToUpdateIndex].data.parents = [
                                                    ...elements[endpointNodeToUpdateIndex].data.parents,
                                                    nodeError.id,
                                                ]
                                            }
                                        }


                                        const edgeIssue2 = generateIssueEdge({
                                            source: nodeError.id,
                                            target: nodeEndpoint.id,
                                            data: {
                                                issuesObject,
                                                issue,
                                                percentage: (issueEndpoint.dest[destinations[p]].occurences / issue.occurences) * 100,
                                                isBounceWarning,
                                            },
                                            hasMarkerPadding: true,
                                            hasStartPadding: false,
                                            sourceHandle: constants.handleType.mid,
                                            targetHandle: constants.handleType.mid,
                                        })

                                        if (!elementsObj[edgeIssue2.id]) {
                                            elements.push(edgeIssue2)
                                            elementsObj[edgeIssue2.id] = edgeIssue2
                                        }
                                    }
                                } else if (issueEndpoint.dest === constants.defaultNodeTypes.flowpoint) {
                                    const edgeIssue2 = generateIssueEdge({
                                        source: nodeError.id,
                                        target: dummyRightNode.id,
                                        sourceHandle: constants.handleType.mid,
                                        targetHandle: constants.handleType.upper,
                                        hasMarkerPadding: true,
                                        hasStartPadding: false,
                                        data: {
                                            isWarningEdge: true,
                                            issue,
                                            percentage: (issue.occurencesFP / issue.occurences) * 100,
                                        },
                                    })

                                    //update the dummyNodeRight parents
                                    const dummyElementToUpdateIndex = elements.map((x) => x.id).indexOf(dummyRightNode.id)

                                    if (dummyElementToUpdateIndex !== null && dummyElementToUpdateIndex !== undefined) {
                                        elements[dummyElementToUpdateIndex].data.parents = [
                                            ...elements[dummyElementToUpdateIndex].data.parents,
                                            nodeError.id,
                                        ]
                                    }
                                    if (!elementsObj[edgeIssue2.id]) {
                                        elements.push(edgeIssue2)
                                    }

                                    elementsObj[edgeIssue2.id] = edgeIssue2
                                } else if (issueEndpoint.dest === constants.defaultNodeTypes.external) {
                                    const nodeEndpoint = generateEndpointNode({
                                        data: {
                                            parentHasIssues,
                                            parentPosition: node.position,
                                            id: `${flowPoints[i]}${issueEndpoint.dest}`,
                                            dest: issueEndpoint.dest,
                                            parents: [nodeError.id],
                                            isBounceWarning: false,
                                        }, // the parent is the error object
                                        i,
                                        j,
                                        otherFlowPositionCounterY,
                                    })


                                    if (!elementsObj[nodeEndpoint.id]) {
                                        //  otherFlowPositionCounterY += 1
                                        elements.push(nodeEndpoint)
                                        elementsObj[nodeEndpoint.id] = nodeEndpoint
                                    } else {
                                        // if the node has already been added we should probably update the parent array this time
                                        const endpointNodeToUpdateIndex = elements.map((x) => x.id).indexOf(nodeEndpoint.id)
                                        if (endpointNodeToUpdateIndex !== null && endpointNodeToUpdateIndex !== undefined) {
                                            elements[endpointNodeToUpdateIndex].data.parents = [
                                                ...elements[endpointNodeToUpdateIndex].data.parents,
                                                nodeError.id,
                                            ]
                                        }
                                    }


                                    const edgeIssue2 = generateIssueEdge({
                                        source: nodeError.id,
                                        target: nodeEndpoint.id,
                                        data: {
                                            issuesObject,
                                            issue,
                                            percentage: (issueEndpoint.occurences / issue.occurences) * 100,
                                            isBounceWarning: false,
                                        },
                                        hasMarkerPadding: true,
                                        hasStartPadding: false,
                                        sourceHandle: constants.handleType.mid,
                                        targetHandle: constants.handleType.mid,
                                    })

                                    if (!elementsObj[edgeIssue2.id]) {
                                        elements.push(edgeIssue2)
                                        elementsObj[edgeIssue2.id] = edgeIssue2
                                    }
                                }
                            }
                        }
                    }

                    // compute edges for the related to fields
                    if (issue?.relatedTo) {
                        const relatedToKeys = Object.keys(issue.relatedTo)

                        for (let k = 0; k < relatedToKeys.length; k += 1) {
                            const relatedToPoint = issue.relatedTo[relatedToKeys[k]]

                            if (relatedToPoint) {
                                let sourceDetails = null
                                let targetDetails = null

                                let sourceHandle = constants.handleType.bottom_center
                                let targetHandle = constants.handleType.top_center

                                for (let e = 0; e < elements.length; e += 1) {
                                    if (elements[e].id === nodeError.id) {
                                        sourceDetails = elements[e]
                                    }
                                    if (elements[e].id === elementsObj[relatedToPoint.id]) {
                                        targetDetails = elements[e]
                                    }
                                }
                                // if the target node is not yet in the array than it means it will be added later
                                // if it's going to be added later it means that it will sit above the current node therefore we need
                                // to connect from  the upper side of the bottom node to the bottom side of the upper node
                                if (!targetDetails) {
                                    sourceHandle = constants.handleType.top_center
                                    targetHandle = constants.handleType.bottom_center
                                }

                                if (sourceDetails && targetDetails) {
                                    const sourceY = get(sourceDetails, "position.y")
                                    const targetY = get(targetDetails, "position.y")

                                    if (sourceY < targetY) {
                                        sourceHandle = constants.handleType.bottom_center
                                        targetHandle = constants.handleType.top_center
                                    } else {
                                        sourceHandle = constants.handleType.top_center
                                        targetHandle = constants.handleType.bottom_center
                                    }
                                }

                                const edgeIssue2 = generateIssueEdge({
                                    source: nodeError.id,
                                    target: `${relatedToPoint.id}`,
                                    sourceHandle,
                                    targetHandle,
                                    hasMarkerPadding: false,
                                    isRelatedToEdge: true,
                                    data: {
                                        percentage: relatedToPoint.percent,
                                    },
                                })

                                if (!elementsObj[edgeIssue2.id]) {
                                    elements.push(edgeIssue2)
                                }

                                elementsObj[edgeIssue2.id] = edgeIssue2
                            }
                        }
                    }
                }

                //check the right dummy node and remove it in case there are no issues bringing the flow back to the main flow or if there is no direct flow from the previous flow point
                if (dummyRightNode) {
                    const dummyElementToUpdateIndex = elements.map((x) => x.id).indexOf(dummyRightNode.id)
                    const dummyLeftElementToUpdateIndex = elements.map((x) => x.id).indexOf(dummyLeftNode.id)
                    const flowPointToUpdateIndex = elements.map((x) => x.id).indexOf(node.id)

                    if (dummyElementToUpdateIndex !== null && dummyElementToUpdateIndex !== undefined) {
                        if (
                            !dummyRightNode.data.hasDirect &&
                            elements[dummyElementToUpdateIndex]?.data?.parents[0] === dummyLeftNode?.id &&
                            elements[dummyElementToUpdateIndex]?.data?.parents.length === 1
                        ) {
                            // delete elementsObj[elements[dummyElementToUpdateIndex].id]
                            // elements.splice(dummyElementToUpdateIndex, 1)

                            if (flowPointToUpdateIndex !== null) {
                                elements[flowPointToUpdateIndex].data = {
                                    ...elements[flowPointToUpdateIndex].data,
                                    hasOnlyErrors: true,
                                }
                            }

                            if (dummyLeftElementToUpdateIndex !== null && dummyLeftElementToUpdateIndex !== undefined) {
                                elements[dummyLeftElementToUpdateIndex].data = {
                                    ...elements[dummyLeftElementToUpdateIndex].data,
                                    hasOnlyErrors: true,
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return { elements, elementsObj }
}
export default prepareNodes
