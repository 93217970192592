export function getHighlightStyle({ color, isHighlighted, percentage }) {
    return {
        stroke: color,
        strokeDasharray: isHighlighted ? 2 : 0,
        //   strokeDasharray: 0,
        // opacity: isHighlighted ? 1 : 0.5,
        arrowHeadColor: color,
        // filter: isHighlighted ? `drop-shadow(1px 1px 1px ${color})` : "none",
        strokeWidth: getEdgeWidthFromPercentage(percentage),
        cursor: "pointer",
    }
}

function getEdgeWidthFromPercentage(percentage) {
    if (percentage < 10) {
        return 1
    }
    if (percentage >= 10 && percentage < 30) {
        return 3
    }
    if (percentage >= 30 && percentage < 50) {
        return 5
    }
    if (percentage >= 50 && percentage < 70) {
        return 7
    }
    if (percentage >= 70 && percentage <= 100) {
        return 10
    }
}

export function getHighlightedLabelStyle({ isHighlighted }) {
    return {
        // opacity: isHighlighted ? 1 : 0.5,
    }
}

export function getEdgeLabelWidth() {
    return 70
}