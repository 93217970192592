import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Container, Typography, Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupIcon from '@mui/icons-material/Group';
import CodeIcon from '@mui/icons-material/Code';
// components
import Image from '../../components/Image';
import { MotionViewport, varFade } from '../../components/animate';
import styledComponent from 'styled-components'
import { EXTERNAL_PATHS } from '../../routes/paths';


// ----------------------------------------------------------------------

const ButtonContainer = styledComponent.div`
  margin-top:30px;
`

const CARDS = [
  {
    icon: CodeIcon,
    key: 'CodeIcon',
    title: 'One min integration with Google Tag Manager',
    image: "/images/gtm.svg",
    description: 'No effort. No code. No technical skills required.',
    cta:{
      color: "white",
      text:"Other integrations",
      url:`${EXTERNAL_PATHS.docs}/integrations`
    }
  },
  {
    icon: GroupIcon,
    key: 'GroupIcon',
    title: 'See all user sessions',
    image: "/images/sessionsDec.png",
    description: 'See instantly all your users’ journeys on your website',
    // cta:{
    //   color: "#0a2540",
    //   text:"learn more",
    //   url:`${EXTERNAL_PATHS.docs}/`
    // }
  },
  {
    icon: FilterAltIcon,
    key: 'FilterAltIcon',
    title: 'Funnel Analysis',
    image: "/images/flow-test.png",
    description:
      'See the most granular issues in your users flows',
    cta:{
      color: "white",
      text:"learn more",
      url:`${EXTERNAL_PATHS.docs}/funnel-analysis`
    }
  },
  {
    icon: FilterAltIcon,
    key: 'FilterAltIcons',
    title: 'AI powered insights',
    image: "/images/insightsexpanded3.png",
    description:
      'Understand what needs to be done to drive more revenue',
    cta:{
      color: "#0a2540",
      text:"learn more",
      url:`${EXTERNAL_PATHS.docs}/the-magic`
    }
  },
];

const shadowIcon = (color) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));

const CardStyle = styled(Card)(({ theme }) => {
  const shadowCard = (opacity) =>
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[500], opacity)
      : alpha(theme.palette.common.black, opacity);

  return {
    border: 0,
    maxWidth: 550,
    minHeight: 330,

    margin: 'auto',
    textAlign: 'center',
    padding: theme.spacing(10, 5, 10),
    boxShadow: theme.customShadows.z12,
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    '&.cardFirst': {
      [theme.breakpoints.up('md')]: { marginTop: -30 },
      // backgroundColor: "#282c34",
      backgroundColor: '#031E3A',
    },
    '&.cardSecond': {
      [theme.breakpoints.up('md')]: { marginTop: -10 },

      backgroundColor: theme.palette.background.paper,
      boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
      '&:before': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        content: "''",
        margin: 'auto',
        position: 'absolute',
        width: 'calc(100% - 40px)',
        // height: 'calc(100% - 40px)',
        borderRadius: Number(theme.shape.borderRadius) * 2,

        backgroundColor: theme.palette.background.paper,
        // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
      },
    },
    '&.cardThird': {
      [theme.breakpoints.up('md')]: { marginTop: -180 },
      // backgroundColor: "#0c0c0d",
      backgroundColor: '#063B74',
      boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
      '&:before': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        content: "''",
        margin: 'auto',
        position: 'absolute',
        width: 'calc(100% - 40px)',
        // height: 'calc(100% - 40px)',
        borderRadius: Number(theme.shape.borderRadius) * 2,
        // backgroundColor: theme.palette.background.paper,
        // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
      },
    },
    '&.cardForth': {
      [theme.breakpoints.up('xs')]: {
        marginTop: 0,
        backgroundColor: "#fafafa",

        boxShadow: `-40px 40px 80px 0 alpha(#000, 0.4)`,
        '&:before': {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          content: "''",
          margin: 'auto',
          position: 'absolute',
          width: 'calc(100% - 40px)',
          // height: 'calc(100% - 40px)',

          borderRadius: Number(theme.shape.borderRadius) * 2,
          backgroundColor: "#fafafa",
          // backgroundColor: theme.palette.background.paper,
          // boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
        },
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 10
      }
    },
  };
});

// ----------------------------------------------------------------------

export default function HomeMinimal() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container component={MotionViewport}>
        <Box
          sx={{
            textAlign: 'center',
            mb: { xs: 10, md: 25 },
          }}
        >
          <m.div variants={varFade().inUp}>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              Understanding
            </Typography>
          </m.div>
          <m.div variants={varFade().inDown}>
            <Typography variant="h2" sx={{ mb: 3 }}>How the magic happens</Typography>
          </m.div>
          <m.div variants={varFade().inUp} >
                <Typography
                    sx={{
                        color: (theme) =>
                            theme.palette.mode === 'light' ? 'text.secondary' : 'text.primary',
                    }}
                >
                We analyse users' behaviour, identify the most impactful issues,<br />
                highlight revenue generating opportunities.
                </Typography>
            </m.div>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gap: { xs: 5, lg: 5 },
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
            // minHeight: 1000,
          }}
        >
          {CARDS.map((card, index) => (
            <m.div variants={varFade().inUp} key={card.title}>
              <CardStyle className={(index === 0 && 'cardFirst') || (index === 1 && 'cardSecond') || (index == 2 && 'cardThird') || (index == 3 && 'cardForth')} >
                {/* <card.icon style={{ fontSize: 50 }} /> */}
                <Image
                  src={card.image}
                  alt={card.title}
                  key={card.key}
                  sx={{
                    mb: 10,
                    mx: 'auto',
                    width: index === 0 ? '80px' : "100%",
                    // height: 'auto'
                    maxHeight: index === 0 ? '80px' : "100%",
                    minHeight: '20px',
                    position: 'relative', 
                  }}
                />
                < Typography variant="h5" paragraph style={{ color: (index == 0 || index == 2) ? "white" : "initial" }}>
                  {card.title}
                </Typography>
                <Typography sx={{ color: (index == 0 || index == 2) ? "white" : "initial" }}>{card.description}</Typography>
               {card?.cta?.url && (  <ButtonContainer>
                      <Button
                        variant="outline"
                        target="_blank"
                        style={{color:card.cta.color, textDecoration:"underline"}}
                        href={card.cta.url}
                      >
                    {card.cta.text}
                  </Button>
                </ButtonContainer>)}
              </CardStyle>
            </m.div>
          ))}
        </Box>
      </Container>
    </RootStyle>
  );
}
