import { Handle } from "react-flow-renderer"
import PropTypes from "prop-types"

const HandleComponent = ({ type, position, id, color, top }) => (
    <Handle
        type={type}
        position={position}
        id={id}
        style={{
            top,
            borderColor: color ? color : "transparent",
            width: 10,
            height: 10,
            borderRadius: "50%",
            background: color ? color : "transparent",
            boxShadow: color ? `0px 0px 4px ${color} ` : "none",
        }}
    />
)
HandleComponent.propTypes = {
    type: PropTypes.string,
    position: PropTypes.string,
    id: PropTypes.string,
    color: PropTypes.string,
    top: PropTypes.string,
}

export default HandleComponent