import { useState, useEffect, useRef } from "react"
import { AnimatePresence, m } from "framer-motion"
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Box, Divider, Container, Typography, useTheme, Grid } from "@mui/material"
// components
import Image from "../../components/Image"
import { MotionViewport, varFade } from "../../components/animate"
import styledComponent from "styled-components"
import { PageNotFoundIllustration } from "../../assets"
import Iconify from "../../components/Iconify"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useResponsive from "src/hooks/useResponsive"
// ----------------------------------------------------------------------

const LogoContainer = styledComponent.div`
  display: flex;
  overflow-x: hidden;  // Default to hidden for larger screens
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  margin-left: -40px;

  ::before, ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 350px;  /* Adjust width to control the fade area */
    pointer-events: none;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.99), transparent);
  }

  ::before {
    left: 0;
    transform: scaleX(-1);  /* Reverse the gradient for the left side */
  }

  ::after {
    right: 0;
  }

  @media only screen and (max-width: 768px) {
    min-width: max-content; // This makes the container's minimum width to be as wide as its content
    overflow-x: hidden;
    scroll-behavior: smooth;
    margin-left:0px;
    // Apply animation only on mobile devices
    animation: scrollLogo 20s linear infinite;

    @keyframes scrollLogo {
      0% { transform: translateX(40%); }
      50% { transform: translateX(-40%); }
      100% { transform: translateX(40%); }
    }
  }
`

const IssueContainer = styledComponent.div`
  margin:auto;
   margin-top:10px;
  display: flex;
  height: 100%;
  margin:0px;
  margin-left:20px;
  flex-direction: column;
  justify-content: center;
  border-radius: 26px;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px dashed rgba(145, 158, 171, 0.16);

  padding:8px 16px;
  height: 55px;
  min-width: 180px;
  img {
    max-width: 100%;
    max-height: 25px;
    width: auto;
    height: auto;
   filter: grayscale(3) brightness(0.8);
   background-size: contain!important;
   background-repeat: no-repeat!important;
  }
  @media only screen and (max-width: 768px) {
  margin: 5px;
   
  }
`

const RootStyle = styled("div")(({ theme, paddingTop }) => ({
    // padding: theme.spacing(15, 0),
    // paddingTop: "90px",
    [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
        paddingTop: paddingTop || theme.spacing(15),
        paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("xs")]: {
        paddingBottom: theme.spacing(1),
        margin: "5px",
    },
}))

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: "100%",
    margin: "auto",
    textAlign: "center",
    fontFamily: "Inter, sans-serif",
    [theme.breakpoints.up("md")]: {
        zIndex: 11,
        textAlign: "center",
        position: "relative",
    },
}))

// ----------------------------------------------------------------------

export default function HomeCleanInterfaces({ paddingTopProp }) {
    const theme = useTheme()
    const [x, setX] = useState(0)
    const [width, setWidth] = useState(0)
    const containerRef = useRef(null)
    const logoContainerRef = useRef(null)

    const isDesktop = useResponsive("up", "sm")

    useEffect(() => {
        if (!isDesktop) {
            logoContainerRef.current.style.animationPlayState = "running"
        } else {
            logoContainerRef.current.style.animationPlayState = "paused"
        }
    }, [isDesktop])

    return (
        <RootStyle
            sx={{
                "@media screen and (min-width: 960px)": {
                    paddingTop: "16px",
                    paddingBottom: "16px",
                },
                "@media screen and (min-width: 1280px)": {
                    paddingTop: paddingTopProp || "50px",
                    paddingBottom: "48px",
                },
                "@media screen and (min-width: 600px)": {
                    paddingBottom: "48px",
                    margin: "5px",
                },
            }}
        >
            <Grid
                spacing={2}
                container
                sx={{
                    justifyContent: "center",
                    justifyItems: "center",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                }}
            >
                <Grid
                    container
                    sx={{
                        justifyContent: "center",
                        justifyItems: "center",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        overflow: "hidden",
                    }}
                >
                    <Grid
                        md={12}
                        xs={12}
                        item
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 3,
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 3,
                            textAlign: "center",
                        }}
                    >
                        <Typography variant='h5' paragraph sx={{ fontFamily: "Poppins, sans-serif" }}>
                            {" "}
                            Join over 1,800 satisfied customers today
                        </Typography>
                    </Grid>
                    <LogoContainer ref={logoContainerRef}>
                        <IssueContainer>
                            <Image height={65} width={121} alt='customer' src='/images/customers/11.svg' />
                        </IssueContainer>

                        <IssueContainer>
                            <Image height={150} width={25} alt='customer' src='/images/customers/fundraise-up.webp' />
                        </IssueContainer>

                        <IssueContainer>
                            <Image height={31} width={150} alt='customer' src='/images/customers/cropped-Nightcap_logo_RGB_dark-1.webp' />
                        </IssueContainer>

                        <IssueContainer>
                            <Image height={30} width={164} alt='customer' src='/images/customers/desyr.webp' />
                        </IssueContainer>

                        <IssueContainer>
                            <Image height={24} width={150} alt='customer' src='/images/customers/claim2.webp' />
                        </IssueContainer>

                        <IssueContainer>
                            <Image height={61} width={340} alt='customer' src='/images/customers/swing2sleep.avif' />
                        </IssueContainer>
                        <IssueContainer>
                            <Image height={61} width={340} alt='customer' src='/images/customers/naturepedic.webp' />
                        </IssueContainer>
                        <IssueContainer>
                            <Image height={61} width={340} alt='customer' src='/images/customers/billy.avif' />
                        </IssueContainer>

                        {/* </Grid> */}
                    </LogoContainer>
                </Grid>
            </Grid>
        </RootStyle>
    )
}
