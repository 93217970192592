import React from "react"
import { getBezierPath, getEdgeCenter, getMarkerEnd, getSmoothStepPath } from "react-flow-renderer"
import styled from "styled-components"
import PropTypes from "prop-types"

import constants from "../../../utils/constants"
import ArrowMarker from "../Markers/ArrowMarker"
import { getHighlightStyle, getHighlightedLabelStyle, getEdgeLabelWidth } from "./utils"

const StyledButton = styled.button`
    background-color: ${(props) => props.color};
    box-shadow: 0px 4px 4px rgba(145, 158, 171, 0.1);
    border: none;
    cursor: pointer;
    border-radius: 8px,
    width: ${getEdgeLabelWidth()};
    padding: 0px 10px;
    height: 16px;
    font-weight: bold;
    color: white;
    display: initial;
    border-radius:8px;
`


const foreignObjectSize = getEdgeLabelWidth()

export default function TotalEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data, markerEndId }) {
    const { isStepEdges } = data
    const edgePath = isStepEdges
        ? getSmoothStepPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })
        : getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })

    const markerEnd = getMarkerEnd("arrowclosed", markerEndId)
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })
    let color = constants.edgeColors.green
    if (data.isErrorEdge) {
        color = constants.edgeColors.red
    }
    const percentage = parseFloat(data.percentage)
    return (
        <>
            {/* <ArrowMarker refX='10' id={`arrow_total_${id}`} orient='auto' color={color} /> */}
            <path
                id={id}
                style={getHighlightStyle({ color, isHighlighted: data.isHighlighted, percentage })}
                className='react-flow__edge-path'
                d={edgePath}
                markerEnd={`url(#arrow_total_${id})`}
            />
            {!data.noStats && data.index === 0 && (
                <foreignObject
                    width={foreignObjectSize}
                    height={25}
                    x={edgeCenterX - foreignObjectSize / 2}
                    y={edgeCenterY - foreignObjectSize / 5}
                    className='edgebutton-foreignobject'
                    requiredExtensions='http://www.w3.org/1999/xhtml'
                >
                    {/* {(<StyledButton isHighlighted={data.isHighlighted} percentage={data.percentage} className='edgebutton' color={color} style={getHighlightedLabelStyle({ isHighlighted: data.isHighlighted })}>
                        {percentage < 1 ? '<1%' : `${percentage}%`}
                    </StyledButton>)} */}
                </foreignObject>
            )}
        </>
    )
}
TotalEdge.propTypes = {
    id: PropTypes.string,
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    style: PropTypes.shape({}),
    markerEndId: PropTypes.string,
    data: PropTypes.shape({
        isHighlighted: PropTypes.bool,
        percentage: PropTypes.string,
        isStepEdges: PropTypes.bool,
        hasMarkerPadding: PropTypes.bool,
        hasStartPadding: PropTypes.bool,
        isRelatedToEdge: PropTypes.bool,
        noStats: PropTypes.bool,
        index: PropTypes.number,
    }),
}
